import * as React from 'react'
import { withStyles } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import green from '@material-ui/core/colors/green'
import { CovaultAuth } from 'components/CovaultAuth'
import { TableCell } from '@material-ui/core'

// modify the padding of table cells to accomodate an icon
export const StatusIconTableCell = withStyles(() => ({
  root: {
    '&:first-child': {
      padding: '7px 0px 1px 6px',
      textAlign: 'center',
    },
    '&:nth-child(2)': {
      padding: '4px 18px 4px 6px',
    },
  },
}))(TableCell)

function StatusIcon({ classes, status }) {
  let icon = <div style={{ width: 20 }} /> // need something to fill the gap

  switch (status) {
    case 'APPROVED':
      icon = (
        <CovaultAuth requiredParent="dealer">
          <CheckCircleIcon color="action" classes={classes} data-testid="approved-icon" />
        </CovaultAuth>
      )
      break
    case 'REJECTED':
      icon = (
        <CovaultAuth requiredParent="dealer">
          <ErrorIcon color="error" data-testid="rejected-icon" />
        </CovaultAuth>
      )
      break
    case 'SUBMITTED':
      icon = (
        <CovaultAuth requiredParent="brand">
          <InfoIcon color="primary" data-testid="submitted-icon" />
        </CovaultAuth>
      )
      break
    default:
      break
  }

  return <StatusIconTableCell children={icon} />
}

export default withStyles(() => ({
  colorAction: {
    color: green[600],
  },
}))(StatusIcon)
