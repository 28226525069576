import gql from 'graphql-tag'
import { assumeRoleFragment, dealerFragment, dealerPartialFragment } from '../graphql'

export const DealersQueryDocument = gql`
  query Dealers {
    dealers {
      ...dealer
    }
  }

  ${dealerFragment}
`

export const DealerQueryDocument = gql`
  query DealerQueryDocument($id: ID!) {
    dealer(id: $id) {
      ...dealer
    }
  }

  ${dealerFragment}
`

export const CreateDealerMutationDocument = gql`
  mutation CreateDealer($dealer: DealerCreateParams!) {
    createDealer(dealer: $dealer) {
      dealer {
        ...dealerPartial
      }
      assumeRole {
        ...assumeRole
      }
    }
  }

  ${dealerPartialFragment}
  ${assumeRoleFragment}
`

export const UpdateDealerMutationDocument = gql`
  mutation UpdateDealer($dealer: DealerUpdateParams!, $id: ID!) {
    updateDealer(dealer: $dealer, id: $id) {
      dealer {
        ...dealerPartial
      }
      assumeRole {
        ...assumeRole
      }
    }
  }

  ${dealerPartialFragment}
  ${assumeRoleFragment}
`

export const LinkDealerToBrandMutationDocument = gql`
  mutation LinkDealerToBrand($id: ID!, $dealerId: ID!) {
    linkDealerToBrand(brandId: $id, dealerId: $dealerId) {
      brandId
    }
  }
`

export const LinkBrandToDealerMutationDocument = gql`
  mutation LinkDealerToBrand($brandId: ID!, $id: ID!) {
    linkDealerToBrand(brandId: $brandId, dealerId: $id) {
      brandId
    }
  }
`

export const UnlinkDealerFromBrandMutationDocument = gql`
  mutation UnlinkDealerFromBrand($id: ID!, $dealerId: ID!) {
    unlinkDealerFromBrand(brandId: $id, dealerId: $dealerId) {
      brandId
    }
  }
`

export const UnlinkBrandFromDealerMutationDocument = gql`
  mutation UnlinkBrandFromDealer($brandId: ID!, $id: ID!) {
    unlinkDealerFromBrand(brandId: $brandId, dealerId: $id) {
      brandId
    }
  }
`

export const DeleteDealerMutationDocument = gql`
  mutation DeleteDealer($id: ID!) {
    deleteDealer(id: $id) {
      id
    }
  }
`
