import * as React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'ramda'
import { translate } from 'react-i18next'
import CreateEntityButton from 'components/CreateEntityButton'
import CovaultTextField from 'components/CovaultTextField'
import CovaultFilePicker from 'components/CovaultFilePicker'
import CovaultAuth from 'components/CovaultAuth'
import { AdQueryDocument, CreateCreativeMutationDocument } from '../../graphql'
import withSnackbar from 'components/WithSnackbar'
import { uploadFile } from './uploadFileHelper'

export class CreateCreative extends React.Component {
  static propTypes = {
    adId: PropTypes.string.isRequired,
    channel: PropTypes.string.isRequired,
    snackbarContext: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }

  state = {
    file: void 0,
    loading: void 0,
    progress: void 0,
  }

  render() {
    const {
      props: { adId, t, channel, url },
      state: { loading, progress },
    } = this

    const isFacebook = channel === 'FACEBOOK'
    const isDoubleClick = channel === 'DOUBLE_CLICK'

    return (
      <CreateEntityButton
        mutation={CreateCreativeMutationDocument}
        buttonText={t('Upload Creative')}
        shortButtonText={t('+ Creative')}
        onCompleted={({ createCreative: { assumeRole } }) =>
          uploadFile.bind(this)(assumeRole, 'file', {
            errorMessage: 'Uploading Creative Failed',
            successMessage: 'Creative Created',
          })
        }
        initialVariables={[['adId', adId]]}
        refetchQueries={() => [{ query: AdQueryDocument, variables: { id: adId } }]}
        progress={progress}
        loading={loading}
        submitHook={() => this.setState({ loading: true })}
        errorHook={() => this.setState({ loading: false })}
      >
        {setValueFactory => (
          <React.Fragment>
            <CovaultFilePicker
              label={t('The Creative')}
              required
              setValue={(hasFile, file) => {
                if (!hasFile) {
                  setValueFactory('creative.filename', true)(null)
                  this.setState({ file: void 0 })
                } else {
                  this.setState({ file })
                  setValueFactory('creative.filename', true)(file.name)
                }
              }}
            />

            {isFacebook && (
              <CovaultTextField id="text" label={t('Post Text')} setValue={setValueFactory('creative.text')} />
            )}

            {isFacebook && (
              <CovaultTextField id="name" label={t('Headline')} setValue={setValueFactory('creative.name')} />
            )}

            {isFacebook && (
              <CovaultTextField
                id="description"
                label={t('Newsfeed Description')}
                setValue={setValueFactory('creative.description')}
              />
            )}

            {(isFacebook || isDoubleClick) && (
              <CovaultTextField
                defaultValue={url}
                id="clickThroughUrl"
                label={t('Click-through URL')}
                setValue={setValueFactory('creative.clickThroughUrl')}
              />
            )}
          </React.Fragment>
        )}
      </CreateEntityButton>
    )
  }
}

export default compose(
  withSnackbar,
  translate(),
  CovaultAuth({ requiredParent: 'dealer' })
)(CreateCreative)
