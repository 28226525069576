import * as React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'ramda'
import { translate } from 'react-i18next'
import { withApollo } from 'react-apollo'
import CreateEntityButton from 'components/CreateEntityButton'
import CovaultTextField from 'components/CovaultTextField'
import CovaultFilePicker from 'components/CovaultFilePicker'
import CovaultAuth from 'components/CovaultAuth'
import { AdQueryDocument, CreateCreativeMutationDocument } from '../../graphql'
import withSnackbar from 'components/WithSnackbar'
import FacebookAd from 'components/FacebookAd'
import { removeUndefined, tripleMergeDeep } from 'utils/helpers'
import { uploadFile, uploadTemporaryFile } from './uploadFileHelper'

const DEFAULT_VALUES = {
  description: 'Description goes here',
  text: 'Text goes here',
  name: 'Name goes here',
  clickThroughUrl: '',
}
const INITIAL_STATE = {
  file: void 0,
  loading: void 0,
  location: void 0,
  progress: void 0,
}

export class CreateFacebookCreative extends React.Component {
  static propTypes = {
    adId: PropTypes.string.isRequired,
    snackbarContext: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }

  state = INITIAL_STATE

  render() {
    const {
      props: { buttonText, shortButtonText, adId, dealer, t, url },
      state: { loading, location, progress },
    } = this

    return (
      <CreateEntityButton
        confirmBeforeClose={true}
        mutation={CreateCreativeMutationDocument}
        buttonText={buttonText || t('Upload Creative')}
        shortButtonText={shortButtonText || t('+ Creative')}
        onCompleted={async ({ createCreative: { assumeRole } }) => {
          await uploadFile.bind(this)(assumeRole, 'file', { errorMessage: 'Creative upload failed.' })
          this.setState(INITIAL_STATE)
        }}
        initialVariables={[['adId', adId]]}
        refetchQueries={() => [{ query: AdQueryDocument, variables: { id: adId } }]}
        progress={progress}
        loading={loading}
        submitHook={() => this.setState({ loading: true })}
        errorHook={() => this.setState({ loading: false })}
        livePreview={variables => (
          <FacebookAd
            dealer={dealer}
            creative={tripleMergeDeep(
              DEFAULT_VALUES,
              removeUndefined(variables.creative),
              removeUndefined({ location })
            )}
          />
        )}
      >
        {(setValueFactory, errorFields) => (
          <React.Fragment>
            <CovaultFilePicker
              label={t('Image')}
              required
              setValue={(hasFile, file) => {
                if (!hasFile) {
                  setValueFactory('creative.filename', true)(null)
                  this.setState({ file: void 0 })
                } else {
                  this.setState({ file })
                  setValueFactory('creative.filename', true)(file.name)
                  uploadTemporaryFile.bind(this)(file, 'file', 'location', { errorMessage: 'Creative upload failed.' })
                }
              }}
            />

            <CovaultTextField
              id="text"
              error={errorFields['text']}
              defaultValue={DEFAULT_VALUES.text}
              label={t('Post Text')}
              multiline
              setValue={setValueFactory('creative.text')}
              InputProps={{ multiline: true, rowsMax: 6 }}
            />

            <CovaultTextField
              id="name"
              error={errorFields['name']}
              defaultValue={DEFAULT_VALUES.name}
              label={t('Headline')}
              setValue={setValueFactory('creative.name')}
              InputProps={{ inputProps: { maxLength: 255 } }}
            />

            <CovaultTextField
              id="description"
              error={errorFields['description']}
              defaultValue={DEFAULT_VALUES.description}
              label={t('Newsfeed Description')}
              setValue={setValueFactory('creative.description')}
              InputProps={{ inputProps: { maxLength: 255 } }}
            />

            <CovaultTextField
              defaultValue={url}
              error={errorFields['clickThroughUrl']}
              id="clickThroughUrl"
              label={t('Click-through URL')}
              setValue={setValueFactory('creative.clickThroughUrl')}
            />
          </React.Fragment>
        )}
      </CreateEntityButton>
    )
  }
}

export default compose(
  withSnackbar,
  translate(),
  CovaultAuth({ requiredParent: 'dealer' }),
  withApollo
)(CreateFacebookCreative)
