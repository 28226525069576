import * as React from 'react'
import { Route, Switch } from 'react-router-dom'
import { redirectIfAuthenticated } from '../utils/authWrappers'
import LoginComponent from 'components/Login'
import SnackBarContext, { snackbarFactory } from 'context/snackbar'
import ContextSnackbar from 'components/ContextSnackbar'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import Intercom from 'components/Intercom'

import {
  AD_PATH,
  ADS_PATH,
  BRAND_PATH,
  BRANDS_PATH,
  CAMPAIGN_TEMPLATES_PATH,
  CAMPAIGN_TEMPLATE_PATH,
  DASHBOARD_PATH,
  DEALER_PATH,
  DEALERS_PATH,
  LOGIN_PATH,
  SALES_PROGRAM_PATH,
  SALES_PROGRAMS_PATH,
  ROOT_PATH,
  USER_SETTINGS_PATH,
} from '../constants'

import AdContainer from 'containers/AdContainer'
import AdsContainer from 'containers/AdsContainer'
import BrandsContainer from 'containers/BrandsContainer'
import BrandContainer from 'containers/BrandContainer'
import CampaignTemplatesContainer from 'containers/CampaignTemplatesContainer'
import CampaignTemplateContainer from 'containers/CampaignTemplateContainer'
import DealersContainer from 'containers/DealersContainer'
import DealerContainer from 'containers/DealerContainer'
import DashboardContainer from 'containers/DashboardContainer'
import SalesProgramsContainer from 'containers/SalesProgramsContainer'
import SalesProgramContainer from 'containers/SalesProgramContainer'
import UserSettingsContainer from 'containers/UserSettingsContainer'
import BrandPicker from 'components/BrandPicker'
import AuthenticatedNavRoute from 'components/AuthenticatedNavRoute'

export class App extends React.Component {
  static propTypes = {
    location: PropTypes.object,
  }

  constructor(props) {
    super(props)

    this.setSnackbarState = message => this.setState({ snackbar: { ...this.state.snackbar, ...message } })

    this.state = {
      snackbar: { setSnackbarState: this.setSnackbarState },
    }
  }

  render() {
    return (
      <SnackBarContext.Provider
        value={{
          ...this.state.snackbar,
          ...snackbarFactory(this.setSnackbarState),
        }}
      >
        <Intercom />
        <ContextSnackbar />
        <AppRoutes {...this.props} />
      </SnackBarContext.Provider>
    )
  }
}

class AppRoutes extends React.PureComponent {
  render() {
    return (
      <Switch>
        <Route path={LOGIN_PATH} component={redirectIfAuthenticated(LoginComponent)} />
        <AuthenticatedNavRoute path={ROOT_PATH} exact component={BrandPicker} />
        <AuthenticatedNavRoute path={ADS_PATH} exact component={AdsContainer} />
        <AuthenticatedNavRoute path={AD_PATH} component={AdContainer} />
        <AuthenticatedNavRoute path={BRANDS_PATH} exact component={BrandsContainer} />
        <AuthenticatedNavRoute path={BRAND_PATH} component={BrandContainer} />
        <AuthenticatedNavRoute path={CAMPAIGN_TEMPLATES_PATH} exact component={CampaignTemplatesContainer} />
        <AuthenticatedNavRoute path={CAMPAIGN_TEMPLATE_PATH} component={CampaignTemplateContainer} />
        <AuthenticatedNavRoute path={DEALERS_PATH} exact component={DealersContainer} />
        <AuthenticatedNavRoute path={DEALER_PATH} component={DealerContainer} />
        <AuthenticatedNavRoute path={SALES_PROGRAMS_PATH} exact component={SalesProgramsContainer} />
        <AuthenticatedNavRoute path={SALES_PROGRAM_PATH} component={SalesProgramContainer} />
        <AuthenticatedNavRoute path={DASHBOARD_PATH} exact component={DashboardContainer} />
        <AuthenticatedNavRoute path={USER_SETTINGS_PATH} exact component={UserSettingsContainer} />
        <AuthenticatedNavRoute path={ROOT_PATH} component={BrandPicker} />
      </Switch>
    )
  }
}

export default withRouter(App)
