import gql from 'graphql-tag'
import {
  adFragment,
  campaignTemplateFragment,
  channelQuestionFragment,
  creativeFragment,
  creativeTemplateFragment,
  dealerFragment,
  salesProgramFragment,
  dealerPartialFragment,
} from './'

export const DashboardQueryDocument = gql`
  query($brandId: ID!) {
    brand(id: $brandId) {
      id
      name
      salesPrograms {
        ...salesProgram
        campaignTemplates {
          ...campaignTemplate
          creativeTemplates {
            ...creativeTemplate
          }
        }
      }
      ads {
        ...ad
        salesProgram {
          ...salesProgram
        }
        channelQuestions {
          ...channelQuestion
        }
        creatives {
          ...creative
        }
        dealer {
          ...dealer
        }
      }
      productLines {
        id
        name
        logoLocation {
          domain
          path
          key
        }
      }
    }
    dealers {
      ...dealer
    }
  }

  ${adFragment}
  ${campaignTemplateFragment}
  ${channelQuestionFragment}
  ${creativeFragment}
  ${creativeTemplateFragment}
  ${dealerFragment}
  ${salesProgramFragment}
`

export const BrandDashboardQueryDocument = gql`
  query DealerByBrandQueryDocument($brandId: ID!) {
    brand(id: $brandId) {
      id
      dealers {
        ...dealerPartial
        logoLocation {
          domain
          key
          path
        }
        ads {
          ...ad
          salesProgram {
            ...salesProgram
          }
        }
      }
    }
  }

  ${adFragment}
  ${dealerPartialFragment}
  ${salesProgramFragment}
`
