import * as React from 'react'
import { css } from 'emotion'
import { Card, Icon } from '@material-ui/core'
import { DeleteStripeCard, SetDefaultStripeCard } from 'components/TransitionEntityButton'

const VALID_CARDS = ['mastercard', 'visa']

export default function StripeCard({ card, dealerId, isDefault }) {
  const {
    addressLine1,
    addressLine2,
    addressCity,
    addressState,
    addressCountry,
    addressZip,
    brand = '',
    id,
    name,
    last4,
    expMonth,
    expYear,
  } = card

  const brand_lowercase = brand.toLowerCase()

  return (
    <Card key={id} className={cardStyle}>
      <div>
        <div className={cardHeaderWrapperStyle}>
          <span className={cardHeaderLeftStyle}>
            {VALID_CARDS.includes(brand_lowercase) ? (
              <img className={cardLogoStyle} src={require(`assets/${brand.toLowerCase()}Logo.svg`)} alt="" />
            ) : (
              <Icon className={cardLogoStyle}>credit_card</Icon>
            )}
            <small>
              {brand} ending in {last4}
            </small>
          </span>
          <small className={cardHeaderRightStyle}>Expires {`${expMonth}/${expYear}`}</small>
        </div>
        <div className={cardBodyWrapperStyle}>
          <div className={cardBodyLeftStyle}>
            <small>
              <strong>Name on card</strong>
            </small>
            <small>{name}</small>
          </div>
          <div className={cardBodyRightStyle}>
            <small>
              <strong>Billing address</strong>
            </small>
            <small>
              {addressLine1} {addressLine2}
            </small>
            <small>
              {addressCity}, {addressState} {addressZip}
            </small>
            <small>{addressCountry}</small>
          </div>
        </div>
      </div>
      <div className={cardFooterWrapperStyle}>
        <div className={cardFooterLeftStyle}>
          {isDefault && (
            <small className={cardHeaderRightStyle}>
              <em>This card is set as the default card.</em>
            </small>
          )}
        </div>
        <div className={cardFooterRightStyle}>
          {isDefault ? <div /> : <SetDefaultStripeCard card={card} dealerId={dealerId} />}
          <DeleteStripeCard card={card} dealerId={dealerId} />
        </div>
      </div>
    </Card>
  )
}

const cardStyle = css`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  width: 445px;
  padding: 10px;
  margin-bottom: 32px;

  &:nth-child(odd) {
    margin-right: 8px;
  }
`
const cardHeaderWrapperStyle = css`
  display: flex;
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid #ccc;
`
const cardHeaderLeftStyle = css`
  display: flex;
  align-items: center;
  flex: 7 0 0;
`
const cardHeaderRightStyle = css`
  color: #666;
  display: flex;
  align-items: center;
  flex: 5 0 0;
`
const cardBodyWrapperStyle = css`
  display: flex;

  > div > small {
    margin-bottom: 4px;

    &:first-child {
      margin-bottom: 6px;
    }
  }
`
const cardBodyLeftStyle = css`
  display: flex;
  flex-flow: column;
  flex: 7 0 0;
`
const cardBodyRightStyle = css`
  display: flex;
  flex-flow: column;
  flex: 5 0 0;
`
const cardLogoStyle = css`
  width: 32px;
  margin-right: 8px;
`
const cardFooterWrapperStyle = css`
  display: flex;
`
const cardFooterLeftStyle = css`
  flex: 7 0 0;
  display: flex;
  align-items: flex-end;

  .${cardHeaderRightStyle} {
    margin-bottom: 4px;
  }
`
const cardFooterRightStyle = css`
  flex: 5 0 0;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`
