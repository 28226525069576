import * as React from 'react'
import { TableHead, TableRow } from '@material-ui/core'
import { StyledTableCell } from '../../materialUI'

export default function CampaignTableHeader() {
  return (
    <TableHead>
      <TableRow>
        <StyledTableCell>Name</StyledTableCell>
        <StyledTableCell> Product Line</StyledTableCell>
        <StyledTableCell collapse>Sales Program</StyledTableCell>
        <StyledTableCell collapse>Channel</StyledTableCell>
        <StyledTableCell>Budget</StyledTableCell>
      </TableRow>
    </TableHead>
  )
}
