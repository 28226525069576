import * as React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import { Typography } from '@material-ui/core'

const defaultQuestionType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired
})
const unitQuestionType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  quantityValue: PropTypes.number.isRequired,
  unitValue: PropTypes.string.isRequired
})
const channelQuestionType = PropTypes.oneOfType([defaultQuestionType, unitQuestionType])

export function ChannelQuestion({ channelQuestion }) {
  switch (channelQuestion.valueType) {
    case 'UNIT':
      return <UnitChannelQuestion channelQuestion={channelQuestion} />
    default:
      return <DefaultChannelQuestion channelQuestion={channelQuestion} />
  }
}

ChannelQuestion.propTypes = {
  channelQuestion: channelQuestionType.isRequired
}

function DefaultChannelQuestion({ channelQuestion: { label, value } }) {
  return (
    <Typography component="p" gutterBottom>
      <Trans>{label}:</Trans> {value}
    </Typography>
  )
}

DefaultChannelQuestion.propTypes = {
  channelQuestion: defaultQuestionType.isRequired
}

function UnitChannelQuestion({ channelQuestion: { label, quantityValue, unitValue } }) {
  return (
    <Typography component="p" gutterBottom>
      <Trans>{label}:</Trans> {quantityValue} {unitValue}
    </Typography>
  )
}

UnitChannelQuestion.propTypes = {
  channelQuestion: unitQuestionType.isRequired
}

export function ChannelQuestions({ channelQuestions }) {
  return channelQuestions.map(channelQuestion => {
    return <ChannelQuestion key={channelQuestion.name} channelQuestion={channelQuestion} />
  })
}

ChannelQuestions.propTypes = {
  channelQuestion: PropTypes.arrayOf(channelQuestionType)
}

export default ChannelQuestions
