import * as React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose, contains } from 'ramda'

import {
  getAuthClaimFromState,
  getParentId,
  getParentType,
  getPermissions,
  isBrand,
  isCovault,
  isDealer,
  permissionCheck,
} from 'utils/permissions'

export const renderAuthState = state => {
  const { auth: authState } = state
  const claim = getAuthClaimFromState(state)

  return {
    hasPermission: permissionCheck(claim),
    isAuthenticated: !!authState.isAuthenticated,
    isBrand: isBrand(claim),
    isCovault: isCovault(claim),
    isDealer: isDealer(claim),
    parentType: getParentType(claim),
    parentId: getParentId(claim),
    permissions: getPermissions(claim),
    profile: authState.profile,
  }
}

const mapStateToProps = state => ({
  auth: renderAuthState(state),
})

const CovaultAuthTemplate = ({
  auth,
  children,
  requiredParent,
  requiredPermission,
  auth: { parentType, hasPermission },
}) => {
  if (requiredParent) {
    if (requiredParent instanceof Array && !contains(parentType, requiredParent)) {
      return null
    }
    if (!(requiredParent instanceof Array) && parentType !== requiredParent) {
      return null
    }
  }

  if (requiredPermission && !hasPermission(requiredPermission)) {
    return null
  }

  return React.cloneElement(children, { auth })
}

CovaultAuthTemplate.propTypes = {
  auth: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
  requiredParent: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  requiredPermission: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
}

export const CovaultAuth = compose(connect(mapStateToProps))(CovaultAuthTemplate)

export const auth = ({ requiredParent = null, requiredPermission = null } = {}) => {
  return Component => props => (
    <CovaultAuth requiredParent={requiredParent} requiredPermission={requiredPermission}>
      <Component {...props} auth={{}} />
    </CovaultAuth>
  )
}

export default auth
