import * as React from 'react'
import { Trans } from 'react-i18next'
import { graphql } from 'react-apollo'
import { css } from 'emotion'
import { steps } from 'campaign/Steps'
import Title from 'components/Title'
import CampaignDetails from 'campaign/CampaignDetails'
import CallToActionCard from 'campaign/CallToActionCard'
import CampaignFromTemplateNotification from 'components/CampaignFromTemplateNotification'
import RunCampaignStepper from 'campaign/RunCampaignStepper'
import CreativeList from 'components/CreativeList'
import CTAButtonWrapper from 'components/CTAButtonWrapper'
import FacebookCreativeList from 'components/FacebookCreativeList'
import graphQLData from 'hoc/GraphQLData'
import SecondaryNavBar from 'components/SecondaryNavBar'
import ScrollWrapper from 'components/ScrollWrapper'
import PropTypes from 'prop-types'
import withBrandId from 'components/WithBrandId'
import { compose, find, prop, findIndex } from 'ramda'
import { CovaultAuth, auth } from 'components/CovaultAuth'
import { CreateCreative, CreateFacebookCreative } from 'components/EntityButtons'
import { SectionPaper, StyledCardHeader } from 'components/materialUI'
import { Typography } from '@material-ui/core'
import {
  ApproveAd,
  DeleteAd,
  SubmitAdForApproval,
  ResubmitAdForApproval,
  RejectAd,
} from 'components/TransitionEntityButton'
import PublishCampaign from 'components/PublishCampaign'
import { BREAKPOINTS } from '../constants'
import { AdQueryDocument } from '../graphql'
import RequestContainer from 'components/requestForm/RequestContainer'
import isSinglePartyDealer from 'hoc/IsSinglePartyDealer'
import SinglePartyDealerOnly from 'hoc/SinglePartyDealerOnly'
import SinglePartyCampaignDetails from 'components/singlePartyDealerComponents/campaignDetails'

export class AdContainer extends React.Component {
  static propTypes = {
    brandId: PropTypes.string,
    data: PropTypes.object.isRequired,
  }

  getCurrentStep(campaign) {
    const step = findIndex(({ campaignStepComplete }) => !campaignStepComplete(campaign))(steps)
    return step === -1 ? steps.length : step
  }

  render() {
    const {
      auth,
      brandId,
      data: { dealers },
      isSinglePartyDealer,
    } = this.props

    // Find the dealer with the Ad. We can remove this when
    // there is a query which treats Ads as a top level entity
    const dealer = find(prop('ad'), dealers)

    const {
      ad,
      ad: { channel, id, creatives, name, endDate, budgetInCents },
      brands,
      url,
    } = dealer

    const brand = brands.find(brand => brand.id === brandId)
    const currentStep = this.getCurrentStep(ad)
    const stepperTitles = steps.map(({ stepperTitle }) => stepperTitle(this.props, this.state))

    const isDealer = auth.parentType === 'dealer'

    if (ad.channel === 'REQUEST') {
      return <RequestContainer auth={auth} ad={ad} brandId={brandId} />
    }

    return (
      <React.Fragment>
        <CampaignFromTemplateNotification />
        <SecondaryNavBar>
          <Title>{name}</Title>
          <CTAButtonWrapper>
            <DeleteAd ad={ad} />
            {(!isSinglePartyDealer && Date.parse(endDate) > new Date()) && <SubmitAdForApproval ad={ad} />}
            <PublishCampaign campaign={ad} isSingleParty={isSinglePartyDealer} disabled={!budgetInCents} />
            <ResubmitAdForApproval ad={ad} />
            <ApproveAd ad={ad} />
            <RejectAd ad={ad} />
            {ad.status === 'SUBMITTED' && isDealer && (
              <Typography variant="button">
                <Trans>Ad Submitted for Approval</Trans>
              </Typography>
            )}
          </CTAButtonWrapper>
        </SecondaryNavBar>

        <ScrollWrapper>
          {ad.channel !== 'REQUEST' && (
            <React.Fragment>
              <div className={stepperWrapperStyle}>
                <RunCampaignStepper
                  activeStep={currentStep}
                  stepperTitles={stepperTitles}
                  lastStepTitle={isSinglePartyDealer ? 'Publish' : null}
                  lastStepGrayed={isSinglePartyDealer && ad.status !== 'PUBLISHED'}
                />
              </div>

              <div className={flexWrapper}>
                <CampaignDetails campaign={ad} dealer={dealer} brandId={brandId} />
                {!isSinglePartyDealer && <CallToActionCard campaign={ad} dealer={dealer} brand={brand} />}
                <SinglePartyDealerOnly>
                  <SinglePartyCampaignDetails ad={ad} />
                </SinglePartyDealerOnly>
              </div>

              <SectionPaper elevation={0}>
                <div className={creativesListHeaderStyle}>
                  <StyledCardHeader
                    title={`Creatives in this ${isSinglePartyDealer ? 'Digital Template' : 'Campaign'}`}
                  />
                  {ad.status === 'UNSUBMITTED' || ad.status === 'REJECTED' ? (
                    ad.channel === 'FACEBOOK' ? (
                      <CreateFacebookCreative
                        dealer={dealer}
                        url={url}
                        adId={id}
                        channel={channel}
                        buttonText={isSinglePartyDealer && 'Create New Template'}
                        shortButtonText={isSinglePartyDealer && '+ Template'}
                      />
                    ) : (
                      <CreateCreative url={url} adId={id} channel={channel} />
                    )
                  ) : null}
                </div>
                {creatives.length === 0 && (
                  <CovaultAuth requiredPermission="ad:manage">
                    <React.Fragment>
                      <br />
                      <Typography component="title">There are no Creatives associated with this Campaign.</Typography>
                    </React.Fragment>
                  </CovaultAuth>
                )}

                {ad.channel === 'FACEBOOK' ? (
                  <FacebookCreativeList dealer={dealer} ad={ad} />
                ) : (
                  <CreativeList ad={ad} />
                )}
              </SectionPaper>
            </React.Fragment>
          )}
        </ScrollWrapper>
      </React.Fragment>
    )
  }
}

const flexWrapper = css`
  display: flex;
  align-items: stretch;
  align-content: stretch;
  flex-wrap: wrap-reverse;
`
const stepperWrapperStyle = css`
  margin-top: 24px;

  @media (max-width: ${BREAKPOINTS.phone}) {
    display: none;
  }
`
const creativesListHeaderStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export default compose(
  auth({
    requiredPermission: 'ad:view',
  }),
  withBrandId,
  graphql(AdQueryDocument, {
    options: props => ({
      variables: {
        id: props.match.params.id,
      },
    }),
  }),
  graphQLData,
  isSinglePartyDealer
)(AdContainer)
