import * as React from 'react'
import Raven from 'raven-js'
import Error from 'components/Error'
import { withRouter } from 'react-router-dom'
import { generatePath } from 'react-router'
import { ROOT_PATH } from 'constants/paths'

export function parseError(error) {
  if (!error) {
    return {
      title: 'Unexpected Error',
      message: 'An unexpected error occurred.'
    }
  }

  if (typeof error === 'string') {
    return {
      title: 'Error',
      message: error
    }
  }

  if (error.networkError && error.message && error.networkError.statusCode === 400) {
    return {
      title: 'Unexpected GraphQL Error',
      message: error.message
    }
  }

  if (error.message) {
    return {
      title: 'Unexpected Error',
      message: error.message
    }
  }

  return {
    title: 'Unexpected Error',
    message: 'An unexpected error occurred.'
  }
}

export class BareErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, error: '', info: '' }
  }

  componentDidCatch(error, info) {
    if (error.name === 'BrandIdError') {
      this.props.history.push(generatePath(ROOT_PATH))
      return
    }

    this.setState({ hasError: true, error, info })
    Raven.captureException(error, { extra: info })
  }

  render() {
    if (this.state.hasError) {
      const { title, message } = parseError(this.state.error)
      return <Error title={title} message={message} />
    }
    return this.props.children
  }
}

export const ErrorBoundary = withRouter(BareErrorBoundary)

export const withErrorBoundary = Component => props => (
  <ErrorBoundary>
    <Component {...props} />
  </ErrorBoundary>
)

export default ErrorBoundary
