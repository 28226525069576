import * as React from 'react'
import { compose, path } from 'ramda'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'
import { SalesProgramQueryDocument, UpdateSalesProgramMutationDocument } from '../../graphql'
import CreateEntityButton from 'components/CreateEntityButton'
import CovaultTextField from 'components/CovaultTextField'
import CovaultFilePicker from 'components/CovaultFilePicker'
import CovaultDatePicker from 'components/CovaultDatePicker'
import { auth } from 'components/CovaultAuth'
import { startManagedUpload } from 'utils/upload'
import withSnackbar from 'components/WithSnackbar'

const halfWidth = {
  width: '50%',
}

export class EditSalesProgram extends React.Component {
  static propTypes = {
    salesProgram: PropTypes.object.isRequired,
    brandId: PropTypes.string.isRequired,
    snackbarContext: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }

  state = {
    file: void 0,
    progress: void 0,
    loading: void 0,
  }

  uploadFile = async assumeRole => {
    if (!this.state.file) {
      this.props.snackbarContext.successMessage('Sales Program Updated', '')
      return this.setState({ loading: false })
    }

    this.setState({ loading: true })
    const managedUpload = startManagedUpload(this.state.file, assumeRole)
    managedUpload.on('httpUploadProgress', ({ loaded, total }) =>
      this.setState({ progress: total > 0 ? loaded / total : 0 })
    )

    await managedUpload.promise().then(
      () => {
        this.props.snackbarContext.successMessage('Sales Program Updated', '')
      },
      error => {
        this.props.snackbarContext.errorMessage('Uploading Sales Program Document Failed', '')
      }
    )
    this.setState({ loading: void 0, progress: void 0 })
  }

  render() {
    const {
      props: {
        t,
        salesProgram,
        salesProgram: { id, status },
        brandId,
      },
      state: { loading, progress },
    } = this

    if (status === 'ARCHIVED') {
      return null
    }

    return (
      <CreateEntityButton
        mutation={UpdateSalesProgramMutationDocument}
        refetchQueries={() => [
          {
            query: SalesProgramQueryDocument,
            variables: {
              salesProgramId: id,
              brandId: Number(brandId),
            },
          },
        ]}
        onCompleted={async ({ updateSalesProgram: { assumeRole } }) => {
          await this.uploadFile(assumeRole)
        }}
        buttonText={t('Edit Sales Program')}
        shortButtonText={t('Edit')}
        initialVariables={[['salesProgramId', id]]}
        progress={progress}
        loading={loading}
        submitHook={() => this.setState({ loading: true })}
        errorHook={() => this.setState({ loading: false })}
      >
        {setValueFactory => (
          <React.Fragment>
            <CovaultTextField
              id="name"
              setValue={setValueFactory('salesProgram.name')}
              defaultValue={path(['name'], salesProgram)}
              label={t('Name')}
            />
            <CovaultTextField
              id="code"
              setValue={setValueFactory('salesProgram.code')}
              defaultValue={path(['code'], salesProgram)}
              label={t('Code')}
            />
            <CovaultFilePicker
              accept=".pdf"
              label={t('Description Document')}
              setValue={(hasFile, file) => {
                if (!hasFile) {
                  setValueFactory('salesProgram.documentFilename')(null)
                  this.setState({ file: void 0 })
                } else {
                  setValueFactory('salesProgram.documentFilename')(file.name)
                  this.setState({ file })
                }
              }}
            />
            <CovaultDatePicker
              autoOk
              defaultValue={path(['startDate'], salesProgram)}
              disablePast
              emptyLabel="YYYY-MM-DD"
              label={t('Start Date')}
              setValue={setValueFactory('salesProgram.startDate')}
              style={halfWidth}
              testid="edit-sales-program-start-date-picker"
            />
            <CovaultDatePicker
              autoOk
              defaultValue={path(['endDate'], salesProgram)}
              disablePast
              emptyLabel="YYYY-MM-DD"
              label={t('End Date')}
              setValue={setValueFactory('salesProgram.endDate')}
              style={halfWidth}
              testid="edit-sales-program-end-date-picker"
            />
          </React.Fragment>
        )}
      </CreateEntityButton>
    )
  }
}

export default compose(
  withSnackbar,
  translate(),
  auth({
    requiredParent: ['brand', 'covault'],
    requiredPermission: 'sales_program:manage',
  })
)(EditSalesProgram)
