import * as React from 'react'
import NavBar from 'components/NavBar'
import Sidebar from 'components/Sidebar'
import { css } from 'emotion'
import { withErrorBoundary } from './ErrorBoundary'
import { BREAKPOINTS } from '../constants'

const appContainerStyle = css`
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  height: 100%;
`
const viewContainerStyle = css`
  display: flex;
  flex-wrap: nowrap;
  flex-grow: 1;
  overflow: hidden;

  @media (max-width: ${BREAKPOINTS.tablet}) {
    align-content: flex-start;
    flex-direction: column;
  }
`
const componentContainerStyle = css`
  flex: 6 1 0;
  display: flex;
  flex-flow: column;
  overflow: hidden;
`
const sidebarContainerStyle = css`
  flex: 1 1 0;
  vertical-align: top;
  max-width: 174px;

  @media (max-width: ${BREAKPOINTS.tablet}) {
    flex-grow: 0;
    max-width: unset;
  }
`

export const withNavBars = Component => props => (
  <div className={appContainerStyle}>
    <NavBar />
    <div className={viewContainerStyle}>
      <div className={sidebarContainerStyle}>
        <Sidebar />
      </div>
      <div className={componentContainerStyle}>
        <Component {...props} />
      </div>
    </div>
  </div>
)

export default Component => withNavBars(withErrorBoundary(Component))
