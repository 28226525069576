import * as React from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { translate } from 'react-i18next'
import PropTypes from 'prop-types'
import Link from 'components/Link'
import { DealersQueryDocument } from '../graphql'
import Title from 'components/Title'
import ScrollWrapper from 'components/ScrollWrapper'
import { graphql } from 'react-apollo'
import { compose } from 'ramda'
import { DEALER_PATH } from 'constants/paths'
import { UnlinkFromBrand } from 'components/TransitionEntityButton'

export function DealersTable({ t, brandId, dealers, loading, error, showUnlinkButton }) {
  if (loading) {
    return null
  }
  if (error) {
    return (
      <Title>
        {t('Error!')} {error.message}
      </Title>
    )
  }

  return (
    <ScrollWrapper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('Dealer Name')}</TableCell>
            <TableCell numeric>{t('Number of Brands')}</TableCell>
            <TableCell numeric>{t('Dealer ID')}</TableCell>
            {showUnlinkButton && <TableCell />}
          </TableRow>
        </TableHead>
        {dealers && (
          <TableBody>
            {dealers.map((dealer, i) => {
              const { brands, name, id } = dealer

              return (
                <TableRow key={id}>
                  <TableCell>
                    <Link path={DEALER_PATH} params={{ id }}>
                      {name}
                    </Link>
                  </TableCell>
                  <TableCell numeric>{brands.length}</TableCell>
                  <TableCell numeric>{id}</TableCell>
                  {showUnlinkButton && (
                    <TableCell>
                      <UnlinkFromBrand dealer={{ ...dealer, attachedBrandId: brandId }} />
                    </TableCell>
                  )}
                </TableRow>
              )
            })}
          </TableBody>
        )}
      </Table>
    </ScrollWrapper>
  )
}

DealersTable.propTypes = {
  brandId: PropTypes.string,
  dealers: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.object,
  showUnlinkButton: PropTypes.bool,
  t: PropTypes.func.isRequired,
}

export const TranslatedDealersTable = translate()(DealersTable)

export default compose(
  graphql(DealersQueryDocument, {
    props: ({ data: { dealers, loading, error } }) => ({
      dealers,
      loading,
      error,
    }),
  })
)(TranslatedDealersTable)
