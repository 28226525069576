import * as React from 'react'
import { UpdateAdMutationDocument, AdQueryDocument, AdsQueryDocument } from '../../graphql'
import CreateEntityButton from 'components/CreateEntityButton'
import { translate } from 'react-i18next'
import PropTypes from 'prop-types'
import { mapMutationVariables } from './CreateAd'
import AdForm from '../AdForm'
import covaultAuth from 'components/CovaultAuth'
import { compose, find, join, merge, prop, propEq } from 'ramda'
import withSnackbar from 'components/WithSnackbar'
import withBrandId from 'components/WithBrandId'

export const findChannelQuestion = name => find(propEq('name', name))
export const getChannelQuestionValue = (propName, name) =>
  compose(
    prop(propName),
    findChannelQuestion(name)
  )
export const getUnitChannelQuestionValue = name => getChannelQuestionValue('quantityValue', name)
export const getStringChannelQuestionValue = name => getChannelQuestionValue('value', name)

const mapAdToInitialVariables = (ad, dealer) => {
  const { budgetInCents, channel, endDate, name, salesProgram, startDate, channelQuestions } = ad

  return [
    ['budget', budgetInCents && budgetInCents / 100],
    ['channel', channel],
    ['name', name],
    ['endDate', endDate],
    ['startDate', startDate],
    ['salesProgramEndDate', salesProgram.endDate],
    ['salesProgramStartDate', salesProgram.startDate],
    ['radius', getUnitChannelQuestionValue('radius')(channelQuestions)],
    ['audience', getStringChannelQuestionValue('audience')(channelQuestions)],
    ['emailDestination', getStringChannelQuestionValue('email_destination')(channelQuestions)],
    ['targetAddressLine1', dealer.address],
    ['targetAddressLine2', join(', ', [dealer.city, dealer.state, dealer.country])],
  ]
}

class EditAd extends React.Component {
  render() {
    const {
      props: {
        ad,
        ad: { channel, id, status },
        brandId,
        buttonText,
        campaignTemplate,
        dealer = {},
        history,
        snackbarContext,
        t,
        ...props
      },
    } = this

    if (status !== 'UNSUBMITTED' && status !== 'REJECTED') {
      return null
    }

    return (
      <CreateEntityButton
        mutation={UpdateAdMutationDocument}
        mapMutationVariables={variables => {
          const formMap = mapMutationVariables(variables)
          return merge(formMap, { id })
        }}
        titleText={'Give us some details about your Campaign:'}
        buttonText={t('Edit')}
        refetchQueries={() => [
          { query: AdsQueryDocument, variables: { brandId } },
          { query: AdQueryDocument, variables: { id } },
        ]}
        onCompleted={() => snackbarContext.successMessage('Ad Updated', '')}
        resetOnOpen
        initialVariables={mapAdToInitialVariables(ad, dealer)}
        {...props}
      >
        {(setValueFactory, errorFields, variables) => (
          <AdForm
            errorFields={errorFields}
            selectedChannel={channel}
            setValueFactory={setValueFactory}
            showBaseOptions
            t={t}
            variables={variables}
          />
        )}
      </CreateEntityButton>
    )
  }
}

EditAd.propTypes = {
  ad: PropTypes.object.isRequired,
  brandId: PropTypes.string,
  dealer: PropTypes.object.isRequired,
  snackbarContext: PropTypes.object.isRequired,
  t: PropTypes.func,
}

export default compose(
  withSnackbar,
  translate(),
  covaultAuth({
    requiredParent: 'dealer',
    requiredPermission: 'ad:manage',
  }),
  withBrandId
)(EditAd)
