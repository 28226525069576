import * as React from 'react'
import { Trans } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  GridList,
  GridListTile,
} from '@material-ui/core'
import { sort } from 'ramda'
import TextShrinker from 'components/TextShrinker'
import digitalTemplatesLogo from 'assets/destination-digital-templates.png'
import printMediaLogo from 'assets/destination-printmedia.png'
import { css } from 'emotion'

const gridImg = css`
  width: 190px !important;
  height: 190px;
`
const gridListTile = css`
  cursor: pointer;
`
export const styles = theme => ({
  tableCell: {
    textAlign: 'right',
  },
})

const StyledGridList = withStyles({
  root: {
    margin: 'auto !important',
  },
})(GridList)
const FadedGridListTile = withStyles({
  root: {
    cursor: 'initial',
    opacity: 0.3,
  },
})(GridListTile)
const CenteredTypography = withStyles({
  root: {
    textAlign: 'center',
  },
})(Typography)

export function selectProductLineContent(props, state) {
  const {
    data: {
      brand: { productLines },
    },
    isSinglePartyDealer,
  } = props
  const { selectProductLineId } = state

  return (
    <SelectProductLineTable
      productLines={productLines}
      selectProductLineId={selectProductLineId}
      isSinglePartyDealer={isSinglePartyDealer}
    />
  )
}

export function selectProductLineAction(props, state) {
  return ''
}

export function BareSelectProductLineTable(props) {
  const { productLines, selectProductLineId, classes, isSinglePartyDealer } = props

  if (isSinglePartyDealer) {
    const sortedProductLines = sort((p1, p2) => p2.campaignTemplates.length - p1.campaignTemplates.length, productLines)

    return (
      <React.Fragment>
        <StyledGridList cellHeight={190} cols={2}>
          <GridListTile className={gridListTile} onClick={() => selectProductLineId(sortedProductLines[0].id)}>
            <img className={gridImg} src={digitalTemplatesLogo} alt="Facebook Logo" />
          </GridListTile>
          <FadedGridListTile className={gridListTile}>
            <img className={gridImg} src={printMediaLogo} alt="Newspaper Logo" />
          </FadedGridListTile>
        </StyledGridList>
      </React.Fragment>
    )
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <Trans>Name</Trans>
          </TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {productLines
          .filter(productLine => productLine.campaignTemplates.length)
          .map(({ name, id }, i) => (
            <TableRow key={id}>
              <TableCell>{name}</TableCell>
              <TableCell className={classes.tableCell}>
                <Button
                  color="primary"
                  onClick={() => selectProductLineId(id)}
                  data-testid={`SelectProductLineTable-select-${id}`}
                >
                  <Trans>Select</Trans>
                </Button>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  )
}

BareSelectProductLineTable.propTypes = {
  productLines: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectProductLineId: PropTypes.func.isRequired,
}

export const SelectProductLineTable = withStyles(styles)(BareSelectProductLineTable)

export const productLineStep = {
  title: ({ isSinglePartyDealer }) =>
    isSinglePartyDealer ? (
      <CenteredTypography variant="title" gutterBottom data-testid="SelectProductLineTable-title">
        <Trans>Select a Media Channel</Trans>
      </CenteredTypography>
    ) : (
      <Typography variant="title" gutterBottom data-testid="SelectProductLineTable-title">
        <Trans>Select a Product Line</Trans>
      </Typography>
    ),
  stepperTitle: ({ isSinglePartyDealer }) => (
    <TextShrinker
      fullText={isSinglePartyDealer ? 'Select a Media Channel' : 'Select a Product Line'}
      shortText={isSinglePartyDealer ? 'Media Channel' : 'Product Line'}
    />
  ),
  content: selectProductLineContent,
  action: selectProductLineAction,
  runStepComplete: (props, { productLineId }) => Boolean(productLineId),
  campaignStepComplete: ({ salesProgram }) => Boolean(salesProgram),
  undoStep: () => ({ productLineId: null }),
}
