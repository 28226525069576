import gql from 'graphql-tag'

export const adFragment = gql`
  fragment ad on Ad {
    budgetInCents
    channel
    endDate
    id
    name
    startDate
    status
  }
`

export const adPartialFragment = gql`
  fragment adPartial on Ad {
    budgetInCents
    channel
    endDate
    id
    name
    startDate
    status
  }
`

export const assumeRoleFragment = gql`
  fragment assumeRole on AssumeRoleData {
    accessKeyId
    bucket
    fileKey
    secretAccessKey
    sessionToken
    unmodifiedFileUrl
  }
`

export const brandFragment = gql`
  fragment brand on Brand {
    id
    name
    notificationEmail
    logoLocation {
      domain
      path
      key
    }
    productLines {
      id
      name
      logoLocation {
        domain
        path
        key
      }
    }
    dealers {
      id
      name
      brands {
        name
        id
      }
    }
    salesPrograms {
      id
      name
      code
      status
      startDate
      endDate
      productLine {
        id
        name
      }
    }
  }
`

export const campaignTemplateFragment = gql`
  fragment campaignTemplate on CampaignTemplate {
    channel
    defaultAudience
    description
    id
    name
    status
  }
`

export const channelQuestionFragment = gql`
  fragment channelQuestion on ChannelQuestion {
    valueType
    name
    label
    required
    ... on StringChannelQuestion {
      value
      default
    }
    ... on DateChannelQuestion {
      value
      default
    }
    ... on IntegerChannelQuestion {
      value
      default
    }
    ... on EnumChannelQuestion {
      value
      values
      displayNames
      default
    }
    ... on UnitChannelQuestion {
      quantityValue
      quantityDefault
      unitValue
      unitValues
      unitDefault
    }
  }
`

export const creativeFragment = gql`
  fragment creative on Creative {
    id
    name
    status
    text
    description
    clickThroughUrl
    location {
      domain
      path
      key
    }
    overlayLocation {
      domain
      path
      key
    }
  }
`

export const creativeTemplateFragment = gql`
  fragment creativeTemplate on CreativeTemplate {
    id
    name
    text
    description
    location {
      domain
      path
      key
    }
    overlayLocation {
      domain
      path
      key
    }
  }
`

export const productLineFragment = gql`
  fragment productLine on ProductLine {
    name
    logoLocation {
      domain
      path
      key
    }
    id
  }
`

export const salesProgramFragment = gql`
  fragment salesProgram on SalesProgram {
    code
    endDate
    startDate
    id
    name
    status
    productLine {
      id
      name
    }
  }
`

export const dealerPartialFragment = gql`
  fragment dealerPartial on Dealer {
    id
    name
    notificationEmail
    address
    city
    state
    zip
    url
    country
    isSingleParty
    facebookSlug
  }
`

export const dealerFragment = gql`
  fragment dealer on Dealer {
    ...dealerPartial
    logoLocation {
      domain
      key
      path
    }
    brands {
      id
      name
      productLines {
        id
      }
    }
  }

  ${dealerPartialFragment}
`

export const stripeCardFragment = gql`
  fragment stripeCard on StripeCard {
    id
    addressCity
    addressCountry
    addressLine1
    addressLine1Check
    addressLine2
    addressState
    addressZip
    addressZipCheck
    brand
    country
    currency
    customer
    cvcCheck
    expMonth
    expYear
    fingerprint
    funding
    last4
    name
    object
    defaultSource
  }
`

export const userProfileFragment = gql`
  fragment userProfile on UserProfile {
    name
    id
    auth {
      email
      loginsCount
      lastLogin
    }
    roles {
      id
      name
      description
    }
    availableRoles {
      id
      name
      description
    }
  }
`
