import gql from 'graphql-tag'
import { stripeCardFragment } from '../graphql'

export const CreateStripeCardMutationDocument = gql`
  mutation CreateStripeCard($token: ID!, $dealerId: ID!) {
    createStripeCard(token: $token, dealerId: $dealerId) {
      id
    }
  }
`

export const DeleteStripeCardMutationDocument = gql`
  mutation DeleteStripeCard($id: ID!, $dealerId: ID!) {
    deleteStripeCard(cardId: $id, dealerId: $dealerId) {
      id
    }
  }
`

export const SetDefaultStripeCardMutationDocument = gql`
  mutation SetDefaultStripeCard($id: ID!, $dealerId: ID!) {
    setDefaultStripeSource(cardId: $id, dealerId: $dealerId) {
      id
    }
  }
`

export const StripeCardsQueryDocument = gql`
  query StripeCards($dealerId: ID!) {
    dealer(id: $dealerId) {
      id
      facebookSlug
      stripeCustomer {
        id
        description
        stripeCards {
          ...stripeCard
        }
      }
    }
  }

  ${stripeCardFragment}
`
