import * as React from 'react'
import { TableRow } from '@material-ui/core'
import { CHANNEL_NAMES } from '../../../constants/channels'
import { StyledTableCell } from '../../materialUI'
import { AD_PATH } from '../../../constants/paths'
import { Link } from '../../Link'
import CurrencyFormat from '../../CurrencyFormat'
import { Ad } from '../../../types'
import withBrandId from '../../WithBrandId'

type CampaignTableRowProps = {
  campaign: Ad
}

const CampaignTableRow: React.ComponentType<CampaignTableRowProps> = withBrandId(BareCampaignTableRow)

export default CampaignTableRow

type BrandId = {
  brandId: string
}

function BareCampaignTableRow({ campaign, brandId }: CampaignTableRowProps & BrandId) {
  const {
    id,
    name,
    channel,
    salesProgram: {
      name: salesProgramName,
      productLine: { name: productLineName },
    },

    budgetInCents,
  } = campaign

  return (
    <TableRow>
      <StyledTableCell>
        <Link path={AD_PATH} params={{ id, brandId }}>
          {channel === 'REQUEST' ? `Co-op Request #${id}` : name}
        </Link>
      </StyledTableCell>
      <StyledTableCell>{productLineName}</StyledTableCell>
      <StyledTableCell collapse>{salesProgramName}</StyledTableCell>
      <StyledTableCell collapse>{CHANNEL_NAMES[channel]}</StyledTableCell>
      <StyledTableCell>
        <CurrencyFormat displayType="text" fixedDecimalScale value={(budgetInCents || 0) / 100} />
      </StyledTableCell>
    </TableRow>
  )
}
