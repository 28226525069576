import * as React from 'react'
import PropTypes from 'prop-types'
import { TABLET_SIZE } from '../constants'

export default class TextShrinker extends React.Component {
  static propTypes = {
    fullText: PropTypes.string,
    shortText: PropTypes.string,
  }

  state = {
    showShortText: window.innerWidth < TABLET_SIZE && this.props.shortText,
  }

  shouldComponentUpdate = () => false

  render = () => (this.state.showShortText ? this.props.shortText : this.props.fullText)
}
