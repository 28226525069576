import * as React from 'react'
import graphQLData from 'hoc/GraphQLData'
import NewUserProfile from './NewUserProfile'
import PropTypes from 'prop-types'
import { compose } from 'ramda'
import { graphql } from 'react-apollo'
import { UserProfileForBrand, UserProfileForDealer } from './UserProfile'
import { BrandUserProfilesQueryDocument, DealerUserProfilesQueryDocument } from '../../graphql'

export function UserProfileContainer(props) {
  const {
    brandId,
    dealerId,
    data: {
      parent: { userProfiles },
    },
  } = props

  return (
    <React.Fragment>
      {userProfiles.map((userProfile, i) => (
        <React.Fragment key={i}>
          {brandId && <UserProfileForBrand userProfile={userProfile} parentId={brandId} />}
          {dealerId && <UserProfileForDealer userProfile={userProfile} parentId={dealerId} />}
        </React.Fragment>
      ))}
      <NewUserProfile brandId={brandId} dealerId={dealerId} />
    </React.Fragment>
  )
}

export const UserProfileContainerForBrand = compose(
  graphql(BrandUserProfilesQueryDocument, {
    options: ({ brandId }) => ({ variables: { brandId } }),
  }),
  graphQLData
)(UserProfileContainer)

UserProfileContainerForBrand.propTypes = {
  brandId: PropTypes.string.isRequired,
}

export const UserProfileContainerForDealer = compose(
  graphql(DealerUserProfilesQueryDocument, {
    options: ({ dealerId }) => ({ variables: { dealerId } }),
  }),
  graphQLData
)(UserProfileContainer)

UserProfileContainerForDealer.propTypes = {
  dealerId: PropTypes.string.isRequired,
}
