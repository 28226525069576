import * as React from 'react'
import { DESKTOP_BREAKPOINT, PHONE_BREAKPOINT, TABLET_BREAKPOINT, AD_PATH } from '../constants'
import { withStyles } from '@material-ui/core/styles'
import { generatePath } from 'react-router'
import { graphql } from 'react-apollo'
import { steps } from 'campaign/Steps'
import { withRouter } from 'react-router-dom'
import graphQLData from 'hoc/GraphQLData'
import Loading from 'components/Loading'
import moment from 'moment'
import PropTypes from 'prop-types'
import withBrandId from 'components/WithBrandId'
import { compose, findIndex, contains, without } from 'ramda'
import { RunCampaignMutationDocument, RunCampaignQueryDocument } from '../graphql'
import { DialogContent, DialogTitle } from '@material-ui/core'
import { getProductLine, getCampaignTemplate, getSalesProgram } from 'campaign/Utils'
import RunCampaignStepper from 'campaign/RunCampaignStepper'
import ScrollWrapper from '../components/ScrollWrapper'
import { css } from 'emotion'
import IsSinglePartyDealer from 'hoc/IsSinglePartyDealer'

const styles = {
  container: {
    overflowY: 'auto',
  },
  dialogContent: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  actionText: {
    marginRight: 20,
  },
  currentTitleWrapper: {
    margin: '12px 24px 0',
    padding: '0 0 12px 24px',
  },
}

export class RunCampaignContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      channel: props.channel,
      productLineId: props.productLineId,
      salesProgramId: props.salesProgramId,
      campaignTemplateId: props.campaignTemplateId,
      creativeTemplateIds: props.creativeTemplateIds ? props.creativeTemplateIds : [],
      creativeTemplatesSelected: props.creativeTemplatesSelected,
      budgetInCents: props.budgetInCents,
      startDate: props.startDate ? props.startDate : moment().format('YYYY-MM-DD'),
      endDate: props.endDate
        ? props.endDate
        : moment()
            .add(1, 'M')
            .format('YYYY-MM-DD'),
      salesProgramEndDate: null,
      audience: props.audience,
      campaignDetailsSet: false,
      selectProductLineId: productLineId => {
        this.setState({ productLineId })
      },
      selectCampaignTemplateId: this.selectCampaignTemplateId,
      toggleCreativeTemplateId: this.toggleCreativeTemplateId,
      setcreativeTemplatesSelected: creativeTemplatesSelected => {
        this.setState({ creativeTemplatesSelected })
      },
      setBudgetInCents: budgetInCents => {
        this.setState({ budgetInCents })
      },
      setStartDate: startDate => {
        this.setState({ startDate })
      },
      setEndDate: endDate => {
        this.setState({ endDate })
      },
      setAudience: audience => {
        this.setState({ audience })
      },
      createCampaign: this.createCampaign,
    }

    this.steps = steps.filter((_, i) => !(props.isSinglePartyDealer && i === 3))
  }

  selectCampaignTemplateId = campaignTemplateId => {
    const {
      props: {
        data: {
          brand: { productLines },
        },
      },
      state: { productLineId, startDate, endDate },
    } = this

    const productLine = getProductLine(productLines, productLineId)
    const campaignTemplate = getCampaignTemplate(productLine, campaignTemplateId)
    const salesProgram = getSalesProgram(campaignTemplate)

    const salesProgramEndDate = salesProgram.endDate

    const boundedEndDate = moment(endDate).isAfter(salesProgramEndDate) ? salesProgramEndDate : endDate
    const boundedStartDate = moment(startDate).isAfter(salesProgramEndDate) ? salesProgramEndDate : startDate

    this.setState({ campaignTemplateId, salesProgramEndDate, endDate: boundedEndDate, startDate: boundedStartDate })
  }

  toggleCreativeTemplateId = creativeTemplateId => {
    var { creativeTemplateIds } = this.state

    if (contains(creativeTemplateId, creativeTemplateIds)) {
      creativeTemplateIds = without([creativeTemplateId], creativeTemplateIds)
    } else {
      creativeTemplateIds.push(creativeTemplateId)
    }

    this.setState({ creativeTemplateIds })
  }

  createCampaign = () => {
    const {
      props: {
        data: {
          brand: { productLines },
        },
      },
      state: { productLineId, campaignTemplateId },
    } = this

    const productLine = getProductLine(productLines, productLineId)
    const campaignTemplate = getCampaignTemplate(productLine, campaignTemplateId)
    const salesProgram = getSalesProgram(campaignTemplate)

    const {
      creativeTemplateIds,
      startDate = salesProgram.startDate,
      endDate = salesProgram.endDate,
      audience = campaignTemplate.defaultAudience,
      budgetInCents,
    } = this.state

    const salesProgramId = salesProgram.id

    const campaign = {
      campaignTemplateId,
      creativeTemplateIds,
      name: campaignTemplate.name,
      channel: campaignTemplate.channel,
      endDate,
      startDate,
      budgetInCents: Math.round(budgetInCents && budgetInCents * 100),
      channelQuestions: [
        {
          name: 'radius',
          quantityValue: 20,
          unitValue: 'km',
        },
        {
          name: 'audience',
          value: audience,
        },
      ],
    }

    this.setState({ campaignDetailsSet: true })

    this.props.mutate({
      variables: {
        salesProgramId,
        campaign,
      },
    })
  }

  getStepContent(step) {
    if (step === -1 || step >= this.steps.length - 1) {
      return <Loading title={this.props.isSinglePartyDealer ? 'Creating Digital Template' : 'Creating Campaign'} />
    } else {
      return this.steps[step].content(this.props, this.state)
    }
  }

  getCurrentStep() {
    const step = findIndex(({ runStepComplete }) => !runStepComplete(this.props, this.state))(this.steps)
    return step === -1 ? this.steps.length : step
  }

  getCurrentTitle(step) {
    return this.steps[step].title(this.props, this.state)
  }

  getStepAction(step) {
    return this.steps[step].action && this.steps[step].action(this.props, this.state)
  }

  revertToStep = index => {
    let nextState = this.state

    for (let step of this.steps.slice(index, this.getCurrentStep() + 1).reverse()) {
      nextState = { ...nextState, ...step.undoStep() }
    }

    this.setState(nextState)
  }

  render() {
    const { classes } = this.props

    const currentStep = this.getCurrentStep()

    const stepperTitles = this.steps.map(({ stepperTitle }) => stepperTitle(this.props, this.state))

    return (
      <ScrollWrapper>
        <StyledDialogTitle disableTypography={true}>
          <RunCampaignStepper
            enableRevert={true}
            revertToStep={this.revertToStep}
            activeStep={currentStep}
            stepperTitles={stepperTitles}
          />
        </StyledDialogTitle>
        <div className={contentContainerStyle}>
          <div className={classes.currentTitleWrapper}>{this.getCurrentTitle(currentStep)}</div>
          <DialogContent className={classes.dialogContent}>{this.getStepContent(currentStep)}</DialogContent>
        </div>
      </ScrollWrapper>
    )
  }
}

const contentContainerStyle = css`
  width: 1080px;
  max-width: 98%;
  margin: auto;
  padding-bottom: 36px;
`

const StyledDialogTitle = withStyles({
  root: {
    maxWidth: 800,
    margin: 'auto',
    padding: '10px inherit inherit inherit',
    [DESKTOP_BREAKPOINT]: {
      padding: '8px',
    },
    [TABLET_BREAKPOINT]: {
      padding: '4px',
    },
    [PHONE_BREAKPOINT]: {},
  },
})(DialogTitle)

RunCampaignContainer.propTypes = {
  brandId: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  channel: PropTypes.string,
  productLineId: PropTypes.string,
  salesProgramId: PropTypes.string,
  campaignTemplateId: PropTypes.string,
  creativeTemplateIds: PropTypes.arrayOf(PropTypes.string),
  history: PropTypes.object.isRequired,
  onMutationError: PropTypes.func,
}

export default compose(
  withRouter,
  withBrandId,
  withStyles(styles),
  graphql(RunCampaignQueryDocument, {
    options: ({ brandId }) => {
      return {
        variables: { brandId, filterExpired: true },
      }
    },
  }),
  graphql(RunCampaignMutationDocument, {
    options: ({ brandId, history, onMutationError }) => ({
      refetchQueries: [{ query: RunCampaignQueryDocument, variables: { brandId, filterExpired: true } }],
      awaitRefetchQueries: true,
      onCompleted: ({ createAd: { id } }) => {
        history.push(generatePath(AD_PATH, { id, brandId }))
      },
      onError: onMutationError,
    }),
  }),
  graphQLData,
  IsSinglePartyDealer
)(RunCampaignContainer)
