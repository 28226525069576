// Check out this breakdown of TEB. https://covault-documentation.s3-us-west-2.amazonaws.com/transition-entity-button.png

import TransitionEntityButtonFactory from './TransitionEntityButton'
import PropTypes from 'prop-types'
import { anyPass, pathEq, pick, propEq } from 'ramda'
import { generatePath } from 'react-router'
import { ADS_PATH, AD_PATH, BRANDS_PATH, DEALERS_PATH, SALES_PROGRAMS_PATH } from 'constants/paths'

import {
  ApproveAdMutationDocument,
  PublishAdMutationDocument,
  PublishAdWithoutApprovalMutationDocument,
  AdQueryDocument,
  AdsQueryDocument,
  RejectAdMutationDocument,
  AdsQueryWithStatusForCount,
  AdsQueryWithStatusForCountByBrand,
  SubmitAdMutationDocument,
  CampaignTemplateQueryDocument,
  CreateAdMutationDocument,
  DeleteBrandMutationDocument,
  DeleteCampaignTemplateMutationDocument,
  DeleteCreativeMutationDocument,
  DeleteCreativeTemplateMutationDocument,
  DeleteDealerMutationDocument,
  DeleteProductLine as DeleteProductLineMutation,
  DeleteSalesProgramMutationDocument,
  ArchiveCampaignTemplateMutationDocument,
  ArchiveSalesProgramMutationDocument,
  DeleteAdMutationDocument,
  BrandsQueryDocument,
  BrandQueryDocument,
  SalesProgramsQueryDocument,
  SalesProgramQueryDocument,
  PublishCampaignTemplateMutationDocument,
  PublishSalesProgramMutationDocument,
  DeleteStripeCardMutationDocument,
  StripeCardsQueryDocument,
  SetDefaultStripeCardMutationDocument,
  UnlinkDealerFromBrandMutationDocument,
  DealerQueryDocument,
} from '../../graphql'

const DeleteBrand = TransitionEntityButtonFactory({
  buttonType: 'button',
  buttonText: 'Delete',
  confirmationText: 'Are you sure you want to delete this brand?',
  displayName: 'DeleteBrand',
  entityProp: 'brand',
  mutation: DeleteBrandMutationDocument,
  requiredPermission: 'brand:manage',
  redirectTo: BRANDS_PATH,
  requiredProps: {
    brand: PropTypes.object.isRequired,
  },
  refetchQueries: [{ query: BrandsQueryDocument }],
  snackbarOnComplete: {
    successTitle: 'Brand Deleted',
    successVariant: 'info',
  },
})

const DeleteProductLine = TransitionEntityButtonFactory({
  buttonType: 'button',
  buttonText: 'Delete',
  confirmationText: 'Are you sure you want to delete this product line?',
  displayName: 'DeleteProductLine',
  entityProp: 'productLine',
  mutation: DeleteProductLineMutation,
  requiredPermission: 'brand:manage',
  requiredProps: {
    brandId: PropTypes.string.isRequired,
    productLine: PropTypes.object.isRequired,
  },
  refetchQueries: ({ props }) => [{ query: BrandQueryDocument, variables: { id: props.brandId } }],
  snackbarOnComplete: {
    successTitle: 'Product Line Deleted',
    successVariant: 'info',
  },
})

const DeleteDealer = TransitionEntityButtonFactory({
  buttonType: 'button',
  buttonText: 'Delete',
  confirmationText: 'Are you sure you want to delete this dealer?',
  displayName: 'DeleteDealer',
  entityProp: 'dealer',
  mutation: DeleteDealerMutationDocument,
  requiredPermission: 'dealer:manage',
  redirectTo: DEALERS_PATH,
  requiredProps: {
    dealer: PropTypes.object.isRequired,
  },
  refetchQueries: [{ query: BrandsQueryDocument }],
  snackbarOnComplete: {
    successTitle: 'Dealer Deleted',
    successVariant: 'info',
  },
})

const DeleteCreativeProperties = {
  buttonType: 'icon',
  buttonText: 'Delete Creative',
  confirmationText: 'Are you sure you want to delete this creative?',
  displayName: 'DeleteCreative',
  entityProp: 'creative',
  propTest: anyPass([pathEq(['ad', 'status'], 'UNSUBMITTED'), pathEq(['ad', 'status'], 'REJECTED')]),
  mutation: DeleteCreativeMutationDocument,
  requiredParent: 'dealer',
  requiredPermission: 'ad:manage',
  requiredProps: {
    ad: PropTypes.object.isRequired,
    creative: PropTypes.object.isRequired,
  },
  refetchQueries: ({ props }) => [{ query: AdQueryDocument, variables: { id: props.ad.id } }],
  snackbarOnComplete: {
    successTitle: 'Creative Deleted',
    successVariant: 'info',
  },
}

const DeleteCreative = TransitionEntityButtonFactory(DeleteCreativeProperties)

const DeleteCreativeButton = TransitionEntityButtonFactory({
  ...DeleteCreativeProperties,
  buttonType: 'button',
})

const DeleteCreativeTemplateProperties = {
  buttonType: 'icon',
  buttonText: 'Delete Creative Template',
  confirmationText: 'Are you sure you want to delete this creative template?',
  displayName: 'DeleteCreativeTemplate',
  entityProp: 'creativeTemplate',
  mutation: DeleteCreativeTemplateMutationDocument,
  requiredParent: ['covault', 'brand'],
  requiredPermission: 'sales_program:manage',
  requiredProps: {
    campaignTemplate: PropTypes.object.isRequired,
    creativeTemplate: PropTypes.object.isRequired,
  },
  refetchQueries: ({ props }) => [
    {
      query: CampaignTemplateQueryDocument,
      variables: { id: props.campaignTemplate.id },
    },
  ],
  snackbarOnComplete: {
    succeslsTitle: 'Creative Template Deleted',
    successVariant: 'info',
  },
}

const DeleteCreativeTemplate = TransitionEntityButtonFactory(DeleteCreativeTemplateProperties)

const DeleteCreativeTemplateButton = TransitionEntityButtonFactory({
  ...DeleteCreativeTemplateProperties,
  buttonType: 'button',
})

const ArchiveSalesProgram = TransitionEntityButtonFactory({
  buttonType: 'button',
  buttonText: 'Archive',
  confirmationText: 'Are you sure you want to archive this sales program?',
  entityProp: 'salesProgram',
  entityTest: propEq('status', 'PUBLISHED'),
  mutation: ArchiveSalesProgramMutationDocument,
  requiredParent: ['brand', 'covault'],
  requiredPermission: 'sales_program:manage',
  requiredProps: {
    salesProgram: PropTypes.object.isRequired,
    brandId: PropTypes.string,
  },
  redirectTo: SALES_PROGRAMS_PATH,
  refetchQueries: ({ props: { brandId, salesProgram } }) => [
    {
      query: SalesProgramQueryDocument,
      variables: { brandId, salesProgramId: salesProgram.id },
    },
    { query: SalesProgramsQueryDocument, variables: { brandId } },
  ],
  snackbarOnComplete: {
    successTitle: 'Sales Program Archived',
    successMessage:
      'The Sales Program has been archived. No new Campaigns can be created for this Sales Program but Campaigns that have already been created will continue to run.',
    successVariant: 'info',
  },
})

const DeleteSalesProgram = TransitionEntityButtonFactory({
  buttonType: 'button',
  buttonText: 'Delete',
  confirmationText: 'Are you sure you want to delete this sales program?',
  displayName: 'DeleteSalesProgram',
  entityProp: 'salesProgram',
  entityTest: propEq('status', 'DRAFT'),
  mutation: DeleteSalesProgramMutationDocument,
  redirectTo: SALES_PROGRAMS_PATH,
  requiredParent: ['brand', 'covault'],
  requiredPermission: 'sales_program:manage',
  requiredProps: {
    brandId: PropTypes.string.isRequired,
    salesProgram: PropTypes.object.isRequired,
  },
  refetchQueries: ({ props }) => [{ query: SalesProgramsQueryDocument, variables: { brandId: props.brandId } }],
  snackbarOnComplete: {
    successTitle: 'Sales Program Deleted',
    successVariant: 'info',
  },
})

const PublishSalesProgram = TransitionEntityButtonFactory({
  buttonType: 'button',
  buttonText: 'Publish',
  confirmationText: 'Are you sure you are ready to Publish your Sales Program?',
  secondaryText: 'Once you do, all Dealers with access to your Brand will see this Sales Program.',
  entityProp: 'salesProgram',
  entityTest: propEq('status', 'DRAFT'),
  mutation: PublishSalesProgramMutationDocument,
  requiredPermission: 'sales_program:manage',
  requiredParent: ['brand', 'covault'],
  requiredProps: {
    salesProgram: PropTypes.object.isRequired,
    brandId: PropTypes.string,
  },
  refetchQueries: ({ props: { brandId, salesProgram } }) => [
    {
      query: SalesProgramQueryDocument,
      variables: { brandId, salesProgramId: salesProgram.id },
    },
    { query: SalesProgramsQueryDocument, variables: { brandId } },
  ],
  snackbarOnComplete: {
    successTitle: 'Sales Program Published',
    successMessage:
      'Congratulations! Dealers can now see this Sales Program and associated assets. Dealers can now create Campaigns for this Sales Program, which will then be sent to you for approval.',
  },
})

const ApproveAd = TransitionEntityButtonFactory({
  buttonType: 'button',
  buttonText: 'Approve',
  confirmationText: 'Are you ready to Approve this Campaign?',
  secondaryText: "Once you do, the dealer will see that you've approved it.",
  mutation: ApproveAdMutationDocument,
  entityProp: 'ad',
  entityTest: propEq('status', 'SUBMITTED'),
  requiredPermission: 'ad:approve',
  requiredParent: 'brand',
  requiredProps: {
    ad: PropTypes.object.isRequired,
    brandId: PropTypes.string,
  },
  refetchQueries: ({
    props: {
      brandId,
      entity: { id },
    },
  }) => [
    { query: AdsQueryDocument, variables: { brandId } },
    { query: AdsQueryWithStatusForCount, variables: { adStatus: 'SUBMITTED' } },
    { query: AdQueryDocument, variables: { id } },
  ],
  snackbarOnComplete: {
    successTitle: 'Campaign Approved',
    successMessage:
      'Congratulations! The Dealer will be notified that the Campaign has been approved. The Dealer can now publish the Campaign.',
  },
})

const DeleteAd = TransitionEntityButtonFactory({
  buttonType: 'button',
  buttonText: 'Delete',
  confirmationText: 'Are you sure you want to delete this Campaign?',
  displayName: 'DeleteAd',
  entityProp: 'ad',
  entityTest: anyPass([propEq('status', 'UNSUBMITTED'), propEq('status', 'REJECTED')]),
  mutation: DeleteAdMutationDocument,
  redirectTo: ADS_PATH,
  requiredParent: 'dealer',
  requiredPermission: 'ad:manage',
  requiredProps: {
    ad: PropTypes.object.isRequired,
    brandId: PropTypes.string,
  },
  refetchQueries: ({ props: { brandId } }) => [{ query: AdsQueryDocument, variables: { brandId } }],
  snackbarOnComplete: {
    successTitle: 'Campaign Deleted',
    successVariant: 'info',
  },
})

const PublishAd = TransitionEntityButtonFactory({
  buttonType: 'button',
  buttonText: 'Publish',
  confirmationText: 'Are you ready to Publish this Campaign?',
  displayName: 'PublishAd',
  mutation: PublishAdMutationDocument,
  entityProp: 'ad',
  entityTest: propEq('status', 'APPROVED'),
  requiredPermission: 'ad:manage',
  requiredParent: 'dealer',
  requiredProps: {
    ad: PropTypes.object.isRequired,
    brandId: PropTypes.string,
  },
  refetchQueries: ({
    props: {
      brandId,
      entity: { id },
    },
  }) => [
    { query: AdsQueryDocument, variables: { brandId } },
    { query: AdsQueryWithStatusForCount, variables: { adStatus: 'APPROVED' } },
    { query: AdQueryDocument, variables: { id } },
  ],
  snackbarOnComplete: {
    successTitle: 'Campaign Published',
    successMessage: 'Congratulations! Your Campaign has been successfully sent to the media network to be published.',
  },
})

const PublishAdWithoutApproval = TransitionEntityButtonFactory({
  buttonType: 'button',
  buttonText: 'Publish',
  confirmationText: 'Are you ready to Publish this Campaign?',
  displayName: 'PublishAd',
  mutation: PublishAdWithoutApprovalMutationDocument,
  entityProp: 'ad',
  entityTest: propEq('status', 'UNSUBMITTED'),
  requiredPermission: 'ad:manage',
  requiredParent: 'dealer',
  requiredProps: {
    ad: PropTypes.object.isRequired,
    brandId: PropTypes.string,
  },
  refetchQueries: ({
    props: {
      brandId,
      entity: { id },
    },
  }) => [
    { query: AdsQueryDocument, variables: { brandId } },
    { query: AdsQueryWithStatusForCount, variables: { adStatus: 'APPROVED' } },
    { query: AdQueryDocument, variables: { id } },
  ],
  snackbarOnComplete: {
    successTitle: 'Campaign Published',
    successMessage: 'Congratulations! Your Campaign has been successfully sent to the media network to be published.',
  },
})

const RejectAd = TransitionEntityButtonFactory({
  buttonType: 'button',
  buttonText: 'Reject Campaign',
  shortButtonText: 'Reject',
  confirmationText: 'Are you sure you want to Reject this Campaign?',
  secondaryText: 'Once rejected, the dealer will been notified, and they will be able to see your rejection notes.',
  notesPrompt: 'Rejection notes',
  displayName: 'RejectAd',
  mutation: RejectAdMutationDocument,
  entityProp: 'ad',
  entityTest: propEq('status', 'SUBMITTED'),
  requiredPermission: 'ad:approve',
  requiredParent: 'brand',
  requiredProps: {
    ad: PropTypes.object.isRequired,
    brandId: PropTypes.string,
  },
  refetchQueries: ({
    props: {
      brandId,
      entity: { id },
    },
  }) => [
    { query: AdsQueryDocument, variables: { brandId } },
    { query: AdsQueryWithStatusForCount, variables: { adStatus: 'SUBMITTED' } },
    { query: AdQueryDocument, variables: { id } },
  ],
  snackbarOnComplete: {
    successTitle: 'Campaign Rejected',
    successMessage:
      'The Dealer will be notified that the Campaign has been rejected and they will be able to see the rejection notes.',
    successVariant: 'info',
  },
})

const ResubmitAdForApproval = TransitionEntityButtonFactory({
  buttonType: 'button',
  buttonText: 'Resubmit for Approval',
  shortButtonText: 'Resubmit',
  confirmationText: 'Are you ready to resubmit this Campaign for Approval?',
  displayName: 'ResubmitAdForApproval',
  mutation: SubmitAdMutationDocument,
  entityProp: 'ad',
  entityTest: ad => ad && ad.creatives && ad.status === 'REJECTED' && ad.creatives.length !== 0 && ad.budgetInCents > 0,
  requiredPermission: 'ad:manage',
  requiredParent: 'dealer',
  requiredProps: {
    ad: PropTypes.object.isRequired,
    brandId: PropTypes.string,
  },
  refetchQueries: ({
    props: {
      brandId,
      entity: { id },
    },
  }) => [
    { query: AdsQueryDocument, variables: { brandId } },
    { query: AdQueryDocument, variables: { id } },
    { query: AdsQueryWithStatusForCount, variables: { adStatus: ['APPROVED', 'REJECTED'] } },
    { query: AdsQueryWithStatusForCountByBrand, variables: { adStatus: ['APPROVED', 'REJECTED'], id: brandId } },
  ],
  snackbarOnComplete: {
    successTitle: 'Campaign Submitted for Approval',
    successMessage:
      'Congratulations! The Campaign has successfully been submitted for approval. Once the Campaign has been reviewed and approved by Brand, you will receive an email notification and you will need to log back in to publish the Campaign.',
  },
})

const SubmitAdForApproval = TransitionEntityButtonFactory({
  buttonType: 'button',
  buttonText: 'Submit for Approval',
  shortButtonText: 'Submit',
  confirmationText: 'Are you ready to submit this Campaign and the Creatives for Approval?',
  displayName: 'SubmitAdForApproval',
  mutation: SubmitAdMutationDocument,
  entityProp: 'ad',
  entityTest: ad => {
    if (ad.channel === 'REQUEST') {
      return ad && ad.status === 'UNSUBMITTED' && ad.budgetInCents > 0
    } else {
      return ad && ad.creatives && ad.status === 'UNSUBMITTED' && ad.creatives.length !== 0 && ad.budgetInCents > 0
    }
  },
  requiredPermission: 'ad:manage',
  requiredParent: 'dealer',
  requiredProps: {
    ad: PropTypes.object.isRequired,
    brandId: PropTypes.string,
  },
  refetchQueries: ({
    props: {
      brandId,
      entity: { id },
    },
  }) => [{ query: AdsQueryDocument, variables: { brandId } }, { query: AdQueryDocument, variables: { id } }],
  snackbarOnComplete: {
    successTitle: 'Campaign Submitted for Approval',
    successMessage:
      'Congratulations! The Campaign has successfully been submitted for approval. Once the Campaign has been reviewed and approved by Brand, you will receive an email notification and you will need to log back in to publish the Campaign.',
  },
})

const DeleteCampaignTemplate = TransitionEntityButtonFactory({
  buttonType: 'button',
  buttonText: 'Delete',
  confirmationText: 'Are you sure you want to delete the Campaign Template?',
  displayName: 'DeleteCampaignTemplate',
  mutation: DeleteCampaignTemplateMutationDocument,
  entityProp: 'campaignTemplate',
  entityTest: propEq('status', 'DRAFT'),
  requiredPermission: 'sales_program:manage',
  requiredParent: ['brand', 'covault'],
  redirectTo: SALES_PROGRAMS_PATH,
  requiredProps: {
    campaignTemplate: PropTypes.object.isRequired,
    salesProgram: PropTypes.object.isRequired,
    brandId: PropTypes.string,
  },
  refetchQueries: ({
    props: {
      brandId,
      salesProgram: { id: salesProgramId },
      entity: { id },
    },
  }) => [{ query: SalesProgramsQueryDocument, variables: { brandId } }],
  snackbarOnComplete: {
    successTitle: 'Campaign Template Deleted',
    successVariant: 'info',
  },
})

const ArchiveCampaignTemplate = TransitionEntityButtonFactory({
  buttonType: 'button',
  buttonText: 'Archive',
  confirmationText: 'Are you sure you want to archive the Campaign Template?',
  displayName: 'ArchiveCampaignTemplate',
  mutation: ArchiveCampaignTemplateMutationDocument,
  entityProp: 'campaignTemplate',
  entityTest: propEq('status', 'PUBLISHED'),
  requiredPermission: 'sales_program:manage',
  requiredParent: ['brand', 'covault'],
  requiredProps: {
    campaignTemplate: PropTypes.object.isRequired,
    salesProgram: PropTypes.object.isRequired,
    brandId: PropTypes.string,
  },
  refetchQueries: ({
    props: {
      brandId,
      salesProgram: { id: salesProgramId },
      entity: { id },
    },
  }) => [
    { query: SalesProgramsQueryDocument, variables: { brandId } },
    {
      query: CampaignTemplateQueryDocument,
      variables: { salesProgramId, brandId, id },
    },
  ],
  snackbarOnComplete: {
    successTitle: 'Campaign Template Archived',
    successVariant: 'info',
  },
})

const PublishCampaignTemplate = TransitionEntityButtonFactory({
  buttonType: 'button',
  buttonText: 'Publish',
  displayName: 'PublishCampaignTemplate',
  confirmationText: 'Are you sure you want to publish the Campaign Template?',
  mutation: PublishCampaignTemplateMutationDocument,
  entityProp: 'campaignTemplate',
  entityTest: propEq('status', 'DRAFT'),
  requiredPermission: 'sales_program:manage',
  requiredParent: ['brand', 'covault'],
  requiredProps: {
    campaignTemplate: PropTypes.object.isRequired,
    salesProgram: PropTypes.object.isRequired,
    brandId: PropTypes.string,
  },
  refetchQueries: ({
    props: {
      brandId,
      salesProgram: { id: salesProgramId },
      entity: { id },
    },
  }) => [
    { query: SalesProgramsQueryDocument, variables: { brandId } },
    {
      query: CampaignTemplateQueryDocument,
      variables: { salesProgramId, brandId, id },
    },
  ],
  snackbarOnComplete: {
    successTitle: 'Campaign Template Published',
    successVariant: 'info',
  },
})

const DeleteStripeCard = TransitionEntityButtonFactory({
  buttonType: 'icon',
  buttonText: 'Delete',
  buttonProps: { size: 'small' },
  confirmationText: 'Are you sure you want to delete this credit card?',
  displayName: 'DeleteStripeCard',
  entityProp: 'card',
  mutation: DeleteStripeCardMutationDocument,
  mapPropsToAdditionalMutationVariables: pick(['dealerId']),
  requiredPermission: 'card:manage',
  requiredParent: 'dealer',
  requiredProps: {
    card: PropTypes.object,
    dealerId: PropTypes.number,
  },
  refetchQueries: ({ props: { dealerId } }) => [{ query: StripeCardsQueryDocument, variables: { dealerId } }],
  snackbarOnComplete: {
    successTitle: 'Card Deleted',
    successVariant: 'info',
  },
})

const SetDefaultStripeCard = TransitionEntityButtonFactory({
  buttonType: 'button',
  buttonText: 'Set as Default',
  buttonProps: { size: 'small', variant: 'text' },
  confirmationText: 'Are you sure you want to set this credit card as default?',
  displayName: 'SetDefaultStripeCard',
  entityProp: 'card',
  mutation: SetDefaultStripeCardMutationDocument,
  mapPropsToAdditionalMutationVariables: pick(['dealerId']),
  requiredPermission: 'card:manage',
  requiredParent: 'dealer',
  requiredProps: {
    card: PropTypes.object,
    dealerId: PropTypes.number,
  },
  refetchQueries: ({ props: { dealerId } }) => [{ query: StripeCardsQueryDocument, variables: { dealerId } }],
  snackbarOnComplete: {
    successTitle: 'Card has been set as default.',
    successVariant: 'info',
  },
})

const UnlinkFromBrand = TransitionEntityButtonFactory({
  buttonType: 'button',
  buttonText: 'Unlink',
  confirmationText: 'Are you sure you want to unlink from this brand?',
  buttonProps: { size: 'small', variant: 'text' },
  displayName: 'UnlinkFromBrand',
  entityProp: 'dealer',
  mutation: UnlinkDealerFromBrandMutationDocument,
  mapPropsToAdditionalMutationVariables: props => ({ dealerId: props.dealer.id, id: props.dealer.attachedBrandId }),
  requiredPermission: 'dealer:manage',
  requiredParent: 'covault',
  requiredProps: {
    attachedBrandId: PropTypes.string,
    dealer: PropTypes.object,
  },
  refetchQueries: ({
    props: {
      dealer: { id, attachedBrandId },
    },
  }) => [
    { query: BrandQueryDocument, variables: { id: attachedBrandId } },
    { query: DealerQueryDocument, variables: { id } },
  ],
  snackbarOnComplete: {
    successTitle: 'Unlinked dealer from brand.',
    successVariant: 'info',
  },
})

// TODO we're getting into dangerous territory with the TransitionEntityButton component here.
// I think this whole component could use a _refactor_
const CreateRequest = TransitionEntityButtonFactory({
  buttonType: 'button',
  buttonText: 'New Request',
  buttonProps: { variant: 'contained' },
  displayName: 'CreateRequest',
  mutation: CreateAdMutationDocument,
  mapPropsToAdditionalMutationVariables: ({ salesProgramId, campaignDefaultData }) => ({
    salesProgramId,
    ad: campaignDefaultData,
  }),
  requiredPermission: 'ad:manage',
  requiredParent: 'dealer',
  redirectTo: ({ createAd: { id } }, brandId) => generatePath(AD_PATH, { brandId, id }),
  requiredProps: {
    salesProgramId: PropTypes.string,
    campaignDefaultData: PropTypes.object,
  },
  refetchQueries: ({
    props: {
      brandId,
    },
  }) => [
    { query: AdsQueryDocument, variables: { brandId } },
  ],
  snackbarOnComplete: {
    successTitle: 'Created a new Co-op Request form.',
    successVariant: 'info',
  },
})

export {
  ArchiveCampaignTemplate,
  DeleteBrand,
  DeleteCampaignTemplate,
  DeleteProductLine,
  DeleteDealer,
  DeleteCreative,
  DeleteCreativeButton,
  DeleteCreativeTemplate,
  DeleteCreativeTemplateButton,
  DeleteStripeCard,
  ArchiveSalesProgram,
  DeleteSalesProgram,
  PublishSalesProgram,
  ApproveAd,
  DeleteAd,
  PublishAd,
  PublishAdWithoutApproval,
  PublishCampaignTemplate,
  RejectAd,
  ResubmitAdForApproval,
  SetDefaultStripeCard,
  SubmitAdForApproval,
  UnlinkFromBrand,
  CreateRequest,
}
