/* CovaultFilePicker.jsx
 * Provides a file picker. When a file has been chosen, send up the file using setValue.
 *
 * Created by Louis Ritchie on July 2nd, 2018
 * louiscritchie@gmail.com
 */

import * as React from 'react'
import PropTypes from 'prop-types'
import { css } from 'emotion'
import { Input, InputLabel } from '@material-ui/core'

const containerStyle = css`
  margin-top: 20px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: 55px;
`

export class CovaultFilePicker extends React.Component {
  static propTypes = {
    accept: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    setValue: PropTypes.func.isRequired,
    testid: PropTypes.string,
  }

  constructor(props) {
    super()

    // we need to have a unique id if there are multiple file pickers on the page
    // this seems like something that could be solved with refs, but they aren't exactly needed.
    this.id = `covault-file-picker-file${Math.floor(Math.random() * 10000000)}`
  }

  componentDidMount = () => this.props.required && this.props.setValue(false)

  handleChange = () => {
    const file = document.getElementById(this.id).files[0]

    if (file) {
      this.props.setValue(true, file)
    } else {
      this.props.setValue(false)
    }
  }

  render() {
    const { label, accept, testid } = this.props

    return (
      <div data-testid={testid} className={containerStyle}>
        <InputLabel htmlFor={this.id}>{label}</InputLabel>
        <Input
          disableUnderline
          id={this.id}
          inputProps={{ accept }}
          name="file"
          onChange={this.handleChange}
          type="file"
        />
      </div>
    )
  }
}

export default CovaultFilePicker
