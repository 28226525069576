import * as React from 'react'
import { Trans } from 'react-i18next'
import { css } from 'emotion'
import { withApollo } from 'react-apollo'
import { Subject } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import CTAButtonWrapper from 'components/CTAButtonWrapper'
import SecondaryNavBar from 'components/SecondaryNavBar'
import ScrollWrapper from 'components/ScrollWrapper'
import Title from 'components/Title'
import PropTypes from 'prop-types'
import UploadFileButton from 'components/UploadFileButton'
import { Icon, TextField, Typography, withStyles } from '@material-ui/core'
import { DeleteAd } from 'components/TransitionEntityButton'
import CurrencyFormat from 'components/CurrencyFormat'
import CovaultDatePicker from 'components/CovaultDatePicker'
import ChatBox from 'components/requestForm/ChatBox'
import CreativeCard from 'components/CreativeCard'
import { AdQueryDocument, UpdateAdMutationDocument } from '../../graphql'
import moment from 'moment'

const buttonWrapperStyle = css`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  align-self: flex-end;
  width: 100px;
  vertical-align: middle;
`
const ApprovedTag = withStyles({ root: { color: 'green' } })(props => (
  <div className={buttonWrapperStyle}>
    <Icon {...props}>check</Icon>
    Approved
  </div>
))
const RejectedTag = withStyles({ root: { color: 'red' } })(props => (
  <div className={buttonWrapperStyle}>
    <Icon {...props}>close</Icon>
    Rejected
  </div>
))

export class RequestContainer extends React.Component {
  static propTypes = {
    brandId: PropTypes.string,
    ad: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
  }

  constructor(props) {
    super()

    this.state = {
      budget: props.ad.budgetInCents,
    }

    this._budgetSubject$ = new Subject()
    this._budgetSubject$.subscribe(event => this.setState({ budget: event.target.value }))

    this._budgetSubjectDebounced$ = this._budgetSubject$.pipe(debounceTime(2000))
    this._budgetSubjectDebounced$.subscribe(this._submitBudget)
  }

  _submitBudget = ({ target: { value: budgetInCents } }) =>
    this.props.client.mutate({
      mutation: UpdateAdMutationDocument,
      variables: { id: this.props.ad.id, ad: { budgetInCents: Number(budgetInCents) } },
      refetchQueries: [{ query: AdQueryDocument, variables: { id: this.props.ad.id } }],
    })

  _submitEndDate = endDate =>
    this.props.client.mutate({
      mutation: UpdateAdMutationDocument,
      variables: { id: this.props.ad.id, ad: { endDate } },
    })

  _budgetChange = event => this._budgetSubject$.next(event)

  render() {
    const {
      ad,
      ad: { id, creatives, status },
      auth,
      brandId,
    } = this.props
    const { budget } = this.state

    const isDealer = auth.parentType === 'dealer'
    const unsubmitted = status === 'UNSUBMITTED'
    const approved = status === 'APPROVED'
    const rejected = status === 'REJECTED'

    return (
      <React.Fragment>
        <SecondaryNavBar>
          <Title>
            Co-op Request <span className={requestNumberStyle}>#{id}</span>
          </Title>
          <CTAButtonWrapper>
            {approved && <ApprovedTag />}
            {rejected && <RejectedTag />}
            <DeleteAd ad={ad} />
            {ad.status === 'SUBMITTED' && isDealer && (
              <Typography variant="button">
                <Trans>Submitted for Approval</Trans>
              </Typography>
            )}
            {unsubmitted && <UploadFileButton adId={id} />}
          </CTAButtonWrapper>
        </SecondaryNavBar>

        <ScrollWrapper>
          <div className={requestContainerWrapper}>
            <div className={chatBoxWrapperStyle}>
              <DetailsHeadingTypography variant="subtitle1">Co-op Request Details</DetailsHeadingTypography>
              <div className={budgetAndDateFieldsWrapperStyle}>
                <TextField
                  value={budget}
                  InputProps={{ inputComponent: CurrencyFormat, readOnly: !unsubmitted }}
                  label="Estimated Budget"
                  onChange={this._budgetChange}
                  isNumericString={true}
                />
                {unsubmitted ? (
                  <CovaultDatePicker
                    autoOk
                    defaultValue={ad.endDate}
                    emptyLabel="YYYY-MM-DD"
                    label="Date"
                    setValue={this._submitEndDate}
                    disableFuture={!unsubmitted}
                    disablePast={!unsubmitted}
                  />
                ) : (
                  <TextField
                    defaultValue={moment(Date(ad.endDate)).format('MMMM Do, YYYY')}
                    InputProps={{ readOnly: true }}
                    label="Date"
                  />
                )}
              </div>
              <ChatBox ad={ad} brandId={brandId} />
            </div>
            <div className={requestFilePreviewContainer}>
              <MediaHeadingTypography variant="subtitle1">Media Sources</MediaHeadingTypography>
              {creatives.length > 0 ? (
                creatives.map(c => <CreativeCard ad={ad} creative={c} key={c.id} />)
              ) : (
                <MediaHeadingTypography variant="caption">No media provided.</MediaHeadingTypography>
              )}
            </div>
          </div>
        </ScrollWrapper>
      </React.Fragment>
    )
  }
}

const DetailsHeadingTypography = withStyles({
  root: {
    margin: '0 0 16px 0',
  },
})(Typography)
const MediaHeadingTypography = withStyles({
  root: {
    margin: '0 0 16px 10px',
  },
})(Typography)
const budgetAndDateFieldsWrapperStyle = css`
  padding-bottom: 24px;
  display: flex;
  justify-content: space-between;
  width: 100%;

  > *:first-child {
    width: 49%;
  }

  > *:last-child {
    width: 50%;
  }
`
const chatBoxWrapperStyle = css`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
`
const requestFilePreviewContainer = css`
  margin-left: 12px;
  padding: 0 6px 18px 3px;
  border-left: 1px solid #999;
  overflow: hidden;
  width: 242px;
`
const requestContainerWrapper = css`
  margin: 24px 12px;
  display: flex;

  > *:first-child {
    flex-grow: 1;
  }
`
const requestNumberStyle = css`
  opacity: 0.5;
`

export default withApollo(RequestContainer)
