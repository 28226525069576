import * as React from 'react'
import covaultAuth from 'components/CovaultAuth'
import CovaultTextField from 'components/CovaultTextField'
import CreateEntityButton from 'components/CreateEntityButton'
import PropTypes from 'prop-types'
import withSnackbar from 'components/WithSnackbar'
import { compose, path } from 'ramda'
import { translate } from 'react-i18next'
import { CampaignTemplateQueryDocument, EditCampaignTemplateMutationDocument } from '../../graphql'

export class EditCampaignTemplate extends React.Component {
  static propTypes = {
    brandId: PropTypes.string.isRequired,
    salesProgram: PropTypes.object.isRequired,
    snackbarContext: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }

  state = {
    loading: false,
  }

  render() {
    const {
      props: {
        brandId,
        salesProgram: {
          id: salesProgramId,
          campaignTemplate,
          campaignTemplate: { id: campaignTemplateId },
        },
        snackbarContext,
        t,
      },
      state: { loading },
    } = this

    return (
      <CreateEntityButton
        mutation={EditCampaignTemplateMutationDocument}
        buttonText={t('Edit')}
        initialVariables={[['campaignTemplateId', campaignTemplateId]]}
        refetchQueries={() => [
          {
            query: CampaignTemplateQueryDocument,
            variables: { salesProgramId, brandId, id: campaignTemplateId },
          },
        ]}
        onCompleted={() => {
          snackbarContext.successMessage('Campaign Template Updated', '')
        }}
        loading={loading}
        submitHook={() => this.setState({ loading: true })}
      >
        {setValueFactory => (
          <React.Fragment>
            <CovaultTextField
              id="name"
              setValue={setValueFactory('campaignTemplate.name')}
              defaultValue={path(['name'], campaignTemplate)}
              label={t('Template Name')}
            />
            <CovaultTextField
              id="description"
              setValue={setValueFactory('campaignTemplate.description')}
              defaultValue={path(['description'], campaignTemplate)}
              label={t('Template Description')}
            />
            <CovaultTextField
              id="defaultAudience"
              setValue={setValueFactory('campaignTemplate.defaultAudience')}
              defaultValue={path(['defaultAudience'], campaignTemplate)}
              label={t('Default Audience')}
            />
            <CovaultTextField
              id="channel"
              setValue={setValueFactory('campaignTemplate.channel')}
              defaultValue={path(['channel'], campaignTemplate)}
              label={t('Template Channel')}
              disabled
            />
          </React.Fragment>
        )}
      </CreateEntityButton>
    )
  }
}

export default compose(
  withSnackbar,
  translate(),
  covaultAuth({
    requiredParent: ['covault'],
    requiredPermission: 'sales_program:manage',
  })
)(EditCampaignTemplate)
