// Check out this breakdown of TEB. https://covault-documentation.s3-us-west-2.amazonaws.com/transition-entity-button.png

import * as React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import { Mutation } from 'react-apollo'
import { mapProps } from 'react-map-props'
import { withRouter } from 'react-router-dom'
import { compose, type } from 'ramda'
import covaultAuth from 'components/CovaultAuth'
import CovaultTextField from 'components/CovaultTextField'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import withSnackbar from 'components/WithSnackbar'
import withBrandId from 'components/WithBrandId'
import { generatePath } from 'react-router'
import TextShrinker from 'components/TextShrinker'

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'

const ButtonFactory = ({ buttonType, buttonText, shortButtonText, displayName, props }) => props2 => {
  switch (buttonType) {
    case 'icon':
      return (
        // currently only going to support the delete icon.
        <IconButton data-testid={`button-${displayName}`} {...props}>
          <DeleteIcon />
        </IconButton>
      )
    case 'button':
      return (
        <Button
          color="primary"
          variant="contained"
          data-testid={`button-${displayName}`}
          {...props}
          {...(props2 && props2.optionalOnClick ? { onClick: props2.optionalOnClick } : {})}
        >
          <Trans>
            <TextShrinker fullText={buttonText || 'Delete'} shortText={shortButtonText} />
          </Trans>
        </Button>
      )
    default:
      break
  }
}

export default function TransitionEntityButtonFactory({
  buttonProps,
  buttonText,
  shortButtonText,
  buttonType,
  confirmationText,
  displayName,
  entityProp,
  entityTest,
  mutation,
  mapPropsToAdditionalMutationVariables,
  notesPrompt,
  propTest,
  redirectTo,
  refetchQueries,
  requiredParent,
  requiredPermission,
  requiredProps = {},
  secondaryText,
  snackbarOnComplete,
}) {
  const TransitionEntityButton = class extends React.Component {
    static propTypes = Object.assign(
      {
        entity: PropTypes.object,
        history: PropTypes.object.isRequired,
        snackbarContext: PropTypes.object.isRequired,
      },
      requiredProps
    )

    constructor(props) {
      super()

      this.ButtonComponent = ButtonFactory({
        buttonType,
        buttonText,
        shortButtonText,
        displayName,
        props: { ...buttonProps, onClick: this._toggleOpen },
      })

      this.state = {
        confirmationOpen: false,
        notes: void 0,
      }
    }

    _toggleOpen = () => this.setState({ confirmationOpen: !this.state.confirmationOpen })

    _handleNotesChange = value => this.setState({ notes: value })

    _onMutationComplete = result => {
      const { brandId, history, snackbarContext } = this.props

      const {
        successTitle: snackbarTitle = buttonText,
        successMessage: snackbarMessage = '',
        successVariant: snackbarVariant = 'success',
      } = snackbarOnComplete || {}

      if (redirectTo) {
        if (type(redirectTo) === 'Function') {
          history.push(redirectTo(result, brandId))
        } else {
          history.push(generatePath(redirectTo, { brandId }))
        }
      }

      snackbarContext.snackbarMessage(snackbarTitle, snackbarMessage, snackbarVariant)
    }

    _onMutationError = error => {
      // TODO: add a helper to construct error objects {title, message, [fields]}.
      console.error(error)
      this.props.snackbarContext.errorMessage('Action Failed', '')
    }

    getRefetchQueries = mutationResults => {
      if (!refetchQueries) {
        return []
      }

      if (type(refetchQueries) === 'Function') {
        return refetchQueries({
          props: this.props,
          mutationResults,
        })
      }

      return refetchQueries
    }

    render() {
      const {
        props: { entity },
        state: { confirmationOpen, notes },
      } = this

      if (type(propTest) === 'Function' && !propTest(this.props)) {
        return null
      }

      if (entity && type(entityTest) === 'Function' && !entityTest(entity)) {
        return null
      }

      return (
        <Mutation mutation={mutation} onCompleted={this._onMutationComplete} onError={this._onMutationError}>
          {(submit, { error }) => (
            <React.Fragment>
              {this.ButtonComponent(
                confirmationText
                  ? void 0
                  : {
                      optionalOnClick: () =>
                        submit({
                          variables: Object.assign(
                            entity ? { id: entity.id } : {},
                            notesPrompt ? { notes } : {},
                            mapPropsToAdditionalMutationVariables
                              ? mapPropsToAdditionalMutationVariables(this.props)
                              : {}
                          ),
                          refetchQueries: this.getRefetchQueries,
                        }),
                    }
              )}

              <Dialog open={confirmationOpen} onClose={this._toggleOpen}>
                <DialogTitle id="alert-dialog-title">
                  {confirmationText && <Trans>{confirmationText}</Trans>}
                </DialogTitle>

                {(notesPrompt || secondaryText) && (
                  <DialogContent>
                    {secondaryText && (
                      <DialogContentText id="alert-dialog-description">
                        <Trans>{secondaryText}</Trans>
                      </DialogContentText>
                    )}
                    {secondaryText && notesPrompt && <br />}
                    {notesPrompt && (
                      <CovaultTextField
                        id="notes-prompt"
                        label={notesPrompt}
                        setValue={this._handleNotesChange}
                        fullWidth
                      />
                    )}
                  </DialogContent>
                )}

                <DialogActions>
                  <Button data-testid="cancel" onClick={this._toggleOpen}>
                    <Trans>Cancel</Trans>
                  </Button>

                  <Button
                    color="primary"
                    data-testid="submit"
                    autoFocus
                    onClick={() => {
                      submit({
                        variables: Object.assign(
                          entity ? { id: entity.id } : {},
                          notesPrompt ? { notes } : {},
                          mapPropsToAdditionalMutationVariables ? mapPropsToAdditionalMutationVariables(this.props) : {}
                        ),
                        refetchQueries: this.getRefetchQueries,
                      })
                      this._toggleOpen()
                    }}
                    disabled={notesPrompt ? !notes : false}
                  >
                    <Trans>Yes, {buttonText}</Trans>
                  </Button>
                </DialogActions>
              </Dialog>
            </React.Fragment>
          )}
        </Mutation>
      )
    }
  }

  TransitionEntityButton.displayName = displayName

  return compose(
    withRouter,
    withSnackbar,
    covaultAuth({ requiredPermission, requiredParent }),
    withBrandId,
    mapProps(props => {
      if (entityProp) {
        return Object.assign({ entity: props[entityProp] }, props)
      }

      return props
    })
  )(TransitionEntityButton)
}
