import { find } from 'ramda'

export function getProductLine(productLines, productLineId) {
  const productLine = productLines && find(({ id }) => id === productLineId)(productLines)
  return productLine
}

export function getCampaignTemplate(productLine, campaignTemplateId) {
  const campaignTemplate =
    productLine &&
    productLine.campaignTemplates &&
    find(({ id }) => id === campaignTemplateId)(productLine.campaignTemplates)
  return campaignTemplate
}

export function getSalesProgram(campaignTemplate) {
  return campaignTemplate && campaignTemplate.salesProgram
}
