import * as React from 'react'
import DealerView from './DealerView'
import { Dealer } from '../../../types'

export default function DealerList({ dealers }: { dealers: Dealer[] }) {
  return (
    <div>
      {dealers.map((dealer: Dealer, i) => (
        <DealerView dealer={dealer} key={i} />
      ))}
    </div>
  )
}
