import * as React from 'react'
import { Table, TableBody, TableHead, TableRow } from '@material-ui/core'
import { StyledTableCell } from 'components/materialUI'
import { Trans } from 'react-i18next'
import PropTypes from 'prop-types'
import Link from 'components/Link'
import { STATUS_NAMES } from 'constants/ads'
import { CHANNEL_NAMES } from 'constants/channels'
import { CAMPAIGN_TEMPLATE_PATH } from 'constants/paths'

export function CampaignTemplatesTable({ salesProgramId, brandId, campaignTemplates }) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <StyledTableCell>
            <Trans>Campaign Template</Trans>
          </StyledTableCell>
          <StyledTableCell>
            <Trans>Status</Trans>
          </StyledTableCell>
          <StyledTableCell collapse>
            <Trans>Channel</Trans>
          </StyledTableCell>
          <StyledTableCell numeric>
            <Trans>Creative Templates</Trans>
          </StyledTableCell>
        </TableRow>
      </TableHead>
      {campaignTemplates && (
        <TableBody>
          {campaignTemplates.map(({ channel, id, name, status, creativeTemplates }, i) => (
            <TableRow key={id}>
              <StyledTableCell>
                <Link path={CAMPAIGN_TEMPLATE_PATH} params={{ brandId, salesProgramId, id }}>
                  {name}
                </Link>
              </StyledTableCell>
              <StyledTableCell>{STATUS_NAMES[status]}</StyledTableCell>
              <StyledTableCell collapse>{CHANNEL_NAMES[channel]}</StyledTableCell>
              <StyledTableCell numeric>
                {creativeTemplates ? creativeTemplates.length : 0} Creative Templates
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      )}
    </Table>
  )
}

CampaignTemplatesTable.propTypes = {
  campaignTemplates: PropTypes.array.isRequired,
  brandId: PropTypes.string.isRequired,
  salesProgramId: PropTypes.string.isRequired,
}

export default CampaignTemplatesTable
