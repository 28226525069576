import * as React from 'react'
import Moment from 'react-moment'
import { auth } from 'components/CovaultAuth'
import { compose } from 'ramda'
import { Table, TableBody, TableHead, TableRow } from '@material-ui/core'
import { StyledTableCell } from 'components/materialUI'
import { Trans } from 'react-i18next'
import PropTypes from 'prop-types'
import Link from 'components/Link'
import { STATUS_NAMES } from 'constants/ads'
import { SALES_PROGRAM_PATH } from 'constants/paths'

export function SalesProgramsTable({ auth: { isBrand }, brand, salesPrograms }) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <StyledTableCell style={{ width: '10%' }}>
            <Trans>Code</Trans>
          </StyledTableCell>
          <StyledTableCell>
            <Trans>Name</Trans>
          </StyledTableCell>
          <StyledTableCell>
            <Trans>Product Line</Trans>
          </StyledTableCell>
          <StyledTableCell collapse>
            <Trans>Run Date</Trans>
          </StyledTableCell>
          {isBrand && (
            <StyledTableCell>
              <Trans>Status</Trans>
            </StyledTableCell>
          )}
          <StyledTableCell collapse numeric>
            <Trans>Templates</Trans>
          </StyledTableCell>
        </TableRow>
      </TableHead>
      {brand && (
        <TableBody>
          {salesPrograms.map(({ name, code, id, productLine, startDate, endDate, campaignTemplates, status }, i) => (
            <TableRow key={id}>
              <StyledTableCell>
                <Link path={SALES_PROGRAM_PATH} params={{ id, brandId: brand.id }}>
                  {code}
                </Link>
              </StyledTableCell>
              <StyledTableCell>{name}</StyledTableCell>
              <StyledTableCell>{productLine ? productLine.name : ''}</StyledTableCell>
              <StyledTableCell collapse>
                <Moment format="LL">{startDate}</Moment> - <Moment format="LL">{endDate}</Moment>
              </StyledTableCell>
              {isBrand && <StyledTableCell>{STATUS_NAMES[status]}</StyledTableCell>}
              <StyledTableCell collapse numeric>
                {campaignTemplates && campaignTemplates.length}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      )}
    </Table>
  )
}

SalesProgramsTable.propTypes = {
  auth: PropTypes.object.isRequired,
  brand: PropTypes.object.isRequired,
  salesPrograms: PropTypes.array.isRequired,
}

export default compose(auth())(SalesProgramsTable)
