import gql from 'graphql-tag'
import {
  adPartialFragment,
  assumeRoleFragment,
  creativeTemplateFragment,
  campaignTemplateFragment,
  dealerFragment,
  productLineFragment,
  salesProgramFragment,
} from '.'

export const CampaignTemplatesQueryDocument = gql`
  query CampaignTemplates($brandId: ID!, $salesProgramId: ID!) {
    brand(id: $brandId) {
      id
      salesProgram(id: $salesProgramId) {
        id
        campaignTemplates {
          ...campaignTemplate
          creativeTemplates {
            ...creativeTemplate
          }
        }
      }
    }
  }

  ${campaignTemplateFragment}
  ${creativeTemplateFragment}
`

export const CampaignTemplateQueryDocument = gql`
  query CampaignTemplates($brandId: ID!, $salesProgramId: ID!, $id: ID!) {
    brand(id: $brandId) {
      id
      salesProgram(id: $salesProgramId) {
        id
        startDate
        endDate
        productLine {
          ...productLine
        }
        campaignTemplate(id: $id) {
          ...campaignTemplate
          creativeTemplates {
            ...creativeTemplate
          }
        }
      }
    }
  }

  ${campaignTemplateFragment}
  ${creativeTemplateFragment}
  ${productLineFragment}
`

export const RunCampaignQueryDocument = gql`
  query($brandId: ID!, $filterExpired: Boolean) {
    brand(id: $brandId) {
      id
      name
      productLines {
        id
        name
        logoLocation {
          domain
          path
          key
        }
        campaignTemplates(filterExpired: $filterExpired) {
          ...campaignTemplate
          salesProgram {
            ...salesProgram
          }
          creativeTemplates {
            ...creativeTemplate
          }
        }
      }
    }
    dealers {
      ...dealer
    }
  }

  ${dealerFragment}
  ${salesProgramFragment}
  ${creativeTemplateFragment}
  ${campaignTemplateFragment}
`

export const CreateCampaignTemplateMutationDocument = gql`
  mutation($salesProgramId: ID!, $campaignTemplate: CampaignTemplateCreateParams) {
    createCampaignTemplate(salesProgramId: $salesProgramId, campaignTemplate: $campaignTemplate) {
      ...campaignTemplate
    }
  }

  ${campaignTemplateFragment}
`

export const EditCampaignTemplateMutationDocument = gql`
  mutation($campaignTemplateId: ID!, $campaignTemplate: CampaignTemplateCreateParams) {
    updateCampaignTemplate(campaignTemplateId: $campaignTemplateId, campaignTemplate: $campaignTemplate) {
      ...campaignTemplate
    }
  }

  ${campaignTemplateFragment}
`

export const DeleteCampaignTemplateMutationDocument = gql`
  mutation($id: ID!) {
    deleteCampaignTemplate(campaignTemplateId: $id) {
      ...campaignTemplate
    }
  }

  ${campaignTemplateFragment}
`

export const ArchiveCampaignTemplateMutationDocument = gql`
  mutation($id: ID!) {
    archiveCampaignTemplate(campaignTemplateId: $id) {
      ...campaignTemplate
    }
  }

  ${campaignTemplateFragment}
`

export const PublishCampaignTemplateMutationDocument = gql`
  mutation($id: ID!) {
    publishCampaignTemplate(campaignTemplateId: $id) {
      ...campaignTemplate
    }
  }

  ${campaignTemplateFragment}
`

export const CreateCreativeTemplateMutationDocument = gql`
  mutation($campaignTemplateId: ID!, $creativeTemplate: CreativeTemplateCreateParams) {
    createCreativeTemplate(campaignTemplateId: $campaignTemplateId, creativeTemplate: $creativeTemplate) {
      assumeRole {
        ...assumeRole
      }
      overlayAssumeRole {
        ...assumeRole
      }
      creativeTemplate {
        ...creativeTemplate
      }
    }
  }

  ${assumeRoleFragment}
  ${creativeTemplateFragment}
`

export const UpdateCreativeTemplateMutationDocument = gql`
  mutation($id: ID!, $creativeTemplate: CreativeTemplateCreateParams) {
    updateCreativeTemplate(creativeTemplateId: $id, creativeTemplate: $creativeTemplate) {
      assumeRole {
        ...assumeRole
      }
      overlayAssumeRole {
        ...assumeRole
      }
      creativeTemplate {
        ...creativeTemplate
      }
    }
  }

  ${assumeRoleFragment}
  ${creativeTemplateFragment}
`

export const DeleteCreativeTemplateMutationDocument = gql`
  mutation($id: ID!) {
    deleteCreativeTemplate(creativeTemplateId: $id) {
      ...creativeTemplate
    }
  }

  ${creativeTemplateFragment}
`

export const RunCampaignMutationDocument = gql`
  mutation CreateCampaign($campaign: AdCreateParams!, $salesProgramId: ID!) {
    createAd(ad: $campaign, salesProgramId: $salesProgramId) {
      ...adPartial
      creatives {
        id
      }
    }
  }

  ${adPartialFragment}
`
