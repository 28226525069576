import * as React from 'react'
import PropTypes from 'prop-types'
import { auth } from 'components/CovaultAuth'
import { compose } from 'ramda'
import { graphql } from 'react-apollo'
import { SalesProgramQueryDocument } from '../graphql'
import SecondaryNavBar from 'components/SecondaryNavBar'
import DocumentLink from 'components/DocumentLink'
import Title from 'components/Title'
import CTAButtonWrapper from 'components/CTAButtonWrapper'
import { Paper, Typography, withStyles } from '@material-ui/core'
import withBrandId from 'components/WithBrandId'
import ScrollWrapper from 'components/ScrollWrapper'
import CampaignTemplatesTable from 'components/CampaignTemplatesTable'
import graphQLData from 'hoc/GraphQLData'
import { CreateAd, CreateCampaignTemplate, EditSalesProgram } from 'components/EntityButtons'
import { ArchiveSalesProgram, DeleteSalesProgram, PublishSalesProgram } from 'components/TransitionEntityButton'
import { STATUS_NAMES } from '../constants'
import { hasDatePassed } from '../utils/helpers'

const styles = theme => ({
  paper: {
    margin: '24px',
  },
})

export function SalesProgramContainer(props) {
  const {
    brandId,
    classes,
    data: {
      brand: {
        salesProgram,
        salesProgram: {
          id: salesProgramId,
          documentLocation,
          code,
          endDate,
          startDate,
          name,
          status,
          productLine,
          campaignTemplates,
        },
      },
    },
  } = props
  const campaignTemplate = campaignTemplates[0]

  return (
    <React.Fragment>
      <SecondaryNavBar>
        <Title>{name}</Title>
        <CTAButtonWrapper>
          <DeleteSalesProgram salesProgram={salesProgram} />
          <ArchiveSalesProgram salesProgram={salesProgram} />
          <EditSalesProgram salesProgram={salesProgram} brandId={brandId} />
          <PublishSalesProgram salesProgram={salesProgram} />
          {!hasDatePassed(Date.parse(endDate)) && (
            <CreateAd salesProgram={salesProgram} campaignTemplate={campaignTemplate} />
          )}
          <CreateCampaignTemplate brandId={brandId} salesProgram={salesProgram} />
        </CTAButtonWrapper>
      </SecondaryNavBar>
      <ScrollWrapper>
        <React.Fragment>
          <Paper elevation={0} className={classes.paper}>
            <Typography data-testid="title" variant="title">
              &nbsp;
              {code} - {productLine.name}
            </Typography>
            <br />
            <Typography component="p">
              Run date: {startDate} - {endDate}
            </Typography>
            <Typography component="p">
              This sales program is currently&nbsp;
              <strong data-testid="status">{STATUS_NAMES[status]}</strong>
            </Typography>
            <br />
            {documentLocation && (
              <DocumentLink documentLocation={documentLocation} label="Sales Program Description Document" />
            )}
          </Paper>
          <CampaignTemplatesTable
            campaignTemplates={campaignTemplates}
            salesProgramId={salesProgramId}
            brandId={brandId}
          />
        </React.Fragment>
      </ScrollWrapper>
    </React.Fragment>
  )
}

SalesProgramContainer.propTypes = {
  brandId: PropTypes.string,
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export default compose(
  withStyles(styles),
  auth({ requiredPermission: 'sales_program:view' }),
  withBrandId,
  graphql(SalesProgramQueryDocument, {
    skip: ({ brandId }) => !brandId,
    options: ({
      match: {
        params: { id: salesProgramId },
      },
      brandId,
    }) => ({
      variables: { salesProgramId, brandId },
    }),
  }),
  graphQLData
)(SalesProgramContainer)
