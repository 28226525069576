import * as React from 'react'
import { css } from 'emotion'
import { Trans } from 'react-i18next'
import { CardHeader, DialogContent, DialogTitle, Paper, Typography, withStyles } from '@material-ui/core'
import { PHONE_BREAKPOINT } from '../../constants'

const covaultFieldContainerStyle = css`
  > * {
    margin-bottom: 20px !important;
  }
`
const dialogueTitleStyle = css`
  min-width: 500px !important;
`

// some CreateEntityButton modal (aka dialogue) styled components.
const CEBDialogTitle = props => (
  <DialogTitle className={dialogueTitleStyle} {...props}>
    <Trans>{props.children}</Trans>
  </DialogTitle>
)

const CEBDialogContent = props => <DialogContent className={covaultFieldContainerStyle}>{props.children}</DialogContent>

const Headline = props => (
  <Typography component="div" variant="headline" {...props}>
    <Trans>{props.children}</Trans>
  </Typography>
)
const ErrorBar = withStyles(theme => ({
  root: {
    height: '40px',
    lineHeight: '40px',
    textAlign: 'center',
    verticalAlign: 'middle',
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
}))(props => (
  <Paper elevation={0} classes={props.classes} data-testid="error-bar" square>
    <Trans>{props.children}</Trans>
  </Paper>
))

const CenteredPurpleText = withStyles(theme => ({
  root: {
    margin: '24px',
    textAlign: 'center',
    color: theme.palette.secondary.main,
  },
}))(props => (
  <Typography variant="subtitle1" {...props}>
    {props.children}
  </Typography>
))

// some styled components used for common page content.
// use these for big blank pages that have several sections of content.
const StyledCardHeader = withStyles({
  root: {
    [PHONE_BREAKPOINT]: {
      paddingLeft: 0,
    },
  },
})(CardHeader)

const BlankSectionPaper = withStyles({
  root: {
    margin: '48px 24px 0',

    [PHONE_BREAKPOINT]: {
      marginLeft: 16,
    },
  },
})(Paper)

const SectionPaper = ({ children, ...props }) => (
  <BlankSectionPaper elevation={0} {...props}>
    {children}
  </BlankSectionPaper>
)

const TranslatedParagraph = ({ children, ...props }) => (
  <Typography component="p" gutterBottom {...props}>
    <Trans>{children}</Trans>
  </Typography>
)

export {
  covaultFieldContainerStyle,
  CEBDialogTitle,
  CEBDialogContent,
  Headline,
  ErrorBar,
  CenteredPurpleText,
  StyledCardHeader,
  SectionPaper,
  TranslatedParagraph,
}
