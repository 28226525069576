export const BUMP_CAMPAIGN = 'BUMP_CAMPAIGN'
export const UPDATE_SESSION_STATE = 'UPDATE_SESSION_STATE'
export const CLEAR_SESSION_STATE = 'CLEAR_SESSION_STATE'

export const bumpCampaign = campaign => ({
  type: BUMP_CAMPAIGN,
  payload: { campaign },
})
export const updateSessionState = sessionStateUpdate => ({
  type: UPDATE_SESSION_STATE,
  payload: { sessionStateUpdate },
})
export const clearSessionState = () => ({
  type: CLEAR_SESSION_STATE,
  payload: void 0,
})
