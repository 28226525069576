import gql from 'graphql-tag'
import { assumeRoleFragment, creativeFragment } from './fragments'

export const UpdateCreativeMutationDocument = gql`
  mutation UpdateCreative($creative: CreativeUpdateParams!, $id: ID!) {
    updateCreative(creative: $creative, id: $id) {
      assumeRole {
        ...assumeRole
      }
      creative {
        ...creative
      }
    }
  }

  ${assumeRoleFragment}
  ${creativeFragment}
`

export const DeleteCreativeMutationDocument = gql`
  mutation DeleteCreative($id: ID!) {
    deleteCreative(id: $id) {
      id
    }
  }
`
