import * as React from 'react'
import PropTypes from 'prop-types'
import withSnackbar from 'components/WithSnackbar'
import { compose } from 'ramda'
import { css } from 'emotion'
import { graphql } from 'react-apollo'
import { Trans } from 'react-i18next'
import { Button } from '@material-ui/core'
import { BrandUserProfilesQueryDocument, DealerUserProfilesQueryDocument, OnboardUserProfileMutationDocument } from '../../graphql'

const onboardingButtonStyle = css`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  div {
    margin-top: auto;
  }
`

export function OnboardingButton(props) {
  const {
    userProfile: {
      id: userProfileId,
      roles,
      auth: { email, loginsCount, lastLogin },
    },
    mutate,
  } = props

  if (!Boolean(email) || roles.length <= 0) {
    return null
  }

  const onboard = () => {
    mutate({
      variables: {
        userProfileId,
      },
    })
  }

  return (
    <div className={onboardingButtonStyle}>
      <div>Logins: {loginsCount ? loginsCount : 0}</div>
      <div>Last Login: {lastLogin ? lastLogin : 'Never'}</div>
      <Button color="primary" onClick={onboard} data-testid="onboard">
        <Trans>Send Onboarding Email</Trans>
      </Button>
    </div>
  )
}

OnboardingButton.propTypes = {
  userProfile: PropTypes.object.isRequired,
  parentId: PropTypes.string,
  mutate: PropTypes.func.isRequired,
}

export default compose(
  withSnackbar,
  graphql(OnboardUserProfileMutationDocument, {
    options: ({ parentId, snackbarContext }) => ({
      onCompleted: () => snackbarContext.snackbarMessage('Email Sent', 'Onboarding Email Sent!'),
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: BrandUserProfilesQueryDocument, variables: { brandId: parentId } },
        { query: DealerUserProfilesQueryDocument, variables: { dealerId: parentId } },
      ],
    }),
  })
)(OnboardingButton)
