import * as React from 'react'
import { Trans } from 'react-i18next'
import SecondaryNavBar from 'components/SecondaryNavBar'
import Title from 'components/Title'
import CampaignTemplatesTable from 'components/CampaignTemplatesTable'
import { CampaignTemplatesQueryDocument } from '../graphql'
import { graphql } from 'react-apollo'
import { auth } from 'components/CovaultAuth'
import withBrandId from 'components/WithBrandId'
import PropTypes from 'prop-types'
import { compose } from 'ramda'
import Loading from 'components/Loading'
import ScrollWrapper from 'components/ScrollWrapper'

export function CampaignTemplatesContainer({ brandId, data: { loading, error, brand } }) {
  if (loading) {
    return <Loading />
  }
  if (error) {
    throw error
  }

  const {
    salesProgram: { id: salesProgramId, campaignTemplates }
  } = brand

  return (
    <React.Fragment>
      <SecondaryNavBar>
        <Title>
          <Trans>Campaign Templates</Trans>
        </Title>
      </SecondaryNavBar>
      <ScrollWrapper>
        <CampaignTemplatesTable
          campaignTemplates={campaignTemplates}
          salesProgramId={salesProgramId}
          brandId={brandId}
        />
      </ScrollWrapper>
    </React.Fragment>
  )
}

CampaignTemplatesContainer.propTypes = {
  data: PropTypes.object,
  brandId: PropTypes.string
}

export default compose(
  auth({
    requiredParent: ['covault', 'brand'],
    requiredPermission: 'sales_program:view'
  }),
  withBrandId,
  graphql(CampaignTemplatesQueryDocument, {
    options: ({
      match: {
        params: { salesProgramId }
      },
      brandId
    }) => ({
      variables: { salesProgramId, brandId }
    })
  })
)(CampaignTemplatesContainer)
