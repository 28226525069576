// see this document for help understand CEB. https://covault-documentation.s3-us-west-2.amazonaws.com/create-entity-button.png

import * as React from 'react'
import { connect } from 'react-redux'
import { withApollo } from 'react-apollo'
import { routerActions } from 'react-router-redux'
import PropTypes from 'prop-types'
import { compose, find, propEq } from 'ramda'
import { get, toPath } from 'lodash'
import { translate } from 'react-i18next'
import CreateEntityButton from 'components/CreateEntityButton'
import CovaultTextField from 'components/CovaultTextField'
import CovaultDatePicker from 'components/CovaultDatePicker'
import CovaultFilePicker from 'components/CovaultFilePicker'
import CovaultDropdown from 'components/CovaultDropdown'
import CovaultSwitch from 'components/CovaultSwitch'
import withSnackbar from 'components/WithSnackbar'
import { uploadFile, uploadTemporaryFile } from '../EntityButtons/uploadFileHelper'

const INITIAL_STATE = {
  file: void 0,
  loading: void 0,
  location: void 0,
  progress: void 0,
}

export class BlankCEB extends React.Component {
  static propTypes = {
    config: PropTypes.shape({
      mutation: PropTypes.object.isRequired,
      // responseSlug is the key that your mutation response data is under, and it's defined in the GraphQL document.
      // If mutation response is { data: { createBrand: { assumeRole, brand } } }, the responseSlug is createBrand.
      responseSlug: PropTypes.string,
      // entityId is a function for getting an id out of the props passed to the CEB instance.
      // Editing a Sales Program? Then id would be the sales program's id, and you would define entityId like
      // (props) => props.salesProgram.id. Now the id will be sent off with the response.
      entityId: PropTypes.func,
      buttonText: PropTypes.string.isRequired,
      // redirectPath is a function that takes the mutation response data and returns a path that we redirect to.
      redirectPath: PropTypes.func,
      refetchQueries: PropTypes.func,
      snackbarOnComplete: PropTypes.shape({
        successTitle: PropTypes.string,
        errorTitle: PropTypes.string,
      }),
      fields: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
          label: PropTypes.string,
          path: PropTypes.string,
        })
      ).isRequired,
    }).isRequired,
    t: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
    snackbarContext: PropTypes.object.isRequired,
  }

  state = INITIAL_STATE

  render() {
    const {
      props: { config, replace, t },
      state: { loading, progress, errorMessage },
    } = this

    const uploadFileOpts = {
      errorMessage: config.snackbarOnComplete ? config.snackbarOnComplete.errorTitle : void 0,
      successMessage: config.snackbarOnComplete ? config.snackbarOnComplete.successTitle : void 0,
    }

    return (
      <CreateEntityButton
        entityId={config.entityId && config.entityId(this.props)}
        mutation={config.mutation}
        buttonText={t(config.buttonText)}
        onCompleted={async data => {
          if (find(propEq('type', 'file'), config.fields)) {
            const { assumeRole } = data[config.responseSlug]
            await uploadFile.bind(this)(assumeRole, 'file', uploadFileOpts)
          }

          if (config.redirectPath && typeof config.redirectPath === 'function') {
            replace(config.redirectPath(data[config.responseSlug]))
          }

          this.setState({ loading: void 0, progress: void 0 })
        }}
        progress={progress}
        loading={loading}
        refetchQueries={() => config.refetchQueries(this.props)}
        submitHook={() => this.setState({ loading: true, errorMessage: void 0 })}
        errorHook={() => this.setState({ loading: false })}
        errorMessage={errorMessage}
        buttonProps={config.buttonProps}
      >
        {(setValueFactory, errorFields) => (
          <React.Fragment>
            {config.fields.map(fieldSpec => {
              const pathArray = toPath(fieldSpec.path)
              const lastKeyInPath = pathArray[pathArray.length - 1]

              switch (fieldSpec.type) {
                case 'text':
                  return (
                    <CovaultTextField
                      id={fieldSpec.id}
                      defaultValue={get(this.props, fieldSpec.path) || ''}
                      label={t(fieldSpec.label)}
                      setValue={setValueFactory(fieldSpec.path)}
                      error={errorFields[lastKeyInPath]}
                      key={lastKeyInPath}
                      testid={fieldSpec.testid}
                    />
                  )
                case 'file':
                  return (
                    <CovaultFilePicker
                      label={t(fieldSpec.label)}
                      error={errorFields[lastKeyInPath]}
                      setValue={(hasFile, file) => {
                        if (!hasFile) {
                          setValueFactory(fieldSpec.path)(void 0)
                          this.setState({ file: void 0 })
                        } else {
                          this.setState({ file })
                          setValueFactory(fieldSpec.path)(file.name)
                          fieldSpec.isTemporary &&
                            uploadTemporaryFile.bind(this)(file, 'file', 'location', uploadFileOpts)
                        }
                      }}
                      testid={fieldSpec.testid}
                      key={lastKeyInPath}
                      required
                    />
                  )
                case 'date':
                  return (
                    <CovaultDatePicker
                      error={errorFields[lastKeyInPath]}
                      testid={fieldSpec.testid}
                      setValue={setValueFactory(fieldSpec.path)}
                      defaultValue={fieldSpec.defaultValue}
                      minDate={fieldSpec.minDate}
                      maxDate={fieldSpec.maxDate}
                      label={t(fieldSpec.label)}
                      style={halfWidth}
                      autoOk
                      disablePast
                      key={lastKeyInPath}
                    />
                  )
                case 'dropdown':
                  return (
                    <CovaultDropdown
                      error={errorFields[lastKeyInPath]}
                      testid={fieldSpec.testid}
                      setValue={setValueFactory(fieldSpec.path)}
                      itemNames={fieldSpec.itemNames}
                      itemValues={fieldSpec.itemValues}
                      defaultValue={fieldSpec.defaultValue}
                      label={t(fieldSpec.label)}
                      key={lastKeyInPath}
                    />
                  )
                case 'switch':
                  return (
                    <CovaultSwitch
                      error={errorFields[lastKeyInPath]}
                      testid={fieldSpec.testid}
                      setValue={setValueFactory(fieldSpec.path)}
                      defaultValue={get(this.props, fieldSpec.path) || fieldSpec.defaultValue}
                      label={t(fieldSpec.label)}
                      key={lastKeyInPath}
                    />
                  )
                default:
                  return null
              }
            })}
          </React.Fragment>
        )}
      </CreateEntityButton>
    )
  }
}

const halfWidth = {
  width: '50%',
}

export default compose(
  withSnackbar,
  translate(),
  connect(
    null,
    { replace: routerActions.replace }
  ),
  withApollo
)(BlankCEB)
