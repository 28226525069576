import * as React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'ramda'
import { translate } from 'react-i18next'
import SecondaryNavBar from 'components/SecondaryNavBar'
import Title from 'components/Title'
import BrandsTable from 'components/BrandsTable'
import { CreateBrand } from 'components/CreateEntityButton'

export class BrandsContainer extends React.Component {
  static propTypes = {
    t: PropTypes.func
  }

  render() {
    const { t } = this.props

    return (
      <React.Fragment>
        <SecondaryNavBar>
          <Title>{t('Brands')}</Title>
          <CreateBrand />
        </SecondaryNavBar>
        <BrandsTable />
      </React.Fragment>
    )
  }
}

export default compose(translate())(BrandsContainer)
