// see this document for help understand CEB. https://covault-documentation.s3-us-west-2.amazonaws.com/create-entity-button.png

import * as React from 'react'
import {
  BrandsQueryDocument,
  CreateBrandMutationDocument,
  UpdateBrandMutationDocument,
  BrandQueryDocument,
  CreateProductLineMutationDocument,
  UpdateProductLineMutationDocument,
  LinkDealerToBrandMutationDocument,
  LinkBrandToDealerMutationDocument,
  DealersQueryDocument,
  CreateDealerMutationDocument,
  UpdateDealerMutationDocument,
  DealerQueryDocument,
  CreateAssetMutationDocument,
  SalesProgramQueryDocument,
} from '../../graphql'
import { BlankCEB } from 'components/CreateEntityButton'
import { generatePath } from 'react-router'
import { BRAND_PATH } from '../../constants'
import { always, path, prop } from 'ramda'

const CreateBrandConfig = {
  mutation: CreateBrandMutationDocument,
  responseSlug: 'createBrand',
  buttonText: 'Create Brand',
  redirectPath: response => generatePath(BRAND_PATH, { id: response.brand.id }),
  refetchQueries: () => [{ query: BrandsQueryDocument }],
  snackbarOnComplete: {
    successTitle: 'Brand Created',
    errorTitle: 'An error ocurred while uploading the brand logo.',
  },
  fields: [
    {
      type: 'text',
      label: 'Brand Name',
      path: 'brand.name',
    },
    {
      type: 'text',
      label: 'Notification Email',
      path: 'brand.notificationEmail',
    },
    {
      type: 'file',
      label: 'Brand Logo',
      path: 'brand.logoFilename',
    },
  ],
}

const CreateSalesProgramConfig = {
  mutation: CreateBrandMutationDocument,
  responseSlug: 'createBrand',
  buttonText: 'Create Brand',
  redirectPath: response => generatePath(BRAND_PATH, { id: response.brand.id }),
  refetchQueries: () => [{ query: BrandsQueryDocument }],
  snackbarOnComplete: {
    successTitle: 'Brand Created',
    errorTitle: 'An error ocurred while uploading the brand logo.',
  },
  fields: [
    {
      type: 'text',
      label: 'Brand Name',
      path: 'brand.name',
    },
    {
      type: 'text',
      label: 'Notification Email',
      path: 'brand.notificationEmail',
    },
    {
      type: 'file',
      label: 'Brand Logo',
      path: 'brand.logoFilename',
    },
  ],
}

const EditBrandConfig = {
  mutation: UpdateBrandMutationDocument,
  responseSlug: 'updateBrand',
  buttonText: 'Edit Brand',
  refetchQueries: props => [{ query: BrandQueryDocument, variables: { id: props.brand.id } }],
  entityId: props => props.brand.id,
  fields: [
    {
      type: 'text',
      label: 'Brand Name',
      path: 'brand.name',
    },
    {
      type: 'text',
      label: 'Notification Email',
      path: 'brand.notificationEmail',
    },
    {
      type: 'file',
      label: 'Brand Logo',
      path: 'brand.logoFilename',
    },
  ],
}

const CreateProductLineConfig = {
  mutation: CreateProductLineMutationDocument,
  responseSlug: 'createProductLine',
  buttonText: 'Create Product Line',
  refetchQueries: props => [{ query: BrandQueryDocument, variables: { id: props.brand.id } }],
  entityId: props => props.brand.id,
  fields: [
    {
      type: 'text',
      label: 'Product Line Name',
      path: 'productLine.name',
    },
    {
      type: 'file',
      label: 'Product Image',
      path: 'productLine.logoFilename',
    },
  ],
}

const EditProductLineConfig = {
  buttonProps: { variant: 'flat' },
  mutation: UpdateProductLineMutationDocument,
  responseSlug: 'updateProductLine',
  buttonText: 'Edit',
  refetchQueries: props => [{ query: BrandQueryDocument, variables: { id: props.brand.id } }],
  entityId: path(['productLine', 'id']),
  fields: [
    {
      type: 'text',
      label: 'Product Line Name',
      path: 'productLine.name',
    },
    {
      type: 'file',
      label: 'Product Image',
      path: 'productLine.logoFilename',
    },
  ],
}

const LinkToDealerConfig = ({ brand, dealers }) => {
  // dealers in dealers array should each have a list of brands.

  // cheating a little bit here. This should be static, but the dropdowns necessitate a bit of logic in the config.
  // TODO figure out how to nicely pass in dropdown values
  const unlinkedDealers = dealers.filter(({ brands }) => !brands.map(prop('id')).includes(brand.id))

  return {
    mutation: LinkDealerToBrandMutationDocument,
    responseSlug: 'linkDealerToBrand',
    buttonText: 'Link To Dealer',
    refetchQueries: props => [{ query: BrandQueryDocument, variables: { id: props.brand.id } }],
    entityId: always(brand.id),
    fields: [
      {
        type: 'dropdown',
        label: 'Dealer',
        path: 'dealerId',
        itemNames: unlinkedDealers.map(prop('name')),
        itemValues: unlinkedDealers.map(prop('id')),
        defaultValue: '-1',
      },
    ],
  }
}

const LinkToBrandConfig = ({ brands, dealerId, brandId }) => {
  const unlinkedBrands = brands.filter(({ dealers }) => !dealers.map(prop('id')).includes(dealerId))

  return {
    mutation: LinkBrandToDealerMutationDocument,
    responseSlug: 'linkDealerToBrand',
    buttonText: 'Link To Brand',
    refetchQueries: () => [{ query: DealersQueryDocument }],
    entityId: always(dealerId),
    fields: [
      {
        type: 'dropdown',
        label: 'Brand',
        path: 'brandId',
        itemNames: unlinkedBrands.map(prop('name')),
        itemValues: unlinkedBrands.map(prop('id')),
        defaultValue: '-1',
      },
    ],
  }
}

const CreateDealerConfig = {
  mutation: CreateDealerMutationDocument,
  responseSlug: 'createDealer',
  buttonText: 'Create Dealer',
  refetchQueries: () => [{ query: DealersQueryDocument }],
  fields: [
    {
      type: 'text',
      label: 'Dealer Name',
      path: 'dealer.name',
    },
    {
      type: 'text',
      label: 'Notification Email',
      path: 'dealer.notificationEmail',
    },
    {
      type: 'text',
      label: 'Address',
      path: 'dealer.address',
    },
    {
      type: 'text',
      label: 'City',
      path: 'dealer.city',
    },
    {
      type: 'text',
      label: 'State',
      path: 'dealer.state',
    },
    {
      type: 'text',
      label: 'Country',
      path: 'dealer.country',
    },
    {
      type: 'text',
      label: 'Website URL',
      path: 'dealer.url',
    },
    {
      type: 'switch',
      label: 'Single-Party',
      path: 'dealer.isSingleParty',
      defaultValue: false,
    },
    {
      type: 'text',
      label: 'Dealer\'s Facebook Account Slug (check their Facebook account URL)',
      path: 'dealer.facebookSlug',
    },
    {
      type: 'file',
      label: 'Dealer Logo',
      path: 'dealer.logoFilename',
    },
  ],
}

const EditDealerConfig = {
  mutation: UpdateDealerMutationDocument,
  responseSlug: 'updateDealer',
  buttonText: 'Edit Dealer',
  refetchQueries: props => [{ query: DealerQueryDocument, variables: { id: props.dealer.id } }],
  entityId: path(['dealer', 'id']),
  fields: [
    {
      type: 'text',
      label: 'Dealer Name',
      path: 'dealer.name',
    },
    {
      type: 'text',
      label: 'Notification Email',
      path: 'dealer.notificationEmail',
    },
    {
      type: 'text',
      label: 'Address',
      path: 'dealer.address',
    },
    {
      type: 'text',
      label: 'City',
      path: 'dealer.city',
    },
    {
      type: 'text',
      label: 'State',
      path: 'dealer.state',
    },
    {
      type: 'text',
      label: 'Country',
      path: 'dealer.country',
    },
    {
      type: 'text',
      label: 'Website URL',
      path: 'dealer.url',
    },
    {
      type: 'switch',
      label: 'Single-Party',
      path: 'dealer.isSingleParty',
    },
    {
      type: 'text',
      label: 'Dealer\'s Facebook Account Slug (check their Facebook account URL)',
      path: 'dealer.facebookSlug',
    },
    {
      type: 'file',
      label: 'Dealer Logo',
      path: 'dealer.logoFilename',
    },
  ],
}

const CreateAssetConfig = {
  mutation: CreateAssetMutationDocument,
  responseSlug: 'createAsset',
  buttonText: 'Upload Asset',
  refetchQueries: ({ brandId, salesProgram }) => [
    { query: SalesProgramQueryDocument, variables: { brandId, salesProgramId: salesProgram.id } },
  ],
  entityId: path(['salesProgram', 'id']),
  fields: [
    {
      type: 'file',
      label: 'Asset',
      path: 'asset.filename',
    },
  ],
}

const CreateBrand = props => <BlankCEB config={CreateBrandConfig} {...props} />
const CreateSalesProgram = props => <BlankCEB config={CreateSalesProgramConfig} {...props} />
const EditBrand = props => <BlankCEB config={EditBrandConfig} {...props} />
const CreateProductLine = props => <BlankCEB config={CreateProductLineConfig} {...props} />
const EditProductLine = props => <BlankCEB config={EditProductLineConfig} {...props} />
const LinkToDealer = props => <BlankCEB config={LinkToDealerConfig(props)} {...props} />
const LinkToBrand = props => <BlankCEB config={LinkToBrandConfig(props)} {...props} />
const CreateDealer = props => <BlankCEB config={CreateDealerConfig} {...props} />
const EditDealer = props => <BlankCEB config={EditDealerConfig} {...props} />
const CreateAsset = props => <BlankCEB config={CreateAssetConfig} {...props} />

export {
  CreateBrand,
  EditBrand,
  CreateSalesProgram,
  CreateProductLine,
  EditProductLine,
  LinkToDealer,
  LinkToBrand,
  CreateDealer,
  EditDealer,
  CreateAsset,
}
