import * as React from 'react'
import { Trans } from 'react-i18next'
import SecondaryNavBar from 'components/SecondaryNavBar'
import Title from 'components/Title'
import DealersTable from 'components/DealersTable'
import { CreateDealer } from 'components/CreateEntityButton'

export class DealersContainer extends React.Component {
  render() {
    return (
      <React.Fragment>
        <SecondaryNavBar>
          <Title>
            <Trans>Dealers</Trans>
          </Title>
          <CreateDealer />
        </SecondaryNavBar>
        <DealersTable />
      </React.Fragment>
    )
  }
}

export default DealersContainer
