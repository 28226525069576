import gql from 'graphql-tag'

export const AssumeRoleMutationDocument = gql`
  mutation($filename: String!) {
    assumeRole(filename: $filename) {
      accessKeyId
      secretAccessKey
      sessionToken
      unmodifiedFileUrl
      fileKey
      bucket
    }
  }
`

export const CreateAssetMutationDocument = gql`
  mutation($asset: AssetCreateParams!, $id: ID!) {
    createAsset(asset: $asset, salesProgramId: $id) {
      assumeRole {
        accessKeyId
        secretAccessKey
        sessionToken
        unmodifiedFileUrl
        fileKey
        bucket
      }
      asset {
        id
        name
        status
        location {
          domain
          path
          key
        }
      }
    }
  }
`

export const CreateCreativeMutationDocument = gql`
  mutation($adId: ID!, $creative: CreativeCreateParams!) {
    createCreative(adId: $adId, creative: $creative) {
      assumeRole {
        accessKeyId
        secretAccessKey
        sessionToken
        unmodifiedFileUrl
        fileKey
        bucket
      }
      creative {
        clickThroughUrl
        description
        id
        name
        status
        text
        location {
          domain
          path
          key
        }
      }
    }
  }
`
