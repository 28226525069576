// This component helps us isolate parts of the app to, or from, single party dealers.
// Built in to this component is a check that it is actually a dealer we are dealing with,
// so you don't have to combine this with <CovaultAuth /> in your JSX.

import * as React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'react-apollo'
import { compose, propEq } from 'ramda'
import { DealerQueryDocument } from '../graphql'
import { auth } from 'components/CovaultAuth'

class SinglePartyDealerOnly extends React.Component {
  static propTypes = {
    data: PropTypes.object,
    error: PropTypes.object,
    loading: PropTypes.bool,
    inverted: PropTypes.bool,
  }

  render() {
    if (this.props.parentType === 'brand') {
      return this.props.children
    }

    const {
      data: { dealer, loading, error },
      inverted,
    } = this.props

    if (loading || error || !dealer) {
      return null
    }

    if ((inverted && dealer.isSingleParty) || (!inverted && !dealer.isSingleParty)) {
      return null
    }

    return this.props.children
  }
}

export default compose(
  auth(),
  graphql(DealerQueryDocument, {
    skip: propEq('parentType', 'brand'),
    options: ({ auth: { parentId: id } }) => ({ variables: { id } }),
  })
)(SinglePartyDealerOnly)
