import * as React from 'react'
import PropTypes from 'prop-types'
import { Typography, Icon } from '@material-ui/core'

export default function DocumentLink({ documentLocation, label }) {
  if (!documentLocation) {
    return null
  }

  const { domain, key, path } = documentLocation

  return (
    <Typography component="a" href={`https://${domain}${path}${key}`}>
      <Icon>cloud_download</Icon> {label}
    </Typography>
  )
}

DocumentLink.propTypes = {
  documentLocation: PropTypes.shape({
    domain: PropTypes.string,
    path: PropTypes.string,
    key: PropTypes.string
  }),
  label: PropTypes.string
}
