import * as React from 'react'
import PropTypes from 'prop-types'
import { any, compose, isNil } from 'ramda'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import IconButton from '@material-ui/core/IconButton'
import { translate } from 'react-i18next'
import { css } from 'emotion'

const downloadButtonStyle = css`
  margin-left: 'auto';
`

export function DownloadLocationButton({ location: { domain, path, key }, t }) {
  if (any(isNil)([domain, path, key])) {
    return null
  }

  return (
    <IconButton className={downloadButtonStyle} href={`https://${domain}${path}${key}`} aria-label={t('Download')}>
      <DownloadIcon />
    </IconButton>
  )
}

DownloadLocationButton.propTypes = {
  className: PropTypes.string,
  location: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
}

export default compose(translate())(DownloadLocationButton)
