/* CovaultDropdown.jsx
 * Provides a set of dropdowns to modify one variable for some GraphQL mutation.
 * Receives a setValue fn from setValueFactory provided by CreateEntityButton.
 *
 * Created by Louis Ritchie on May 4th, 2018
 * louiscritchie@gmail.com
 */

import * as React from 'react'
import PropTypes from 'prop-types'
import { MenuItem, Input, InputLabel, FormControl, FormHelperText, Select } from '@material-ui/core'
import { Trans, translate } from 'react-i18next'

const isNumeric = n => !isNaN(parseFloat(n)) && isFinite(n)

export class CovaultDropdown extends React.Component {
  static propTypes = {
    error: PropTypes.string,
    testid: PropTypes.string,
    label: PropTypes.string,
    setValue: PropTypes.func.isRequired,
    itemNames: PropTypes.array.isRequired,
    itemValues: PropTypes.array.isRequired,
    defaultValue: PropTypes.any,
    t: PropTypes.func
  }

  state = {
    value: -1
  }

  componentDidMount() {
    this.props.setValue(this.props.defaultValue)
  }

  _handleChange = ({ target: { value } }) => {
    this.setState({ value })
    this.props.setValue(value)
  }

  render() {
    const {
      props: { error, itemNames, itemValues, label, testid },
      state: { value }
    } = this

    return (
      <FormControl data-testid={testid} error={error} fullWidth>
        <InputLabel htmlFor="dropdown-helper">{label}</InputLabel>
        <Select
          value={value}
          onChange={this._handleChange}
          SelectDisplayProps={{ 'data-testid': 'covault-dropdown' }}
          input={<Input name="dropdown" id="dropdown-helper" />}
        >
          {itemValues.map((value, i) => (
            <MenuItem key={`${value}${i}`} value={isNumeric(value) ? Number(value) : value}>
              {itemNames[i]}
            </MenuItem>
          ))}
        </Select>
        {error && (
          <FormHelperText>
            <Trans>{error}</Trans>
          </FormHelperText>
        )}
      </FormControl>
    )
  }
}

export default translate()(CovaultDropdown)
