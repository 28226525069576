import * as React from 'react'
import PropTypes from 'prop-types'
import { css } from 'emotion'
import { CardActions, CardContent, CardHeader, Typography, withStyles } from '@material-ui/core'
import { CovaultAuth } from 'components/CovaultAuth'
import { ApproveAd, SubmitAdForApproval, ResubmitAdForApproval, RejectAd } from 'components/TransitionEntityButton'
import CTAButtonWrapper from 'components/CTAButtonWrapper'
import { BREAKPOINTS, CHANNEL_NAMES } from '../constants'
import PublishCampaign from 'components/PublishCampaign'
import Moment from 'moment'

const genStatusLogSentence = (campaign, brand, dealer) => {
  let lastStatusLog, secondLastStatusLog, statusLogSentence
  const { status, statusLogs } = campaign

  lastStatusLog = statusLogs
    .filter(statusLog => statusLog.status === status)
    .sort((statusLog1, statusLog2) => new Date(statusLog2.date).getTime() - new Date(statusLog1.date).getTime())
    .shift()
  secondLastStatusLog = statusLogs.filter(statusLog => statusLog.status === 'APPROVED').shift()

  if (!lastStatusLog) {
    return null
  }

  switch (status) {
    case 'SUBMITTED':
      return `${dealer.name} submitted this campaign on ${Moment(lastStatusLog.date).format('LL')}.`
    case 'APPROVED':
      return `This campaign was approved on ${Moment(lastStatusLog.date).format('LL')}.`
    case 'REJECTED':
      return `This campaign was rejected on ${Moment(lastStatusLog.date).format('LL')}
        with the following notes: "${lastStatusLog.notes}"`
    case 'PUBLISHED':
      if (!secondLastStatusLog) {
        return `${dealer.name} published this campaign on ${Moment(lastStatusLog.date).format('LL')}.`
      }

      return `This campaign was approved on ${Moment(secondLastStatusLog.date).format('LL')},
        and ${dealer.name} published this campaign on ${Moment(lastStatusLog.date).format('LL')}.`
    default:
      break
  }

  return statusLogSentence
}

export default function CallToActionCard(props) {
  const { dealer, campaign, brand } = props

  return (
    <React.Fragment>
      <CovaultAuth requiredParent="dealer">
        <div className={cardStyle}>
          <CardHeader title="What is next?" />
          {getDealerContent(dealer, campaign, brand)}
        </div>
      </CovaultAuth>
      <CovaultAuth requiredParent="brand">
        <div className={cardStyle}>
          <BrandContent dealer={dealer} campaign={campaign} brand={brand} />
        </div>
      </CovaultAuth>
    </React.Fragment>
  )
}

function BrandContent({ dealer, campaign, brand }) {
  const { channel, status } = campaign

  const statusUpdateSentence = genStatusLogSentence(campaign, brand, dealer)

  switch (status) {
    case 'SUBMITTED':
      return (
        <React.Fragment>
          <CardHeader title="Please review this campaign." />
          <StyledDiv>
            <CardContent>
              <Typography component="p" gutterBottom>
                {dealer.name} is waiting for the approval or rejection of this campaign.
              </Typography>
              <Typography component="p" gutterBottom>
                Does this campaign need some improvements? Click "Reject" to provide comments and suggestions to{' '}
                {dealer.name}.
              </Typography>
              <Typography component="p" gutterBottom>
                Otherwise, click "Approve" to approve the ad so that {dealer.name} can publish it to{' '}
                {CHANNEL_NAMES[channel]}.
              </Typography>
            </CardContent>
            <StyledCardActions>
              <CTAButtonWrapper>
                <ApproveAd ad={campaign} />
                <RejectAd ad={campaign} />
              </CTAButtonWrapper>
            </StyledCardActions>
          </StyledDiv>
        </React.Fragment>
      )
    case 'PUBLISHED':
      return (
        <React.Fragment>
          <CardHeader title="This campaign is published." />
          <StyledDiv>
            <CardContent>
              <Typography component="p" gutterBottom>
                {statusUpdateSentence}
              </Typography>
            </CardContent>
            <StyledCardActions />
          </StyledDiv>
        </React.Fragment>
      )
    case 'REJECTED':
      return (
        <React.Fragment>
          <CardHeader title="This campaign is rejected." />
          <StyledDiv>
            <CardContent>
              <Typography component="p" gutterBottom>
                {statusUpdateSentence}
              </Typography>
              <Typography component="p" gutterBottom>
                {dealer.name} has been alerted, and will submit it again when they have made your requested
                improvements.
              </Typography>
            </CardContent>
            <StyledCardActions />
          </StyledDiv>
        </React.Fragment>
      )
    case 'APPROVED':
      return (
        <React.Fragment>
          <CardHeader title="This campaign is approved." />
          <StyledDiv>
            <CardContent>
              <Typography component="p" gutterBottom>
                {dealer.name} has been notified of your approval. Now, they are permitted to run this campaign.
              </Typography>
              <Typography component="p" gutterBottom>
                When they decide to run this campaign, we will let you know here.
              </Typography>
              <Typography component="p" gutterBottom>
                {statusUpdateSentence}
              </Typography>
            </CardContent>
            <StyledCardActions />
          </StyledDiv>
        </React.Fragment>
      )
    default:
      return null
  }
}

function getDealerContent(dealer, campaign, brand) {
  const { status, budgetInCents, creatives, endDate } = campaign

  if (status === 'UNSUBMITTED' && creatives.length === 0) {
    return (
      <StyledDiv>
        <CardContent>
          <Typography component="p" gutterBottom>
            Time to add creatives to the campaign.
          </Typography>
          <Typography component="p" gutterBottom>
            You need at least one creative, but it is better to have several creatives running in a campaign at once.
          </Typography>
        </CardContent>
        <StyledCardActions />
      </StyledDiv>
    )
  } else if (status === 'UNSUBMITTED' && !Boolean(budgetInCents)) {
    return (
      <StyledDiv>
        <CardContent>
          <Typography component="p" gutterBottom>
            Set the Campaign Details.
          </Typography>
          <Typography component="p" gutterBottom>
            Set The budget.
          </Typography>
          <Typography component="p" gutterBottom>
            Check the Target Audience and Run Date.
          </Typography>
        </CardContent>
        <StyledCardActions />
      </StyledDiv>
    )
  } else if (status === 'UNSUBMITTED') {
    return (
      <StyledDiv>
        {Date.parse(endDate) > new Date() ? (
          <React.Fragment>
            <CardContent>
              <Typography component="p" gutterBottom>
                Is it time to submit your campaign for approval?
              </Typography>
              <Typography component="p" gutterBottom>
                Review the creatives and campaign details and submit the campaign for approval when you are ready.
              </Typography>
            </CardContent>
            <StyledCardActions>
              <SubmitAdForApproval ad={campaign} />
            </StyledCardActions>
          </React.Fragment>
        ) : (
          <CardContent>
            <Typography component="p" gutterBottom>
              Unfortunately, this campaign cannot be submitted because its Sales Program has expired. You may want to delete it.
            </Typography>
          </CardContent>
        )}
      </StyledDiv>
    )
  } else if (status === 'SUBMITTED') {
    return (
      <StyledDiv>
        <CardContent>
          <Typography component="p" gutterBottom>
            Your campaign has been submitted for approval.
          </Typography>
          <Typography component="p" gutterBottom>
            You are all done here for the moment. We will notify you when your campaign has been approved.
          </Typography>
        </CardContent>
        <StyledCardActions />
      </StyledDiv>
    )
  } else if (status === 'APPROVED') {
    return (
      <StyledDiv>
        <CardContent>
          <Typography component="p" gutterBottom>
            Your Campaign has been approved!
          </Typography>
          <Typography component="p" gutterBottom>
            Press the publish button to send the campaign to the media buyers.
          </Typography>
        </CardContent>
        <StyledCardActions>
          <PublishCampaign campaign={campaign} isSingleParty={false} />
        </StyledCardActions>
      </StyledDiv>
    )
  } else if (status === 'REJECTED') {
    return (
      <StyledDiv>
        <CardContent>
          <Typography component="p" gutterBottom>
            Your campaign has been rejected by the brand. The brand has provided a reason for rejecting the campaign.
          </Typography>
          <Typography component="p" gutterBottom>
            After addressing the rejection reasons, you can re-submit the campaign for approval.
          </Typography>
          <Typography component="p" gutterBottom>
            If you do not wish to re-submit the campaign, you can delete it instead.
          </Typography>
        </CardContent>
        <StyledCardActions>
          <ResubmitAdForApproval ad={campaign} />
        </StyledCardActions>
      </StyledDiv>
    )
  } else if (status === 'PUBLISHED') {
    return (
      <StyledDiv>
        <CardContent>
          <Typography component="p" gutterBottom>
            Your Campaign has been published!
          </Typography>
          <Typography component="p" gutterBottom>
            Your Campaign has been sent to the media buyers. You will be contacted when the campaign is finished.
          </Typography>
        </CardContent>
        <StyledCardActions />
      </StyledDiv>
    )
  }
}

CallToActionCard.propTypes = {
  brand: PropTypes.object,
  campaign: PropTypes.object.isRequired,
  dealer: PropTypes.object.isRequired,
}

const cardStyle = css`
  flex: 1;
  padding: 24px;
  min-width: 210px;
  display: flex;
  flex-flow: column nowrap;

  @media (max-width: ${BREAKPOINTS.phone}) {
    padding: 25px 0 0 0;
  }
`
const StyledCardActions = withStyles(() => ({
  root: {
    paddingLeft: 24,
    paddingBottom: 24,
  },
}))(CardActions)
export const StyledDiv = ({ children }) => <div className={contentWrapper}>{children}</div>

const contentWrapper = css`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  height: 100%;
`
