import * as React from 'react'
import { Typography, Card, CardContent, CircularProgress } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Trans } from 'react-i18next'

const styles = {
  card: {
    minWidth: 275,
    maxWidth: 450,
    margin: 'auto',
    marginTop: 50,
    marginBottom: 50,
  },
  cardContent: {
    textAlign: 'center',
  },
}

export function Loading({ classes, title = 'Loading' }) {
  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography variant="headline" data-testid="loading">
          <Trans>{title}</Trans>
        </Typography>
        <br />
        <CircularProgress className={classes.progress} />
      </CardContent>
    </Card>
  )
}

export default withStyles(styles)(Loading)
