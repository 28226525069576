import * as React from 'react'
import { auth } from 'components/CovaultAuth'

const withDealerId = Component => props => {
  const {
    auth: { isDealer, parentId },
  } = props

  const dealerId = isDealer ? parentId : null

  return <Component dealerId={dealerId} {...props} />
}

export default Component => auth()(withDealerId(Component))
