import * as React from 'react'
import { CreateAdMutationDocument, AdsQueryDocument, DealerQueryDocument } from '../../graphql'
import { compose, path, merge, mergeDeepLeft, join } from 'ramda'
import CreateEntityButton from 'components/CreateEntityButton'
import { translate } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { generatePath } from 'react-router'
import PropTypes from 'prop-types'
import AdForm from '../AdForm'
import covaultAuth from 'components/CovaultAuth'
import { AD_PATH, CAMPAIGN_FROM_TEMPLATE_POSTFIX } from 'constants/paths'
import { graphql } from 'react-apollo'
import withSnackbar from 'components/WithSnackbar'
import withBrandId from 'components/WithBrandId'

export const mapMutationVariables = ({
  audience,
  budget,
  channel,
  emailDestination,
  endDate,
  name,
  radius,
  startDate,
}) => {
  const obj = {
    ad: {
      budgetInCents: budget && Math.round(budget * 100),
      channel,
      endDate,
      name,
      startDate,
    },
  }

  if (channel === 'FACEBOOK' || channel === 'DOUBLE_CLICK') {
    return mergeDeepLeft(obj, {
      ad: {
        channelQuestions: [
          {
            name: 'radius',
            quantityValue: Number(radius),
            unitValue: 'km',
          },
          {
            name: 'audience',
            value: audience,
          },
        ],
      },
    })
  }

  if (channel === 'OFFLINE') {
    return mergeDeepLeft(obj, {
      ad: {
        channelQuestions: [
          {
            name: 'email_destination',
            value: emailDestination,
          },
        ],
      },
    })
  }
}

class CreateAd extends React.Component {
  state = {
    channel: void 0,
  }

  setChannelFactory = setValueFactory => channel => {
    setValueFactory('channel')(channel)
    this.setState({ channel })
  }

  handleAdFormUnmount = () => this.setState({ channel: void 0 })

  render() {
    const {
      props: {
        brandId,
        buttonText,
        campaignTemplate,
        data: { dealer = {} },
        history,
        salesProgram,
        salesProgram: { id: salesProgramId, name: salesProgramName, endDate, startDate },
        snackbarContext,
        t,
        ...props
      },
      state: { channel },
    } = this

    const initialVariables = [
      ['salesProgramEndDate', endDate],
      ['salesProgramStartDate', startDate],
      ['targetAddressLine1', dealer.address],
      ['name', salesProgramName],
      ['radius', 20],
      ['audience', path(['defaultAudience'], campaignTemplate)],
      ['targetAddressLine2', join(', ', [dealer.city, dealer.state, dealer.country])],
      ['channel', channel],
    ]

    const titleText = !channel ? 'Select Destination' : 'Give us some details about your Campaign:'

    return (
      <CreateEntityButton
        mutation={CreateAdMutationDocument}
        mapMutationVariables={variables => {
          const formMap = mapMutationVariables(variables)
          return merge(formMap, { salesProgramId })
        }}
        titleText={titleText}
        buttonText={buttonText || t('Create Campaign')}
        refetchQueries={() => [{ query: AdsQueryDocument, variables: { brandId } }]}
        onCompleted={({ createAd: { id, creatives } }) => {
          if (creatives.length >= 1) {
            history.push(
              `${generatePath(AD_PATH, {
                id,
                brandId,
              })}${CAMPAIGN_FROM_TEMPLATE_POSTFIX}`
            )
          } else {
            history.push(generatePath(AD_PATH, { id, brandId }))
          }
          snackbarContext.successMessage('Campaign Created', '')
        }}
        resetOnOpen
        initialVariables={initialVariables}
        {...props}
      >
        {(setValueFactory, errorFields, variables) => (
          <AdForm
            errorFields={errorFields}
            selectedChannel={channel}
            setChannelFunc={this.setChannelFactory(setValueFactory)}
            setValueFactory={setValueFactory}
            showBaseOptions={!!channel}
            showChannelGrid={!channel}
            t={t}
            variables={variables}
            handleUnmount={this.handleAdFormUnmount}
          />
        )}
      </CreateEntityButton>
    )
  }
}

CreateAd.propTypes = {
  brandId: PropTypes.string,
  buttonText: PropTypes.string,
  campaignTemplate: PropTypes.object,
  channel: PropTypes.string,
  data: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  salesProgram: PropTypes.object.isRequired,
  snackbarContext: PropTypes.object.isRequired,
  t: PropTypes.func,
}

export default compose(
  withRouter,
  withSnackbar,
  translate(),
  covaultAuth({
    requiredParent: 'dealer',
    requiredPermission: 'ad:manage',
  }),
  withBrandId,
  graphql(DealerQueryDocument, {
    options: props => {
      const {
        auth: { parentId },
      } = props

      return {
        variables: {
          id: parentId,
        },
      }
    },
  })
)(CreateAd)
