/*
On August 28th 2019, Louis writes:
I believe that the code from this file is from a tutorial.
However I can't find the tutorial. Good luck sorting out these redux actions.
*/

export const AUTH0_AUTHENTICATED = 'AUTH0_AUTHENTICATED'
export const AUTH0_AUTHORIZATION_ERROR = 'AUTH0_AUTHORIZATION_ERROR'
export const AUTH0_HASH_PARSED = 'AUTH0_HASH_PARSED'
export const AUTH0_LOGIN = 'AUTH0_LOGIN'
export const AUTH0_LOGOUT = 'AUTH0_LOGOUT'
export const AUTH0_PROFILE = 'AUTH0_PROFILE'
export const AUTH0_PROFILE_ERROR = 'AUTH0_PROFILE_ERROR'
export const AUTH0_SHOW_LOGIN_DIALOG = 'AUTH0_SHOW_LOGIN_DIALOG'
export const AUTH0_START_AUTH = 'AUTH0_START_AUTH'
export const AUTH0_CHECK_EXPIRY = 'AUTH0_CHECK_EXPIRY'
export const auth0Authenticated = accessToken => ({
  type: AUTH0_AUTHENTICATED,
  payload: accessToken,
})
export const auth0AuthorizationError = err => ({
  type: AUTH0_AUTHORIZATION_ERROR,
  error: err,
})
export const auth0HashParsed = payload => ({ type: AUTH0_HASH_PARSED, payload })
export const auth0Login = () => ({ type: AUTH0_LOGIN })
export const auth0Logout = () => ({ type: AUTH0_LOGOUT })
export const auth0Profile = profile => ({
  type: AUTH0_PROFILE,
  payload: profile,
})
export const auth0ProfileError = err => ({
  type: AUTH0_PROFILE_ERROR,
  error: err,
})
export const auth0ShowLoginDialog = () => ({ type: AUTH0_SHOW_LOGIN_DIALOG })
export const auth0StartAuth = () => ({ type: AUTH0_START_AUTH })
