import * as React from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'

function CurrencyFormat({ inputRef = null, onChange = null, ...other }) {
  return (
    <NumberFormat
      {...other}
      ref={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value ? values.value : null,
          },
        })
      }}
      decimalScale={2}
      allowNegative={false}
      thousandSeparator
      prefix="$"
    />
  )
}

CurrencyFormat.propTypes = {
  inputRef: PropTypes.func,
  onChange: PropTypes.func,
}

export default CurrencyFormat
