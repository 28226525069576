import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'ramda'
import { withStyles } from '@material-ui/core/styles'

import ErrorIcon from '@material-ui/icons/Error'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import InfoIcon from '@material-ui/icons/Info'
import WarningIcon from '@material-ui/icons/Warning'

const styles = theme => ({
  icon: {
    fontSize: 20,
    marginRight: theme.spacing.unit * 2
  }
})

function Icon({ variant, classes }) {
  switch (variant) {
    case 'warning':
      return <WarningIcon className={classes.icon} />
    case 'error':
      return <ErrorIcon className={classes.icon} />
    case 'info':
      return <InfoIcon className={classes.icon} />
    case 'success':
    default:
      return <CheckCircleIcon className={classes.icon} />
  }
}

Icon.propTypes = {
  classes: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired
}

export default compose(withStyles(styles))(Icon)
