import gql from 'graphql-tag'
import { assumeRoleFragment, brandFragment } from '../graphql'

export const BrandsQueryDocument = gql`
  query Brands {
    brands {
      ...brand
    }
  }

  ${brandFragment}
`

export const BrandQueryDocument = gql`
  query BrandQueryDocument($id: ID!) {
    brand(id: $id) {
      ...brand
    }
  }

  ${brandFragment}
`

export const CreateBrandMutationDocument = gql`
  mutation CreateBrand($brand: BrandCreateParams!) {
    createBrand(brand: $brand) {
      brand {
        ...brand
      }
      assumeRole {
        ...assumeRole
      }
    }
  }

  ${brandFragment}
  ${assumeRoleFragment}
`

export const DeleteBrandMutationDocument = gql`
  mutation DeleteBrand($id: ID!) {
    deleteBrand(id: $id) {
      id
    }
  }
`

export const UpdateBrandMutationDocument = gql`
  mutation UpdateBrand($brand: BrandUpdateParams!, $id: ID!) {
    updateBrand(brand: $brand, id: $id) {
      brand {
        ...brand
      }
      assumeRole {
        ...assumeRole
      }
    }
  }

  ${brandFragment}
  ${assumeRoleFragment}
`
