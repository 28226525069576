import i18next from 'i18next'
import Backend from 'i18next-locize-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { reactI18nextModule } from 'react-i18next'

i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(reactI18nextModule)
  .init({
    debug: true,
    defaultNS: 'common',
    fallbackLng: 'en',
    keySeparator: false,
    ns: ['common'],
    nsSeparator: false,
    saveMissing: true,

    backend: {
      apiKey: process.env.REACT_APP_LOCIZE_API_KEY,
      projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID,
      referenceLng: 'en',
      version: 'latest',
    },

    react: {
      wait: true,
    },
  })

export default i18next
