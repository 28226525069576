import * as React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'ramda'
import { translate } from 'react-i18next'
import { withApollo } from 'react-apollo'
import CreateEntityButton from 'components/CreateEntityButton'
import CovaultTextField from 'components/CovaultTextField'
import CovaultFilePicker from 'components/CovaultFilePicker'
import CovaultAuth from 'components/CovaultAuth'
import { CampaignTemplateQueryDocument, CreateCreativeTemplateMutationDocument } from '../../graphql'
import withSnackbar from 'components/WithSnackbar'
import FacebookAd from 'components/FacebookAd'
import { removeUndefined, tripleMergeDeep } from 'utils/helpers'
import { uploadFile, uploadTemporaryFile } from './uploadFileHelper'

const DEFAULT_VALUES = {
  description: 'Description goes here',
  text: 'Text goes here',
  name: 'Name goes here',
  clickThroughUrl: '',
}

const INITIAL_STATE = {
  file: void 0,
  overlayFile: void 0,
  loading: void 0,
  location: void 0,
  overlayLocation: void 0,
  progress: void 0,
}

export class CreateFacebookCreativeTemplate extends React.Component {
  static propTypes = {
    brandId: PropTypes.string.isRequired,
    dealer: PropTypes.object.isRequired,
    salesProgram: PropTypes.shape({
      id: PropTypes.string.isRequired,
      campaignTemplate: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    snackbarContext: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }

  state = INITIAL_STATE

  render() {
    const {
      props: {
        brandId,
        dealer = {},
        salesProgram: {
          id: salesProgramId,
          campaignTemplate: { id: campaignTemplateId },
        },
        t,
      },
      state: { loading, location, overlayLocation, progress },
    } = this

    return (
      <CreateEntityButton
        confirmBeforeClose={true}
        mutation={CreateCreativeTemplateMutationDocument}
        buttonText={t('Upload Creative Template')}
        onCompleted={async ({ createCreativeTemplate: { assumeRole, overlayAssumeRole } }) => {
          await uploadFile.bind(this)(assumeRole, 'file', { errorMessage: 'Creative upload failed.' })
          await uploadFile.bind(this)(overlayAssumeRole, 'overlayFile', { errorMessage: 'Overlay upload failed.' })
          this.setState(INITIAL_STATE)
        }}
        initialVariables={[['campaignTemplateId', campaignTemplateId]]}
        refetchQueries={() => [
          {
            query: CampaignTemplateQueryDocument,
            variables: { brandId, salesProgramId, id: campaignTemplateId },
          },
        ]}
        progress={progress}
        loading={loading}
        submitHook={() => this.setState({ loading: true })}
        errorHook={() => this.setState({ loading: false })}
        livePreview={variables => (
          <FacebookAd
            dealer={dealer}
            creative={tripleMergeDeep(
              DEFAULT_VALUES,
              removeUndefined(variables.creativeTemplate),
              removeUndefined({ location, overlayLocation })
            )}
          />
        )}
      >
        {setValueFactory => (
          <React.Fragment>
            <CovaultFilePicker
              label={t('Image')}
              required
              setValue={(hasFile, file) => {
                if (!hasFile) {
                  setValueFactory('creativeTemplate.filename', true)(null)
                  this.setState({ file: void 0 })
                } else {
                  this.setState({ file })
                  setValueFactory('creativeTemplate.filename', true)(file.name)
                  uploadTemporaryFile.bind(this)(file, 'file', 'location', { errorMessage: 'Creative upload failed.' })
                }
              }}
            />
            <CovaultTextField
              id="text"
              defaultValue={DEFAULT_VALUES.text}
              label={t('Post Text')}
              setValue={setValueFactory('creativeTemplate.text')}
              InputProps={{ multiline: true, rowsMax: 6 }}
            />
            <CovaultTextField
              id="name"
              defaultValue={DEFAULT_VALUES.name}
              label={t('Headline')}
              setValue={setValueFactory('creativeTemplate.name')}
              InputProps={{ inputProps: { maxLength: 255 } }}
            />
            <CovaultTextField
              id="description"
              defaultValue={DEFAULT_VALUES.description}
              label={t('Newsfeed Description')}
              setValue={setValueFactory('creativeTemplate.description')}
              InputProps={{ inputProps: { maxLength: 255 } }}
            />
            <CovaultFilePicker
              label={t('Overlay Image')}
              setValue={(hasFile, overlayFile) => {
                if (!hasFile) {
                  setValueFactory('creativeTemplate.overlayFilename', true)(null)
                  this.setState({ overlayFile: void 0 })
                } else {
                  this.setState({ overlayFile })
                  setValueFactory('creativeTemplate.overlayFilename', true)(overlayFile.name)
                  uploadTemporaryFile.bind(this)(overlayFile, 'overlayFile', 'overlayLocation', {
                    errorMessage: 'Overlay upload failed.',
                  })
                }
              }}
            />
          </React.Fragment>
        )}
      </CreateEntityButton>
    )
  }
}

export default compose(
  withSnackbar,
  translate(),
  CovaultAuth({
    requiredParent: ['covault'],
    requiredPermission: 'sales_program:manage',
  }),
  withApollo
)(CreateFacebookCreativeTemplate)
