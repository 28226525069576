import * as React from 'react'
import { TableCell, withStyles } from '@material-ui/core'
import { TABLET_BREAKPOINT } from '../../constants'

// Table cells with thinner padding and collapsing on tablet screens
const StyledCollapsableTableCell = withStyles({
  root: {
    paddingRight: 18,

    [TABLET_BREAKPOINT]: {
      display: 'none',
    },
  },
})(TableCell)

const StyledUncollapsableTableCell = withStyles({
  root: {
    paddingRight: 18,

    [TABLET_BREAKPOINT]: {
      paddingRight: 6,
    },
  },
})(TableCell)

const StyledTableCell = ({ children, collapse = false }) =>
  collapse ? (
    <StyledCollapsableTableCell>{children}</StyledCollapsableTableCell>
  ) : (
    <StyledUncollapsableTableCell>{children}</StyledUncollapsableTableCell>
  )

export { StyledTableCell }
