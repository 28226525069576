import * as React from 'react'
import CampaignTemplateSection from 'components/CampaignTemplateSection'
import CTAButtonWrapper from 'components/CTAButtonWrapper'
import Loading from 'components/Loading'
import PropTypes from 'prop-types'
import SecondaryNavBar from 'components/SecondaryNavBar'
import Title from 'components/Title'
import ScrollWrapper from 'components/ScrollWrapper'
import withBrandId from 'components/WithBrandId'
import RunCampaignButton from 'campaign/RunCampaignButton'
import { auth } from 'components/CovaultAuth'
import { CampaignTemplateQueryDocument } from '../graphql'
import { compose } from 'ramda'
import { graphql } from 'react-apollo'
import { Trans } from 'react-i18next'
import { CreateFacebookCreativeTemplate, EditCampaignTemplate } from 'components/EntityButtons'
import {
  ArchiveCampaignTemplate,
  DeleteCampaignTemplate,
  PublishCampaignTemplate,
} from 'components/TransitionEntityButton'

export class CampaignTemplateContainer extends React.Component {
  static propTypes = {
    auth: PropTypes.object,
    data: PropTypes.object,
  }

  render() {
    const {
      data: { loading, error, brand },
    } = this.props

    if (loading) {
      return <Loading />
    }

    if (error) {
      throw error
    }

    const {
      salesProgram,
      salesProgram: {
        productLine: { id: productLineId },
        id: salesProgramId,
        campaignTemplate,
        campaignTemplate: { name, id: campaignTemplateId },
        startDate,
        endDate,
      },
    } = brand

    return (
      <React.Fragment>
        <SecondaryNavBar>
          <Title>
            <Trans>Campaign Template: {name}</Trans>
          </Title>
          <CTAButtonWrapper>
            <EditCampaignTemplate salesProgram={salesProgram} brandId={brand.id} />
            <CreateFacebookCreativeTemplate salesProgram={salesProgram} brandId={brand.id} dealer={{}} />
            <DeleteCampaignTemplate
              salesProgram={salesProgram}
              brandId={brand.id}
              campaignTemplate={campaignTemplate}
            />
            <ArchiveCampaignTemplate
              salesProgram={salesProgram}
              brandId={brand.id}
              campaignTemplate={campaignTemplate}
            />
            <PublishCampaignTemplate
              salesProgram={salesProgram}
              brandId={brand.id}
              campaignTemplate={campaignTemplate}
            />
            {Date.parse(startDate) < new Date() && Date.parse(endDate) > new Date() && (
              <RunCampaignButton
                buttonText="Run This Pre-Made Campaign"
                shortButtonText="Run This Campaign"
                campaignTemplateId={campaignTemplateId}
                salesProgramId={salesProgramId}
                productLineId={productLineId}
              />
            )}
          </CTAButtonWrapper>
        </SecondaryNavBar>

        <ScrollWrapper>
          <CampaignTemplateSection campaignTemplate={campaignTemplate} salesProgramId={salesProgramId} />
        </ScrollWrapper>
      </React.Fragment>
    )
  }
}

export default compose(
  auth({ requiredPermission: 'sales_program:view' }),
  withBrandId,
  graphql(CampaignTemplateQueryDocument, {
    options: ({
      match: {
        params: { id, salesProgramId },
      },
      brandId,
    }) => ({
      variables: { salesProgramId, brandId, id },
    }),
  })
)(CampaignTemplateContainer)
