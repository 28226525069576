import { createMuiTheme } from '@material-ui/core/styles'

export default createMuiTheme({
  palette: {
    primary: {
      light: '#7447ff',
      main: '#1f10f2',
      dark: '#09008C',
    },
    secondary: {
      light: '#1300be',
      main: '#6610f2',
      dark: 'a14dff',
    },
  },

  overrides: {
    MuiAppBar: {
      root: {
        background: 'linear-gradient(45deg, #1f10f2 30%, #6610f2 90%)',
      },
    },

    MuiDrawer: {
      docked: {
        backgroundColor: '#292838',
        color: '#fff',
      },
    },
  },
})
