import * as React from 'react'
import PropTypes from 'prop-types'
import CreativeCard from 'components/CreativeCard'
import { css } from 'emotion'

const creativeListStyle = css`
  display: flex;
  flex-flow: row wrap;
`

export default function CreativeList({ ad }) {
  const { creatives } = ad

  if (!creatives || creatives.length === 0) {
    return null
  }

  return (
    <div className={creativeListStyle}>
      {creatives.map(creative => (
        <CreativeCard ad={ad} creative={creative} key={creative.id} />
      ))}
    </div>
  )
}

CreativeList.propTypes = {
  ad: PropTypes.object.isRequired,
}
