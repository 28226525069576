export const AD_PATH = '/:brandId/ads/:id'
export const AD_REVIEW_PATH = '/:brandId/review_ad/:id'
export const ADS_PATH = '/:brandId/ads/'
export const BRAND_PATH = '/brands/:id'
export const BRANDS_PATH = '/brands'
export const DASHBOARD_PATH = '/:brandId/dashboard'
export const DEALER_PATH = '/dealers/:id'
export const DEALERS_PATH = '/dealers'
export const LOGIN_PATH = '/login'
export const ROOT_PATH = '/'
export const SALES_PROGRAM_PATH = '/:brandId/sales_programs/:id'
export const SALES_PROGRAMS_PATH = '/:brandId/sales_programs'
export const CAMPAIGN_TEMPLATES_PATH = '/:brandId/sales_programs/:salesProgramId/campaign_templates'
export const CAMPAIGN_TEMPLATE_PATH = '/:brandId/sales_programs/:salesProgramId/campaign_templates/:id'
export const CAMPAIGN_FROM_TEMPLATE_POSTFIX = '/new_with_template'
export const USER_SETTINGS_PATH = '/userSettings'
