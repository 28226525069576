import * as React from 'react'
import { css } from 'emotion'
import { connect } from 'react-redux'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { Trans } from 'react-i18next'
import { auth0Login } from 'actions/auth0'

const containerStyle = css`
  background-image: url(/assets/images/tiledbg.jpg);
  background-repeat: repeat;
  height: 100vh;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100vw;
`

export class Login extends React.Component {
  static propTypes = {
    authError: PropTypes.string,
    onLogin: PropTypes.func
  }

  handleTryAgain = () => {
    const { onLogin } = this.props

    onLogin()
  }

  render() {
    const { authError } = this.props

    return (
      <div className={containerStyle}>
        {authError && (
          <Dialog open>
            <DialogTitle>
              <Trans>Authentication Problem</Trans>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Trans>There was a problem logging in. Please try again.</Trans>
                <br />
                <br />
                <Trans>Server reported</Trans> {authError}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleTryAgain} color="primary" autoFocus>
                <Trans>Try again</Trans>
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  authError: get(state, 'auth.error.errorDescription')
})

const mapDispatchToProps = dispatch => ({
  onLogin: () => {
    dispatch(auth0Login())
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)
