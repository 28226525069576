import * as React from 'react'
import { graphql, ChildDataProps } from 'react-apollo'
import graphQLData from '../hoc/GraphQLData'
import { BrandDashboardQueryDocument } from '../graphql'
import DealerList from '../components/dashboard/brand/DealerList'
import { RootQueryType } from '../types'
import { auth } from '../components/CovaultAuth'
import withBrandId from '../components/WithBrandId'

function BrandDashboardContainer(props: ChildProps) {
  const {
    data: { brand },
  } = props

  const dealers = brand ? brand.dealers : []

  return <DealerList dealers={dealers} />
}

type InputProps = {
  brandId: string
}

type Response = RootQueryType

type Variables = {
  brandId: string
}

type ChildProps = ChildDataProps<InputProps, Response, Variables>

const withBrandDashboardQueryDocument = graphql<InputProps, Response, Variables, ChildProps>(
  BrandDashboardQueryDocument,
  {
    options: ({ brandId }) => ({
      variables: { brandId },
    }),
  }
)

export default auth({
  requiredParent: ['brand'],
})(withBrandId(withBrandDashboardQueryDocument(graphQLData(BrandDashboardContainer))))
