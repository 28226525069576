import * as React from 'react'
import { Table, TableBody, TableHead, TableRow } from '@material-ui/core'
import { CenteredPurpleText, StyledTableCell } from 'components/materialUI'
import Link from 'components/Link'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bumpCampaign } from 'actions/sessionState'
import ScrollWrapper from 'components/ScrollWrapper'
import { compose, equals } from 'ramda'
import { graphql } from 'react-apollo'
import { AdsQueryDocument } from '../graphql'
import { CHANNEL_NAMES } from 'constants/channels'
import { STATUS_NAMES } from 'constants/ads'
import { AD_PATH } from 'constants/paths'
import withBrandId from 'components/WithBrandId'
import { auth } from 'components/CovaultAuth'
import StatusIcon, { StatusIconTableCell } from 'components/StatusIcon'

const campaignClickHandler = ({ currentTarget, target }, fn, arg) => {
  if (equals(currentTarget, target)) {
    return
  }

  if (arg) {
    fn(arg)
  }
}

export function AdsTable({ ads, auth: { isBrand }, brandId, bumpCampaign, recentCampaigns }) {
  return (
    <ScrollWrapper>
      {recentCampaigns && recentCampaigns.length > 0 && (
        <React.Fragment>
          <CenteredPurpleText>Recently Viewed Campaigns</CenteredPurpleText>
          <Table>
            <TableHead>
              <TableRow>
                <StatusIconTableCell />
                <StatusIconTableCell>Name</StatusIconTableCell>
                {isBrand && <StyledTableCell>Dealer</StyledTableCell>}
                <StyledTableCell collapse>Sales Program</StyledTableCell>
                <StyledTableCell>Product Line</StyledTableCell>
                <StyledTableCell collapse>Channel</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {recentCampaigns.map((campaign, i) => {
                const {
                  name,
                  channel,
                  status,
                  id,
                  dealer: { name: dealerName },
                  salesProgram: {
                    name: salesProgramName,
                    productLine: { name: productLineName },
                  },
                } = campaign

                return (
                  <TableRow key={name}>
                    <StatusIcon status={status} />
                    <StatusIconTableCell
                      data-id="cell"
                      onClick={event => campaign && campaignClickHandler(event, bumpCampaign, campaign)}
                    >
                      <Link path={AD_PATH} params={{ id, brandId }}>
                        {channel === 'REQUEST' ? `Co-op Request #${id}` : name}
                      </Link>
                    </StatusIconTableCell>
                    {isBrand && <StyledTableCell>{dealerName}</StyledTableCell>}
                    <StyledTableCell collapse>{salesProgramName}</StyledTableCell>
                    <StyledTableCell>{productLineName}</StyledTableCell>
                    <StyledTableCell collapse>{CHANNEL_NAMES[channel]}</StyledTableCell>
                    <StyledTableCell>{STATUS_NAMES[status]}</StyledTableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
          <br />
          <br />
          <br />
          <CenteredPurpleText>All Campaigns</CenteredPurpleText>
        </React.Fragment>
      )}
      <Table>
        <TableHead>
          <TableRow>
            <StatusIconTableCell />
            <StatusIconTableCell>Name</StatusIconTableCell>
            {isBrand && <StyledTableCell>Dealer</StyledTableCell>}
            <StyledTableCell collapse>Sales Program</StyledTableCell>
            <StyledTableCell>Product Line</StyledTableCell>
            <StyledTableCell collapse>Channel</StyledTableCell>
            <StyledTableCell>Status</StyledTableCell>
          </TableRow>
        </TableHead>
        {ads && (
          <TableBody data-testid="results">
            {ads.map((campaign, i) => {
              const {
                name,
                channel,
                status,
                id,
                dealer: { name: dealerName },
                salesProgram: {
                  name: salesProgramName,
                  productLine: { name: productLineName },
                },
              } = campaign

              return (
                <TableRow key={id}>
                  <StatusIcon status={status} />
                  <StatusIconTableCell onClick={event => campaignClickHandler(event, bumpCampaign, campaign)}>
                    <Link path={AD_PATH} params={{ id, brandId }}>
                      {channel === 'REQUEST' ? `Co-op Request #${id}` : name}
                    </Link>
                  </StatusIconTableCell>
                  {isBrand && <StyledTableCell>{dealerName}</StyledTableCell>}
                  <StyledTableCell collapse>{salesProgramName}</StyledTableCell>
                  <StyledTableCell>{productLineName}</StyledTableCell>
                  <StyledTableCell collapse>{CHANNEL_NAMES[channel]}</StyledTableCell>
                  <StyledTableCell>{STATUS_NAMES[status]}</StyledTableCell>
                </TableRow>
              )
            })}
          </TableBody>
        )}
      </Table>
    </ScrollWrapper>
  )
}

AdsTable.propTypes = {
  ads: PropTypes.array.isRequired,
  auth: PropTypes.object.isRequired,
  brandId: PropTypes.string.isRequired,
}

const mapStateToProps = (state, { ads }) => {
  const recentCampaigns =
    state.sessionState &&
    state.sessionState.recentCampaigns &&
    state.sessionState.recentCampaigns.filter(c => ads.map(ad => ad.id).includes(c.id))

  return { recentCampaigns }
}

const mapDispatchToProps = dispatch => ({
  bumpCampaign: campaign => dispatch(bumpCampaign(campaign)),
})

export default compose(
  auth(),
  withBrandId,
  graphql(AdsQueryDocument, {
    skip: ({ brandId }) => !brandId,
    options: ({ brandId }) => ({ variables: { brandId } }),
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AdsTable)
