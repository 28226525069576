import * as React from 'react'
import PropTypes from 'prop-types'
import { compose, without } from 'ramda'
import { translate } from 'react-i18next'
import { graphql } from 'react-apollo'
import { BrandsQueryDocument, DealerQueryDocument } from '../graphql'
import SecondaryNavBar from 'components/SecondaryNavBar'
import Title from 'components/Title'
import CTAButtonWrapper from 'components/CTAButtonWrapper'
import { List, ListItem, ListItemText, Tabs, Tab } from '@material-ui/core'
import { TranslatedBrandsTable } from 'components/BrandsTable'
import { LinkToBrand } from 'components/CreateEntityButton'
import { EditDealer } from 'components/CreateEntityButton'
import { DeleteDealer } from 'components/TransitionEntityButton'
import Loading from 'components/Loading'
import ImageLoader from 'components/ImageLoader'
import ScrollWrapper from 'components/ScrollWrapper'
import { UserProfileContainerForDealer } from 'components/userProfile/UserProfileContainer'

export class DealerContainer extends React.Component {
  static propTypes = {
    DealerQueryDocument: PropTypes.object.isRequired,
    BrandsQueryDocument: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }

  state = {
    loading: true,
    tab: 'dealer',
  }

  changeTab = (event, tab) => {
    this.setState({ tab })
  }

  render() {
    const {
      state: { tab },
      props: { t },
    } = this
    const { loading: brandsLoading, error: brandsError } = this.props.BrandsQueryDocument
    const { loading: dealerLoading, error: dealerError } = this.props.DealerQueryDocument

    if (brandsLoading || dealerLoading) {
      return <Loading />
    }

    if (brandsError) {
      throw brandsError
    }
    if (dealerError) {
      throw dealerError
    }

    const { brands: allBrands } = this.props.BrandsQueryDocument
    const {
      dealer,
      dealer: {
        id,
        name,
        notificationEmail,
        address,
        city,
        state,
        zip,
        country,
        url,
        brands,
        logoLocation,
        isSingleParty,
        facebookSlug,
      },
    } = this.props.DealerQueryDocument

    return (
      <React.Fragment>
        <SecondaryNavBar
          tabs={
            <Tabs value={tab} onChange={this.changeTab}>
              <Tab value="dealer" label={t('Dealer Details')} />
              <Tab value="brands" label={t('Brands')} />
              <Tab value="users" label={t('User Profiles')} />
            </Tabs>
          }
        >
          <Title>{name}</Title>
          <CTAButtonWrapper>
            {tab === 'dealer' && <DeleteDealer dealer={dealer} />}
            {tab === 'dealer' && <EditDealer dealer={dealer} />}
            {tab === 'brands' && <LinkToBrand dealerId={id} brands={without(brands, allBrands)} />}
          </CTAButtonWrapper>
        </SecondaryNavBar>
        {tab === 'dealer' && (
          <List>
            <ListItem>{logoLocation && <ImageLoader height={300} width={300} location={logoLocation} />}</ListItem>
            <ListItem>
              <ListItemText primary={`Notification Email: ${notificationEmail}`} />
            </ListItem>
            <ListItem>
              <ListItemText primary={`Address: ${address}`} />
            </ListItem>
            <ListItem>
              <ListItemText primary={`City: ${city}`} />
            </ListItem>
            <ListItem>
              <ListItemText primary={`State: ${state}`} />
            </ListItem>
            <ListItem>
              <ListItemText primary={`Zip: ${zip}`} />
            </ListItem>
            <ListItem>
              <ListItemText primary={`Country: ${country}`} />
            </ListItem>
            <ListItem>
              <ListItemText primary={`Url: ${url ? url : ''}`} />
            </ListItem>
            <ListItem>
              <ListItemText primary={`Single-party: ${isSingleParty ? 'Yes' : 'No'}`} />
            </ListItem>
            <ListItem>
              <ListItemText primary={`Facebook Slug: ${facebookSlug ? facebookSlug : ''}`} />
            </ListItem>
          </List>
        )}
        {tab === 'brands' && <TranslatedBrandsTable brands={brands} dealer={dealer} showUnlinkButton={true} />}
        {tab === 'users' && (
          <ScrollWrapper>
            <UserProfileContainerForDealer dealerId={dealer.id} />
          </ScrollWrapper>
        )}
      </React.Fragment>
    )
  }
}

export default compose(
  translate(),
  graphql(BrandsQueryDocument, { name: 'BrandsQueryDocument' }),
  graphql(DealerQueryDocument, {
    name: 'DealerQueryDocument',
    options: props => ({
      variables: {
        id: props.match.params.id,
      },
    }),
  })
)(DealerContainer)
