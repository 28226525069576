import * as React from 'react'
import { auth } from 'components/CovaultAuth'
import { compose } from 'ramda'
import { hideUnlessAuthenticated } from 'utils/authWrappers'

const canUseDOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement)
const appID = process.env.REACT_APP_INTERCOM_APP_ID

/* Incorporates Intercom snippet into a React Component
 * taken largely from https://github.com/nhagen/react-intercom
 * Implements https://developers.intercom.com/installing-intercom/docs/basic-javascript
 */
export class Intercom extends React.Component {
  static displayName = 'Intercom'

  constructor(props) {
    super(props)

    const { auth } = props

    if (!appID || !canUseDOM) {
      return
    }

    if (!window.Intercom) {
      ;(function(w, d, id) {
        var ic = w.Intercom
        if (typeof ic === 'function') {
          ic('reattach_activator')
          ic('update', { ...this.payloadFromAuth(auth), app_id: appID })
        } else {
          var i = function() {
            i.c(arguments)
          }
          i.q = []
          i.c = function(args) {
            i.q.push(args)
          }
          w.Intercom = i
          function l() {
            var s = d.createElement('script')
            s.type = 'text/javascript'
            s.async = true
            s.src = 'https://widget.intercom.io/widget/' + id
            var x = d.getElementsByTagName('script')[0]
            x.parentNode.insertBefore(s, x)
          }
          if (w.attachEvent) {
            w.attachEvent('onload', l)
          } else {
            w.addEventListener('load', l, false)
          }
        }
      })(window, document, appID)
    }

    window.intercomSettings = { ...this.payloadFromAuth(auth), app_id: appID }

    if (window.Intercom) {
      window.Intercom('boot', this.payloadFromAuth(auth))
    }
  }

  componentWillReceiveProps(nextProps) {
    const { nextAuth } = nextProps
    const { auth } = this.props

    if (!appID || !canUseDOM || !auth || !nextAuth) return

    window.intercomSettings = { ...nextAuth, app_id: appID }

    if (window.Intercom) {
      if (this.loggedIn(auth) && !this.loggedIn(nextAuth)) {
        // Shutdown and boot each time the user logs out to clear conversations
        window.Intercom('shutdown')
        window.Intercom('boot', this.payloadFromAuth(nextAuth))
      } else {
        window.Intercom('update', this.payloadFromAuth(nextAuth))
      }
    }
  }

  payloadFromAuth(auth) {
    return {
      email: auth.profile.name,
      user_id: `${auth.parentType}-${auth.parentId}`,
      created_at: auth.profile.updated_at,
    }
  }

  shouldComponentUpdate() {
    return false
  }

  componentWillUnmount() {
    if (!canUseDOM || !window.Intercom) return false

    window.Intercom('shutdown')

    delete window.Intercom
    delete window.intercomSettings
  }

  loggedIn(props) {
    return props.email || props.user_id
  }

  render() {
    return false
  }
}

export default compose(
  hideUnlessAuthenticated,
  auth()
)(Intercom)
