import * as React from 'react'
import { campaignTemplateStep } from 'campaign/SelectCampaignTemplate'
import { creativeTemplates } from 'campaign/SelectCreativeTemplates'
import { productLineStep } from 'campaign/SelectProductLine'
import { campaignDetailsStep } from 'campaign/SelectCampaignDetails'
import TextShrinker from 'components/TextShrinker'

export const steps = [
  productLineStep,
  campaignTemplateStep,
  creativeTemplates,
  campaignDetailsStep,
  {
    title: () => '',
    content: () => '',
    action: () => '',
    stepperTitle: ({ isSinglePartyDealer }) => (
      <TextShrinker
        shortText={isSinglePartyDealer ? 'Create' : 'Submit'}
        fullText={isSinglePartyDealer ? 'Create Digital Template' : 'Submit For Approval'}
      />
    ),
    runStepComplete: (props, state) => false,
    campaignStepComplete: ({ status }) => status !== 'UNSUBMITTED',
  },
]
