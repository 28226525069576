import * as React from 'react'
import { withApollo } from 'react-apollo'
import { Subject } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import PropTypes from 'prop-types'
import { compose, find, propEq } from 'ramda'
import { TextField } from '@material-ui/core'
import { DatePicker } from 'material-ui-pickers'
import CurrencyFormat from 'components/CurrencyFormat'
import { withStyles } from '@material-ui/core/styles'
import { halfWidth } from 'campaign/SelectCampaignDetails'
import { AdQueryDocument, UpdateAdMutationDocument } from '../../graphql'

const styles = theme => ({
  budget: {
    flexGrow: 2,
  },
  date: {
    marginTop: 16,
    marginBottom: 8,
  },
  column: {
    margin: '24px 24px 104px',
    display: 'flex',
    flex: 1,
    justifyContent: 'space-around',
    flexDirection: 'column',
  },
})

export class SinglePartyCampaignDetails extends React.Component {
  static propTypes = {
    ad: PropTypes.object.isRequired,
  }

  constructor({ ad: { id, budgetInCents, channelQuestions, startDate, endDate } }) {
    super()

    this._budgetSubject$ = new Subject()
    this._budgetSubject$.subscribe(event => this.setState({ budgetInCents: event.target.value }))
    this._audienceSubject$ = new Subject()
    this._audienceSubject$.subscribe(event => this.setState({ audience: event.target.value }))
    this._startDateSubject$ = new Subject()
    this._startDateSubject$.subscribe(value => {
      this.setState({ startDate: value.format('YYYY-MM-DD') })
      this._updateAdFactory('startDate')({ target: { value: value.format('YYYY-MM-DD') } })
    })
    this._endDateSubject$ = new Subject()
    this._endDateSubject$.subscribe(value => {
      this.setState({ endDate: value.format('YYYY-MM-DD') })
      this._updateAdFactory('endDate')({ target: { value: value.format('YYYY-MM-DD') } })
    })
    this._budgetSubjectDebounced$ = this._budgetSubject$.pipe(debounceTime(1000))
    this._budgetSubjectDebounced$.subscribe(({ target: { value } }) =>
      this._updateAdFactory('budgetInCents')({ target: { value: value * 100 } })
    )
    this._audienceSubjectDebounced$ = this._audienceSubject$.pipe(debounceTime(1000))
    this._audienceSubjectDebounced$.subscribe(this._updateAdFactory('channelQuestions'))

    const audienceChannelQuestion = find(propEq('name', 'audience'), channelQuestions)

    this.state = {
      id,
      budgetInCents: budgetInCents && budgetInCents / 100,
      audience: { name: 'audience', value: audienceChannelQuestion.value },
      startDate,
      endDate,
    }
  }

  _updateAdFactory = key => ({ target: { value } }) =>
    this.props.client.mutate({
      mutation: UpdateAdMutationDocument,
      variables: {
        id: this.state.id,
        ad: { [key]: value, channelQuestions: this.state.audience },
      },
      refetchQueries: [{ query: AdQueryDocument, variables: { id: this.props.ad.id } }],
    })

  _handleBudgetChange = event => this._budgetSubject$.next(event)
  _handleAudienceChange = event => this._audienceSubject$.next(event)
  _handleStartDateChange = value => this._startDateSubject$.next(value)
  _handleEndDateChange = value => this._endDateSubject$.next(value)

  render() {
    const {
      props: {
        ad: {
          salesProgram: { startDate: salesProgramStartDate, endDate: salesProgramEndDate },
        },
        classes,
      },
      state: { startDate, endDate, audience, budgetInCents },
    } = this

    return (
      <div className={classes.column}>
        <TextField
          label="Budget"
          value={budgetInCents}
          onChange={({ target: { value } }) =>
            this._handleBudgetChange({ target: { value: Number(value) === 0 ? value : Number(value) } })
          }
          InputLabelProps={{ shrink: true }}
          InputProps={{ inputComponent: CurrencyFormat }}
          margin="normal"
          required
        />
        <TextField
          label="Audience"
          value={audience.value}
          onChange={({ target: { value } }) =>
            this._handleAudienceChange({ target: { value: [{ name: 'audience', value }] } })
          }
          InputLabelProps={{ shrink: true }}
          InputProps={{ inputProps: { maxLength: 255 } }}
          multiline
          margin="normal"
        />
        <div className={classes.date}>
          <DatePicker
            style={halfWidth}
            label="Start Date"
            value={startDate}
            onChange={this._handleStartDateChange}
            leftArrowIcon="<"
            rightArrowIcon=">"
            format="LL"
            emptyLabel="YYYY-MM-DD"
            minDate={salesProgramStartDate}
            maxDate={salesProgramEndDate}
            autoOk
            disablePast
          />
          <DatePicker
            style={halfWidth}
            label="End Date"
            value={endDate}
            onChange={this._handleEndDateChange}
            leftArrowIcon="<"
            rightArrowIcon=">"
            format="LL"
            emptyLabel="YYYY-MM-DD"
            minDate={salesProgramStartDate}
            maxDate={salesProgramEndDate}
            autoOk
            disablePast
          />
        </div>
      </div>
    )
  }
}

export default compose(
  withStyles(styles),
  withApollo
)(SinglePartyCampaignDetails)
