import * as React from 'react'
import graphQLData from 'hoc/GraphQLData'
import PropTypes from 'prop-types'
import OnboardingButton from './OnboardingButton'
import UserAuth from './UserAuth'
import Roles from './Roles'
import { compose } from 'ramda'
import { css } from 'emotion'
import { graphql } from 'react-apollo'
import { Card, CardContent } from '@material-ui/core'
import { BrandUserProfileQueryDocument, DealerUserProfileQueryDocument } from '../../graphql'

const cardStyle = css`
  margin: 7px;
`
const userProfileFlexStyle = css`
  display: flex;
  justify-content: space-between;
  div {
    margin: 5px;
  }
`

export function UserProfile(props) {
  const {
    parentId,
    data: {
      parent: {
        userProfile,
        userProfile: {
          id,
          name,
          auth: { email },
        },
      },
    },
  } = props

  return (
    <Card className={cardStyle}>
      <CardContent>
        <div className={userProfileFlexStyle}>
          <div>
            <div>{id}</div>
            <div>Name: {name}</div>
            <div>Email: {email}</div>
          </div>
          <UserAuth userProfile={userProfile} parentId={parentId} />
          <Roles userProfile={userProfile} parentId={parentId} />
        </div>
        <OnboardingButton userProfile={userProfile} parentId={parentId} />
      </CardContent>
    </Card>
  )
}

UserProfile.propTypes = {
  userProfile: PropTypes.object.isRequired,
  parentId: PropTypes.string.isRequired,
}

export const UserProfileForBrand = compose(
  graphql(BrandUserProfileQueryDocument, {
    options: ({ parentId, userProfile }) => ({ variables: { parentId, userProfileId: userProfile.id } }),
  }),
  graphQLData
)(UserProfile)

export const UserProfileForDealer = compose(
  graphql(DealerUserProfileQueryDocument, {
    options: ({ parentId, userProfile }) => ({ variables: { parentId, userProfileId: userProfile.id } }),
  }),
  graphQLData
)(UserProfile)
