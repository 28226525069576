import * as React from 'react'
import { Route } from 'react-router-dom'
import { Trans } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { CAMPAIGN_FROM_TEMPLATE_POSTFIX } from 'constants/paths'

export function CampaignFromTemplateNotification({ match, history }) {
  const onClick = () => history.push(match.url)

  return (
    <Route
      path={`${match.url}${CAMPAIGN_FROM_TEMPLATE_POSTFIX}`}
      render={() => (
        <Dialog open={true} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            <Trans>Creatives Provided From a Pre-Made Campaign</Trans>
          </DialogTitle>
          <DialogContent>
            <Trans>
              <Typography>
                Creatives have been automatically provided for you to use in your Campaign. You can customize or delete
                these creatives, or add your own creatives created by your team.
              </Typography>
            </Trans>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClick} color="primary" autoFocus>
              <Trans> Great! </Trans>
            </Button>
          </DialogActions>
        </Dialog>
      )}
    />
  )
}

export default withRouter(CampaignFromTemplateNotification)
