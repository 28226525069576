import * as React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'ramda'
import { graphql } from 'react-apollo'
import { Trans } from 'react-i18next'
import { Button, TextField } from '@material-ui/core'
import { BrandUserProfilesQueryDocument, CreateUserProfileAuthMutationDocument, DealerUserProfilesQueryDocument } from '../../graphql'

/*
 * Creates the user in Auth0 for the UserProfile. This user requires having an email set.
 */
export class UserAuth extends React.Component {
  static propTypes = {
    userProfile: PropTypes.object.isRequired,
    parentId: PropTypes.string,
    mutate: PropTypes.func.isRequired,
  }

  createUserAuth = () => {
    const { userProfile, mutate } = this.props
    const { email } = this.state

    mutate({
      variables: {
        userProfileId: userProfile.id,
        email,
      },
    })
  }

  setEmail = ({ target: { value: email } }) => this.setState({ email })

  state = { email: '' }

  render() {
    const {
      userProfile: {
        auth: { email },
      },
    } = this.props

    if (email) {
      return null
    }

    return (
      <div>
        <TextField
          label="Email"
          value={this.state.email}
          onChange={this.setEmail}
          InputProps={{ inputProps: { maxLength: 255 } }}
        />
        <Button
          color="primary"
          onClick={this.createUserAuth}
          data-testid="createUserAuth"
          disabled={!Boolean(this.state.email)}
        >
          <Trans>Create User Auth</Trans>
        </Button>
      </div>
    )
  }
}

export default compose(
  graphql(CreateUserProfileAuthMutationDocument, {
    options: ({ parentId }) => ({
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: BrandUserProfilesQueryDocument, variables: { brandId: parentId } },
        { query: DealerUserProfilesQueryDocument, variables: { brandId: parentId } },
      ],
    }),
  })
)(UserAuth)
