import * as React from 'react'
import { css } from 'emotion'
import StripeCard from 'components/StripeCard'
import { TranslatedParagraph } from 'components/materialUI'
import { Elements } from 'react-stripe-elements'
import StripeAddCard from 'components/StripeAddCard'

export default function StripeShowCard({ dealerId, stripeCards }) {
  if (!stripeCards || stripeCards.length === 0) {
    return (
      <React.Fragment>
        <TranslatedParagraph>You have not added any payment information yet.</TranslatedParagraph>
        <TranslatedParagraph>Click here to add a credit card.</TranslatedParagraph>
        <br />
        <Elements>
          <StripeAddCard />
        </Elements>
      </React.Fragment>
    )
  }

  const defaultCardId = stripeCards.find(stripeCard => stripeCard.defaultSource).id

  return (
    <div className={cardsWrapperStyle}>
      {stripeCards.map((card, i) => (
        <StripeCard key={card.id} card={card} isDefault={defaultCardId === card.id} dealerId={dealerId} />
      ))}
    </div>
  )
}

const cardsWrapperStyle = css`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`
