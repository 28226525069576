import * as React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'ramda'
import { translate } from 'react-i18next'
import CovaultAuth from 'components/CovaultAuth'
import { AdQueryDocument, CreateCreativeMutationDocument } from '../graphql'
import { startManagedUpload } from 'utils/upload'
import withSnackbar from 'components/WithSnackbar'
import { Mutation } from 'react-apollo'
import { Button, CircularProgress, Input, withStyles } from '@material-ui/core'
import { css } from 'emotion'

const inputTextStyle = css`
  cursor: pointer;
`

const StyledCircularProgress = withStyles({
  root: {
    color: 'white',
    height: '100%',
  },
})(CircularProgress)

const StyledInput = withStyles({
  root: {
    opacity: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  input: {
    cursor: 'pointer',
  },
})(Input)

const StyledButton = withStyles({
  root: {
    width: 120,
  },
})(Button)

export class UploadFileButton extends React.Component {
  static propTypes = {
    adId: PropTypes.string.isRequired,
    snackbarContext: PropTypes.object.isRequired,
  }

  state = {
    file: void 0,
    loading: void 0,
    data: void 0,
  }

  handleChange = () => this.setState({ file: document.querySelector('#upload-file-button').files[0] })

  uploadFile = async assumeRole => {
    this.setState({ loading: true })

    if (!this.state.file) {
      this.setState({ loading: false })
    }

    const managedUpload = startManagedUpload(this.state.file, assumeRole)
    managedUpload.on('httpUploadProgress', ({ loaded, total }) =>
      this.setState({ progress: total > 0 ? loaded / total : 0 })
    )
    await managedUpload.promise().then(
      () => {
        this.setState({ loading: false })
        this.props.snackbarContext.successMessage('File uploaded successfully', '')
      },
      error => {
        this.props.snackbarContext.errorMessage('Uploading Creative Failed', '')
      }
    )
  }

  render() {
    const { snackbarContext, adId } = this.props

    return (
      <StyledButton variant="contained" color="primary">
        <Mutation
          mutation={CreateCreativeMutationDocument}
          onCompleted={({ createCreative: { assumeRole } }) => this.uploadFile(assumeRole)}
          refetchQueries={() => [{ query: AdQueryDocument, variables: { id: adId } }]}
        >
          {(mutate, { data, error, loading }) => {
            if (loading || this.state.loading) {
              return <StyledCircularProgress size={20} />
            }

            if (data && data !== this.state.data) {
              this.uploadFile(data.createCreative.assumeRole)

              this.setState({ data })

              return <StyledCircularProgress size={20} />
            }

            if (error) {
              snackbarContext.errorMessage('Uploading Creative Failed', '')
            }

            return (
              <React.Fragment>
                <span className={inputTextStyle}>Upload File</span>
                <StyledInput
                  disableUnderline
                  id="upload-file-button"
                  name="file"
                  onChange={() => {
                    this.handleChange()
                    mutate({
                      variables: {
                        adId,
                        creative: { filename: document.querySelector('#upload-file-button').files[0].name },
                      },
                    })
                  }}
                  type="file"
                />
              </React.Fragment>
            )
          }}
        </Mutation>
      </StyledButton>
    )
  }
}

export default compose(
  withSnackbar,
  translate(),
  CovaultAuth({ requiredParent: 'dealer' })
)(UploadFileButton)
