/* This works to display Assets and Creatives
 * For display of Facebook Creatives, use FacebookPreviewCard.jsx
 */

import * as React from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog as Dialogue, // how did a typo make it in to this library?
  DialogActions as DialogueActions,
  DialogContent as DialogueContent,
  DialogTitle as DialogueTitle,
  Typography,
  withStyles,
} from '@material-ui/core'
import ImageLoader from 'components/ImageLoader'
import { DeleteCreative } from 'components/TransitionEntityButton'
import DownloadLocationButton from 'components/DownloadLocationButton'
import { css } from 'emotion'

const imageHeight = 165
const imageWidth = 236

export default class CreativeCard extends React.Component {
  static propTypes = {
    ad: PropTypes.object,
    creative: PropTypes.object,
  }

  state = {
    fullscreenIsOpen: false,
  }

  _toggleDialogue = () => this.setState({ fullscreenIsOpen: !this.state.fullscreenIsOpen })

  render() {
    const {
      props: {
        ad,
        creative,
        creative: { clickThroughUrl, description, location, name, text },
      },
      state: { fullscreenIsOpen },
    } = this

    return (
      <React.Fragment>
        <Card className={cardStyles} style={{ width: imageWidth }}>
          <CardHeader
            title={
              <Typography title={name} variant="body2">
                {name}
              </Typography>
            }
          />

          <div
            onClick={this._toggleDialogue}
            className={imageContainerStyles}
            style={{ minHeight: imageHeight, minWidth: imageWidth }}
          >
            <ImageLoader location={location} height={imageHeight} width={imageWidth} />
          </div>

          <CardContent>
            <Typography gutterBottom>{description}</Typography>
            <Typography gutterBottom>{text}</Typography>
            <Typography>
              <a href={clickThroughUrl}>{clickThroughUrl}</a>
            </Typography>
          </CardContent>
          <CardActions>
            <DeleteCreative creative={creative} ad={ad} />
            <DownloadLocationButton location={location} />
          </CardActions>
        </Card>

        <MaxWidthDialogue onClose={this._toggleDialogue} open={fullscreenIsOpen}>
          <DialogueTitle>{name}</DialogueTitle>
          <DialogueContent>
            <div className={`${imageContainerStyles} ${fsImageContainerStyles}`}>
              <ImageLoader
                location={location}
                height={7 * Math.floor(window.innerHeight / 8)}
                width={7 * Math.floor(Math.min(window.innerWidth, 1086) / 8)}
              />
            </div>
            <Typography gutterBottom>{description}</Typography>
            <Typography gutterBottom>{text}</Typography>
            <Typography>
              <a href={clickThroughUrl}>{clickThroughUrl}</a>
            </Typography>
          </DialogueContent>
          <LeftAlignedDialogueActions>
            <DeleteCreative creative={creative} ad={ad} />
            <DownloadLocationButton location={location} />
          </LeftAlignedDialogueActions>
        </MaxWidthDialogue>
      </React.Fragment>
    )
  }
}

const cardStyles = css`
  cursor: pointer;
  margin: 10px 0 0 10px;
`
const imageContainerStyles = css`
  align-items: center;
  display: flex;
  justify-content: center;
`
const fsImageContainerStyles = css`
  margin-bottom: 20px;
  min-height: 200px;
  min-width: 300px;
`

const MaxWidthDialogue = withStyles(() => ({
  paper: {
    maxWidth: 1000,
    margin: 0,
  },
}))(Dialogue)

const LeftAlignedDialogueActions = withStyles(() => ({
  root: {
    justifyContent: 'flex-start',
    padding: '0 24px 24px',
    margin: 0,
  },
}))(DialogueActions)
