import * as React from 'react'
import { CovaultAuth } from 'components/CovaultAuth'
import { css } from 'emotion'
import RunCampaignButton from '../campaign/RunCampaignButton'
import { Card, CardContent, Typography } from '@material-ui/core'
import isSinglePartyDealer from 'hoc/IsSinglePartyDealer'

const card = css`
  min-width: 275px;
  max-width: 475px;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 10px;
`
const center = css`
  text-align: center;
  margin-top: 24px;
`

function RunCampaignDialog({ isSinglePartyDealer, isSalesProgramAvailable }) {
  return (
    <CovaultAuth requiredParent="dealer">
      <Card className={card}>
        {isSalesProgramAvailable ? (
          <CardContent>
            <Typography variant="headline" gutterBottom>
              Get Started by Creating a {isSinglePartyDealer ? 'Digital Template or Print Media' : 'Campaign'}
            </Typography>
            {!isSinglePartyDealer && (
              <Typography variant="subheading" gutterBottom>
                This easy, step-by-step process will guide you through getting your Campaign submitted, approved and
                then published.
              </Typography>
            )}
            <div className={center}>
              <RunCampaignButton />
            </div>
          </CardContent>
        ) : (
          <CardContent>
            <Typography variant="headline" gutterBottom>
              No Sales Programs Available
            </Typography>
            <Typography variant="subheading" gutterBottom>
              In order to create a campaign, you need to have a product with a valid sales program. Contact Covault or your brand to ensure that they have sales programs available for their products.
            </Typography>
          </CardContent>
        )}
      </Card>
    </CovaultAuth>
  )
}

export default isSinglePartyDealer(RunCampaignDialog)
