import 'rxjs/add/operator/filter'
import 'rxjs/add/operator/mapTo'
import 'rxjs/add/operator/map'
import { LOCATION_CHANGE, replace } from 'react-router-redux'
import { get } from 'lodash'
import {
  AUTH0_AUTHORIZATION_ERROR,
  AUTH0_CHECK_EXPIRY,
  AUTH0_LOGIN,
  AUTH0_LOGOUT,
  AUTH0_SHOW_LOGIN_DIALOG,
} from 'actions/auth0'

const triggerLoginOnPageLoad = (action$, store) =>
  action$
    .ofType(LOCATION_CHANGE)
    .filter(() => store.getState().router.location.pathname === '/login')
    .filter(() => !get(store.getState(), 'auth.isAuthenticated'))
    .mapTo({ type: AUTH0_LOGIN })

const redirectToLoginOnAuthorizationError = (action$, store) =>
  action$
    .ofType(AUTH0_AUTHORIZATION_ERROR)
    .filter(() => store.getState().router.location.pathname === '/login/callback')
    .map(() => replace('/login'))

const redirectToLoginOnLogout = (action$, store) =>
  action$
    .ofType(AUTH0_LOGOUT)
    .map(() => replace('/login'))
    .mapTo({ type: AUTH0_LOGIN })

const login = (action$, store) => action$.ofType(AUTH0_LOGIN).mapTo({ type: AUTH0_SHOW_LOGIN_DIALOG })

const checkAuthOnNavigation = (action$, store) => action$.ofType(LOCATION_CHANGE).mapTo({ type: AUTH0_CHECK_EXPIRY })

export default [
  checkAuthOnNavigation,
  login,
  redirectToLoginOnAuthorizationError,
  redirectToLoginOnLogout,
  triggerLoginOnPageLoad,
]
