/* CTAButtonWrapper.jsx
 * Wraps multiple call-to-action buttons to pad and right-align them.
 * Typically, CTAButtonWrapper will wrap multiple instances of CreateEntityButton.
 *
 * Created by Louis Ritchie on May 4th, 2018
 */

import * as React from 'react'
import PropTypes from 'prop-types'
import { css } from 'emotion'

const style = css`
  > * {
    margin-top: 4px !important;

    &:not(:last-child) {
      margin-right: 14px;
    }
  }
`

const CTAButtonWrapper = ({ children }) => {
  return <div className={style}>{children}</div>
}

CTAButtonWrapper.propTypes = {
  children: PropTypes.array.isRequired,
}

export default CTAButtonWrapper
