import * as React from 'react'
import covaultAuth from 'components/CovaultAuth'
import CovaultTextField from 'components/CovaultTextField'
import CreateEntityButton from 'components/CreateEntityButton'
import PropTypes from 'prop-types'
import withSnackbar from 'components/WithSnackbar'
import { CAMPAIGN_TEMPLATE_PATH } from 'constants/paths'
import { compose } from 'ramda'
import { connect } from 'react-redux'
import { generatePath } from 'react-router'
import { routerActions } from 'react-router-redux'
import { translate } from 'react-i18next'
import { SalesProgramQueryDocument, CreateCampaignTemplateMutationDocument } from '../../graphql'

export class CreateCampaignTemplate extends React.Component {
  static propTypes = {
    brandId: PropTypes.string.isRequired,
    replace: PropTypes.func,
    salesProgram: PropTypes.object.isRequired,
    snackbarContext: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }

  state = {
    loading: false,
  }

  render() {
    const {
      props: {
        brandId,
        replace,
        salesProgram: { id: salesProgramId },
        snackbarContext,
        t,
      },
      state: { loading },
    } = this

    return (
      <CreateEntityButton
        mutation={CreateCampaignTemplateMutationDocument}
        buttonText={t('Create Campaign Template')}
        initialVariables={[['salesProgramId', salesProgramId]]}
        refetchQueries={() => [
          {
            query: SalesProgramQueryDocument,
            variables: { salesProgramId, brandId },
          },
        ]}
        onCompleted={({ createCampaignTemplate: { id } }) => {
          replace(
            generatePath(CAMPAIGN_TEMPLATE_PATH, {
              salesProgramId,
              brandId,
              id,
            })
          )
          snackbarContext.successMessage('Campaign Template Created', '')
        }}
        loading={loading}
        submitHook={() => this.setState({ loading: true })}
        resetOnOpen
      >
        {setValueFactory => (
          <React.Fragment>
            <CovaultTextField
              id="name"
              setValue={setValueFactory('campaignTemplate.name')}
              defaultValue=""
              label={t('Template Name')}
            />
            <CovaultTextField
              id="description"
              setValue={setValueFactory('campaignTemplate.description')}
              defaultValue=""
              label={t('Template Description')}
            />
            <CovaultTextField
              id="defaultAudience"
              setValue={setValueFactory('campaignTemplate.defaultAudience')}
              defaultValue=""
              label={t('Default Audience')}
            />
            <CovaultTextField
              id="channel"
              setValue={setValueFactory('campaignTemplate.channel')}
              defaultValue="FACEBOOK"
              label={t('Template Channel')}
              disabled
            />
          </React.Fragment>
        )}
      </CreateEntityButton>
    )
  }
}

export default compose(
  withSnackbar,
  translate(),
  connect(
    null,
    { replace: routerActions.replace }
  ),
  covaultAuth({
    requiredParent: ['covault'],
    requiredPermission: 'sales_program:manage',
  })
)(CreateCampaignTemplate)
