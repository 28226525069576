import { complement, isNil, mergeDeepRight, pickBy } from 'ramda'

export const removeUndefined = pickBy(complement(isNil))

export const tripleMergeDeep = (obj1, obj2, obj3) => mergeDeepRight(mergeDeepRight(obj1, obj2), obj3)

export const hasDatePassed = endDate => endDate < new Date()

export const isSalesProgramCurrent = salesProgram =>
  salesProgram.endDate > new Date() && salesProgram.startDate < new Date()
