import * as React from 'react'
import { Button, TextField, Typography } from '@material-ui/core'
import { DatePicker } from 'material-ui-pickers'
import { Trans } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import CurrencyFormat from 'components/CurrencyFormat'
import PropTypes from 'prop-types'
import { getProductLine, getCampaignTemplate } from 'campaign/Utils'
import TextShrinker from 'components/TextShrinker'
import { titleContainerStyle, actionContainerStyle } from './SelectCreativeTemplates'
import { TABLET_BREAKPOINT } from '../constants'

export const styles = theme => ({
  budget: {
    flexGrow: 2,
  },
  date: {
    marginTop: 16,
    marginBottom: 8,
  },
  column: {
    marginLeft: 24,
    width: '80%',
    display: 'flex',
    flexWrap: 'no-wrap',
    justifyContent: 'space-around',
    flexDirection: 'column',

    [TABLET_BREAKPOINT]: {
      width: '95%',
    },
  },
})
export const halfWidth = {
  width: '50%',
}

export function selectCampaignDetailsContent(props, state) {
  const {
    data: {
      brand: { productLines },
    },
  } = props

  const { productLineId, campaignTemplateId } = state

  const productLine = getProductLine(productLines, productLineId)
  const campaignTemplate = getCampaignTemplate(productLine, campaignTemplateId)

  const {
    budgetInCents = '',
    setBudgetInCents,
    audience = campaignTemplate.defaultAudience,
    setAudience,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    salesProgramEndDate,
  } = state

  return (
    <SelectCampaignDetails
      budgetInCents={budgetInCents}
      setBudgetInCents={setBudgetInCents}
      audience={audience}
      setAudience={setAudience}
      startDate={startDate}
      setStartDate={setStartDate}
      endDate={endDate}
      setEndDate={setEndDate}
      salesProgramEndDate={salesProgramEndDate}
    />
  )
}

export function selectCampaignDetailsAction(props, state) {
  const { createCampaign, budgetInCents } = state

  return (
    <div>
      <Button
        color="primary"
        variant="contained"
        onClick={createCampaign}
        data-testid={'selectCampaignDetailsAction'}
        disabled={!Boolean(budgetInCents)}
      >
        <Trans>Save</Trans>
      </Button>
    </div>
  )
}

export function BareSelectCampaignDetails(props) {
  const {
    budgetInCents,
    setBudgetInCents,
    setAudience,
    audience,
    classes,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    salesProgramEndDate,
  } = props

  const handleBudgetChange = event => {
    setBudgetInCents(event.target.value)
  }

  const handleAudienceChange = event => {
    setAudience(event.target.value)
  }

  const handleStartDateChange = value => {
    const formatedValue = value.format('YYYY-MM-DD')
    setStartDate(formatedValue)
  }

  const handleEndDateChange = value => {
    const formatedValue = value.format('YYYY-MM-DD')
    setEndDate(formatedValue)
  }

  return (
    <React.Fragment>
      <div className={classes.column}>
        <TextField
          label="Budget"
          value={budgetInCents}
          onChange={handleBudgetChange}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputComponent: CurrencyFormat,
            inputProps: {
              maxLength: 255,
              'data-testid': 'budget-text-field',
            },
          }}
          margin="normal"
          required
        />
        <TextField
          label="Audience"
          value={audience}
          onChange={handleAudienceChange}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{ inputProps: { maxLength: 255 } }}
          multiline
          margin="normal"
        />
        <div className={classes.date}>
          <DatePicker
            style={halfWidth}
            label="Start Date"
            value={startDate}
            onChange={handleStartDateChange}
            leftArrowIcon={'<'}
            rightArrowIcon={'>'}
            format={'LL'}
            emptyLabel="YYYY-MM-DD"
            maxDate={endDate}
            autoOk
            disablePast
          />
          <DatePicker
            style={halfWidth}
            label="End Date"
            value={endDate}
            onChange={handleEndDateChange}
            leftArrowIcon={'<'}
            rightArrowIcon={'>'}
            format={'LL'}
            emptyLabel="YYYY-MM-DD"
            minDate={startDate}
            maxDate={salesProgramEndDate}
            autoOk
            disablePast
          />
        </div>
      </div>
    </React.Fragment>
  )
}

BareSelectCampaignDetails.propTypes = {
  budget: PropTypes.string,
  setBudgetInCents: PropTypes.func.isRequired,
  audience: PropTypes.string,
  setAudience: PropTypes.func.isRequired,
  startDate: PropTypes.string,
  setStartDate: PropTypes.func.isRequired,
  endDate: PropTypes.string,
  setEndDate: PropTypes.func.isRequired,
  salesProgramEndDate: PropTypes.string,
}

export const SelectCampaignDetails = withStyles(styles)(BareSelectCampaignDetails)

export const campaignDetailsStep = {
  title: (props, state) => (
    <div className={titleContainerStyle}>
      <Typography variant="title" gutterBottom data-testid="selectCampaignDetails-title">
        <Trans>Set {props.isSinglePartyDealer ? 'Digital Template' : 'Campaign'} Details</Trans>
      </Typography>
      <div className={actionContainerStyle}>{selectCampaignDetailsAction(props, state)}</div>
    </div>
  ),
  stepperTitle: ({ isSinglePartyDealer }) => (
    <TextShrinker
      fullText={isSinglePartyDealer ? 'Set Digital Template Details' : 'Set Campaign Details'}
      shortText="Details"
    />
  ),
  content: selectCampaignDetailsContent,
  runStepComplete: (props, { campaignDetailsSet }) => campaignDetailsSet,
  campaignStepComplete: ({ budgetInCents }) => Boolean(budgetInCents),
  undoStep: () => ({ campaignDetailsSet: void 0, budgetInCents: void 0 }),
}
