import * as React from 'react'
import { CovaultAuth } from 'components/CovaultAuth'
import { css } from 'emotion'
import { EditAd } from 'components/EntityButtons'
import { Trans } from 'react-i18next'
import { propEq, find } from 'ramda'
import ChannelQuestions from 'components/ChannelQuestions'
import Moment from 'react-moment'
import PropTypes from 'prop-types'
import CurrencyFormat from 'components/CurrencyFormat'
import Link from 'components/Link'
import { CardContent, Typography, CardHeader } from '@material-ui/core'
import { BREAKPOINTS, CHANNEL_NAMES, SALES_PROGRAM_PATH, STATUS_NAMES } from '../constants'
import IsSinglePartyDealer from 'hoc/IsSinglePartyDealer'

function rejectionReason(status, statusLogs) {
  const mostRecentRejection = find(propEq('status', 'REJECTED'))(statusLogs)

  if (status !== 'REJECTED' || !mostRecentRejection) {
    return ''
  }

  return (
    <Typography component="p" gutterBottom>
      <Trans>Rejection Reason: </Trans> <strong data-testid="rejection-reason">{mostRecentRejection.notes}</strong>
    </Typography>
  )
}

const cardStyle = css`
  flex: 1;
  padding: 24px;
  min-width: 250px;

  @media (min-width: ${BREAKPOINTS.tablet}) {
    border-right: 1px solid #ddd;
  }

  @media (max-width: ${BREAKPOINTS.phone}) {
    padding: 0;
  }
`
const titleWrapper = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const buttonWrapper = css`
  height: 36px;
`

function CampaignDetails(props) {
  const {
    brandId,
    dealer,
    dealer: { name: dealerName },
    campaign,
    campaign: {
      budgetInCents,
      channel,
      channelQuestions,
      endDate,
      startDate,
      status,
      statusLogs,
      salesProgram: { id: salesProgramId, name: salesProgramName },
    },
    isSinglePartyDealer,
  } = props

  return (
    <div className={cardStyle}>
      <div className={titleWrapper}>
        <CardHeader
          title={isSinglePartyDealer ? 'Digital Template Details' : 'Campaign Details'}
          data-testid="campaignDetails"
        />
        <div className={buttonWrapper}>
          <EditAd ad={campaign} dealer={dealer} />
        </div>
      </div>
      <CardContent>
        <CovaultAuth requiredParent="brand">
          <Typography component="p" gutterBottom data-testid="campaignDetails-dealer">
            <Trans>Dealer: </Trans>
            {dealerName}
          </Typography>
        </CovaultAuth>
        <Typography component="p" gutterBottom>
          <Trans>{isSinglePartyDealer ? 'Digital Template' : 'Campaign'} status: </Trans>
          <strong data-testid="status">{STATUS_NAMES[status]}</strong>
        </Typography>
        {rejectionReason(status, statusLogs)}
        <Typography component="p" gutterBottom>
          <Trans>Channel: </Trans>
          {CHANNEL_NAMES[channel]}
        </Typography>
        <Typography component="p" gutterBottom>
          <Trans>Sales Program: </Trans>
          <Link path={SALES_PROGRAM_PATH} params={{ brandId, id: salesProgramId }}>
            {salesProgramName}
          </Link>
        </Typography>

        <Typography component="p" gutterBottom>
          <Trans>Run date: </Trans>
          <Moment format="LL">{startDate}</Moment> to <Moment format="LL">{endDate}</Moment>
        </Typography>
        <Typography component="p" gutterBottom color={budgetInCents ? 'inherit' : 'error'}>
          <Trans>Budget: </Trans> <CurrencyFormat displayType="text" fixedDecimalScale value={budgetInCents / 100} />
        </Typography>
        <ChannelQuestions channelQuestions={channelQuestions} />
      </CardContent>
    </div>
  )
}

CampaignDetails.propTypes = {
  brandId: PropTypes.string.isRequired,
  campaign: PropTypes.object.isRequired,
  dealer: PropTypes.object.isRequired,
}

export default IsSinglePartyDealer(CampaignDetails)
