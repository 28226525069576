import * as React from 'react'
import PropTypes from 'prop-types'
import { css } from 'emotion'
import FacebookCard from 'components/FacebookCard'
import { auth } from 'components/CovaultAuth'
import { contains, path } from 'ramda'

const columnWrapperStyle = css`
  display: flex;
  flex-wrap: wrap;
  max-width: 1527px;
`
const columnStyle = css`
  margin-left: auto;
  margin-right: auto;
  width: 482px;
`

class FacebookCreativeListBlank extends React.Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    dealer: PropTypes.object.isRequired,
    isTemplate: PropTypes.bool.isRequired,
    getCreativesFromProps: PropTypes.func.isRequired,
    isEditableTest: PropTypes.func.isRequired,
    getPropsForFacebookCard: PropTypes.func.isRequired,
  }

  render() {
    const creatives = this.props.getCreativesFromProps(this.props)
    const isEditable = this.props.isEditableTest(this.props)
    const fbCardProps = this.props.getPropsForFacebookCard(this.props)

    if (!creatives || creatives.length === 0) {
      return null
    }

    return (
      <div className={columnWrapperStyle}>
        <div className={columnStyle}>
          {creatives.filter((_, i) => i % 3 === 0).map((creative, i) => (
            <FacebookCard
              creative={creative}
              dealer={this.props.dealer}
              editable={isEditable}
              isTemplate={this.props.isTemplate}
              key={creative.id}
              {...fbCardProps}
            />
          ))}
        </div>
        {creatives.length > 1 && (
          <React.Fragment>
            <div className={columnStyle}>
              {creatives.filter((_, i) => i % 3 === 1).map((creative, i) => (
                <FacebookCard
                  creative={creative}
                  dealer={this.props.dealer}
                  editable={isEditable}
                  isTemplate={this.props.isTemplate}
                  key={creative.id}
                  {...fbCardProps}
                />
              ))}
            </div>
            <div className={columnStyle}>
              {creatives.filter((_, i) => i % 3 === 2).map((creative, i) => (
                <FacebookCard
                  creative={creative}
                  dealer={this.props.dealer}
                  editable={isEditable}
                  isTemplate={this.props.isTemplate}
                  key={creative.id}
                  {...fbCardProps}
                />
              ))}
            </div>
          </React.Fragment>
        )}
      </div>
    )
  }
}

export const FacebookCreativeTemplateList = auth()(props => (
  <FacebookCreativeListBlank
    isTemplate={true}
    getCreativesFromProps={path(['campaignTemplate', 'creativeTemplates'])}
    isEditableTest={props => props.auth.isCovault}
    getPropsForFacebookCard={props => ({
      salesProgramId: props.salesProgramId,
      campaign: props.campaignTemplate,
    })}
    {...props}
  />
))

export const FacebookCreativeList = auth()(props => (
  <FacebookCreativeListBlank
    isTemplate={false}
    getCreativesFromProps={path(['ad', 'creatives'])}
    isEditableTest={props => props.auth.isDealer && contains(props.ad.status, ['UNSUBMITTED', 'REJECTED'])}
    getPropsForFacebookCard={props => ({ campaign: props.ad })}
    {...props}
  />
))

export default FacebookCreativeList
