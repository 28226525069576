import * as React from 'react'
import { Trans } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import { contains } from 'ramda'
import FacebookAd from 'components/FacebookAd'
import PropTypes from 'prop-types'
import { FormControlLabel, Button, Checkbox, Typography } from '@material-ui/core'
import { getProductLine, getCampaignTemplate } from 'campaign/Utils'
import TextShrinker from 'components/TextShrinker'
import { css } from 'emotion'
import { TABLET_BREAKPOINT, LAPTOP_BREAKPOINT, LAPTOP_BREAKPOINT_INVERTED } from '../constants'

export const titleContainerStyle = css`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`
export const actionContainerStyle = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 48px;
  margin-right: 24px;
  width: 240px;

  ${TABLET_BREAKPOINT} {
    margin-left: 24px;
    margin-right: 16px;
  }
`

export const styles = theme => ({
  cardContainer: {
    transition: ['boxShadow'],
    transitionDuration: 300,
    marginTop: 10,
    marginBottom: 24,
    width: '472px',
    position: 'relative',
    '&:hover $overlay': {
      backgroundColor: 'black',
      opacity: 0.5,
      transition: ['opacity'],
      transitionDuration: 300,
    },
    '&:hover $select': {
      opacity: 1,
    },
    [LAPTOP_BREAKPOINT]: {
      marginLeft: 24,
    },
    [LAPTOP_BREAKPOINT_INVERTED]: {
      '&:nth-child(odd)': {
        marginLeft: 24,
      },

      '&:nth-child(even)': {
        marginRight: 24,
      },
    },
  },
  overlay: {
    borderRadius: 4,
    position: 'absolute',
    opacity: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    transition: ['opacity'],
    transitionDuration: 300,
  },
  select: {
    borderRadius: 5,
    padding: '5px 10px 5px 10px',
    position: 'absolute',
    bottom: '50%',
    left: '50%',
    transform: 'translate(-50%, 50%)',
    opacity: 0,
    backgroundColor: 'white',
    transition: ['opacity'],
    transitionDuration: 300,
    cursor: 'pointer',
  },
})

export function SelectCreativeTemplatesContent(props, state) {
  const {
    data: {
      brand: { productLines },
      dealers,
    },
  } = props
  const dealer = dealers[0]

  const { productLineId, campaignTemplateId, creativeTemplateIds, toggleCreativeTemplateId } = state

  const productLine = getProductLine(productLines, productLineId)
  const campaignTemplate = getCampaignTemplate(productLine, campaignTemplateId)
  return (
    <SelectCreativeTemplates
      campaignTemplate={campaignTemplate}
      creativeTemplateIds={creativeTemplateIds}
      toggleCreativeTemplateId={toggleCreativeTemplateId}
      dealer={dealer}
    />
  )
}

export function SelectCreativeTemplatesAction(props, state) {
  const { creativeTemplateIds, setcreativeTemplatesSelected } = state

  return (
    <div className={actionContainerStyle}>
      <Button
        disabled={creativeTemplateIds.length === 0}
        color="primary"
        variant="contained"
        onClick={() => {
          setcreativeTemplatesSelected(true)
          props.isSinglePartyDealer && state.createCampaign()
        }}
        data-testid={'selectCreativeTemplatesAction'}
      >
        <Trans>{creativeTemplateIds.length > 0 ? 'Next' : 'Select Creatives'}</Trans>
      </Button>
    </div>
  )
}

export function SelectCreativeTemplatesTitle(props, state) {
  const { isSinglePartyDealer } = props

  return (
    <div className={titleContainerStyle}>
      <div>
        <Typography variant="title" gutterBottom data-testid="selectCreativeTemplates-title">
          Select Pre-Made Creatives
        </Typography>

        <Typography variant="subheading" gutterBottom>
          Select ready-made creatives to run in this {isSinglePartyDealer ? 'Digital Template' : 'Campaign'}. We
          recommend having 3 creatives in a {isSinglePartyDealer ? 'Digital Template' : 'Campaign'}.
        </Typography>
      </div>

      {SelectCreativeTemplatesAction(props, state)}
    </div>
  )
}

export class BareSelectCreativeTemplates extends React.Component {
  toggleCreativeSelected = event => {
    event.stopPropagation()
    event.preventDefault()
    this.props.toggleCreativeTemplateId(event.currentTarget.getAttribute('data-creative-id'))
  }

  render() {
    const isSelected = creativeTemplateId => contains(creativeTemplateId, this.props.creativeTemplateIds)

    const {
      campaignTemplate: { creativeTemplates },
      classes,
      dealer,
    } = this.props

    return (
      <React.Fragment>
        {creativeTemplates.map((creative, i) => (
          <div key={creative.id} className={classes.cardContainer}>
            <FacebookAd isSelected={isSelected(creative.id)} creative={creative} dealer={dealer} darkenOnHover />
            <div onClick={this.toggleCreativeSelected} className={classes.overlay} data-creative-id={creative.id} />
            <div onClick={this.toggleCreativeSelected} className={classes.select} data-creative-id={creative.id}>
              <FormControlLabel
                control={<Checkbox checked={isSelected(creative.id)} color="primary" />}
                label="Select"
                onClick={this.toggleCreativeSelected}
                data-testid={`SelectCreativeTemplate-select-${creative.id}`}
                data-creative-id={creative.id}
              />
            </div>
          </div>
        ))}
        <div className={classes.cardContainer} />
      </React.Fragment>
    )
  }
}

BareSelectCreativeTemplates.propTypes = {
  campaignTemplate: PropTypes.object.isRequired,
  creativeTemplateIds: PropTypes.array.isRequired,
  toggleCreativeTemplateId: PropTypes.func.isRequired,
  dealer: PropTypes.object.isRequired,
}

export const SelectCreativeTemplates = withStyles(styles)(BareSelectCreativeTemplates)

export const creativeTemplates = {
  title: SelectCreativeTemplatesTitle,
  stepperTitle: ({ isSinglePartyDealer }) => (
    <TextShrinker
      fullText={isSinglePartyDealer ? 'Select Templates' : 'Select Creatives'}
      shortText={isSinglePartyDealer ? 'Templates' : 'Creatives'}
    />
  ),
  content: SelectCreativeTemplatesContent,
  action: () => null,
  runStepComplete: (props, { creativeTemplatesSelected }) => Boolean(creativeTemplatesSelected),
  campaignStepComplete: ({ creatives }) => creatives.length > 0,
  undoStep: () => ({ creativeTemplatesSelected: null, creatives: [], creativeTemplateIds: [] }),
}
