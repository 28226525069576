import * as React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'ramda'
import { css } from 'emotion'
import { graphql } from 'react-apollo'
import { Trans } from 'react-i18next'
import { Card, Button, TextField, CardContent } from '@material-ui/core'
import { BrandUserProfilesQueryDocument, CreateUserProfileMutationDocument, DealerUserProfilesQueryDocument } from '../../graphql'

const cardStyle = css`
  margin: 7px;
`

export class NewUserProfile extends React.Component {
  static propTypes = {
    brandId: PropTypes.string,
    dealerId: PropTypes.string,
    mutate: PropTypes.func.isRequired,
  }

  createNewUser = () => {
    const { brandId, dealerId, mutate } = this.props
    const { name } = this.state

    mutate({
      variables: {
        brandId,
        dealerId,
        name,
      },
    })

    this.setState({ name: '' })
  }

  setName = event => {
    const name = event.target.value
    this.setState({ name })
  }

  state = { name: '' }

  render() {
    return (
      <Card className={cardStyle}>
        <CardContent>
          <TextField
            label="Name"
            value={this.state.name}
            onChange={this.setName}
            InputProps={{ inputProps: { maxLength: 255 } }}
          />
          <Button
            color="primary"
            onClick={this.createNewUser}
            data-testid="createNewUser"
            disabled={!Boolean(this.state.name)}
          >
            <Trans>Create New User Profile</Trans>
          </Button>
        </CardContent>
      </Card>
    )
  }
}

export default compose(
  graphql(CreateUserProfileMutationDocument, {
    options: ({ brandId, dealerId }) => ({
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: BrandUserProfilesQueryDocument, variables: { brandId: brandId } },
        { query: DealerUserProfilesQueryDocument, variables: { dealerId: dealerId } },
      ],
    }),
  })
)(NewUserProfile)
