import * as React from 'react'
import { graphql } from 'react-apollo'
import { compose, omit, propEq } from 'ramda'
import { DealerQueryDocument } from '../graphql'
import { auth } from 'components/CovaultAuth'

const isSinglePartyDealer = Component =>
  compose(
    auth(),
    graphql(DealerQueryDocument, {
      skip: propEq('parentType', 'brand'),
      options: ({ auth: { parentId: id } }) => ({ variables: { id } }),
      name: 'dealerQuery',
    })
  )(props => {
    const {
      auth: { parentType },
      dealerQuery: { dealer, loading, error },
    } = props

    if (parentType === 'brand') {
      return <Component isSinglePartyDealer={false} {...omit(['dealerQuery'], props)} />
    }

    if (loading || error) {
      return null
    }

    if (dealer && dealer.isSingleParty) {
      return <Component isSinglePartyDealer={true} {...omit(['dealerQuery'], props)} />
    }

    return <Component isSinglePartyDealer={false} {...omit(['dealerQuery'], props)} />
  })

export default isSinglePartyDealer
