import gql from 'graphql-tag'
import {
  assumeRoleFragment,
  campaignTemplateFragment,
  creativeTemplateFragment,
  salesProgramFragment,
} from '../graphql'

export const SalesProgramsQueryDocument = gql`
  query SalesPrograms($brandId: ID!, $status: SalesProgramStatus) {
    brand(id: $brandId) {
      id
      name
      productLines {
        id
        name
        salesPrograms {
          id
          name
        }
      }
      salesPrograms(status: $status) {
        id
        name
        code
        status
        startDate
        endDate
        productLine {
          id
          name
        }
        campaignTemplates {
          ...campaignTemplate
        }
      }
    }
  }

  ${campaignTemplateFragment}
`

export const SalesProgramQueryDocument = gql`
  query SalesPrograms($brandId: ID!, $salesProgramId: ID!) {
    brand(id: $brandId) {
      id
      name
      productLines {
        id
        name
      }
      salesProgram(id: $salesProgramId) {
        id
        name
        code
        status
        startDate
        documentLocation {
          domain
          key
          path
        }
        endDate
        productLine {
          id
          name
        }
        campaignTemplates {
          ...campaignTemplate
          creativeTemplates {
            ...creativeTemplate
          }
        }
      }
    }
  }

  ${campaignTemplateFragment}
  ${creativeTemplateFragment}
`

export const CreateSalesProgramMutationDocument = gql`
  mutation CreateSalesProgram($productLineId: ID!, $salesProgram: SalesProgramCreateParams!) {
    createSalesProgram(productLineId: $productLineId, salesProgram: $salesProgram) {
      assumeRole {
        ...assumeRole
      }
      salesProgram {
        ...salesProgram
      }
    }
  }

  ${assumeRoleFragment}
  ${salesProgramFragment}
`

export const UpdateSalesProgramMutationDocument = gql`
  mutation updateSalesProgram($salesProgram: SalesProgramUpdateParams!, $salesProgramId: ID!) {
    updateSalesProgram(salesProgram: $salesProgram, salesProgramId: $salesProgramId) {
      assumeRole {
        ...assumeRole
      }
      salesProgram {
        ...salesProgram
      }
    }
  }

  ${assumeRoleFragment}
  ${salesProgramFragment}
`

export const PublishSalesProgramMutationDocument = gql`
  mutation PublishSalesProgram($id: ID!) {
    publishSalesProgram(id: $id) {
      id
    }
  }
`

export const DeleteSalesProgramMutationDocument = gql`
  mutation DeleteSalesProgram($id: ID!) {
    deleteSalesProgram(id: $id) {
      id
    }
  }
`

export const ArchiveSalesProgramMutationDocument = gql`
  mutation ArchiveSalesProgram($id: ID!) {
    archiveSalesProgram(id: $id) {
      id
    }
  }
`
