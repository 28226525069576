/* SecondaryNavBar.jsx
 * A secondary navbar that holds route-specific stuff like titles and buttons.
 * Should have two children: One for the left-hand side and one for the right-hand side.
 * See CTAButtonWrapper.jsx for wrapping right-hand-side CTA buttons.
 *
 * Created by Louis Ritchie on April 24th, 2018
 * louiscritchie@gmail.com
 */

import * as React from 'react'
import PropTypes from 'prop-types'
import { AppBar, Toolbar } from '@material-ui/core'
import { css } from 'emotion'

const appbarStyle = css`
  z-index: 1000 !important;
  background: white !important;
  border-bottom: 1px solid #bbb;
`
const toolbarStyle = css`
  justify-content: space-between;
  padding-top: 3px;
  padding-bottom: 5px;
  flex-wrap: wrap;
`

export function SecondaryNavBar({ children, tabs }) {
  return (
    <AppBar className={appbarStyle} position="sticky" color="default">
      <Toolbar className={toolbarStyle}>{children}</Toolbar>
      {tabs}
    </AppBar>
  )
}

SecondaryNavBar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  tabs: PropTypes.object,
}

export default SecondaryNavBar
