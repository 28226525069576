import * as React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'ramda'
import { graphql } from 'react-apollo'
import { Button } from '@material-ui/core'
import { AddRoleToUserProfileMutationDocument, BrandUserProfilesQueryDocument, DealerUserProfilesQueryDocument } from '../../graphql'

export function Roles(props) {
  const {
    userProfile: {
      roles,
      availableRoles,
      id: userProfileId,
      auth: { email },
    },
    mutate,
  } = props

  const addRole = roleId => {
    mutate({
      variables: {
        userProfileId,
        roleId,
      },
    })
  }

  if (!Boolean(email)) {
    return null
  }

  const roleIds = roles.map(role => role.id)
  const unselectedRoles = availableRoles.filter(role => !roleIds.includes(role.id))

  return (
    <div>
      <div>Roles:</div>
      {roles.map(({ name, id }, i) => (
        <div key={i}>
          {name}
          <Button color="primary" onClick={() => {}} data-testid="uselessButton">
            X
          </Button>
        </div>
      ))}
      {unselectedRoles.map(({ name, id }, i) => (
        <div key={i}>
          {name}
          <Button color="primary" onClick={() => addRole(id)} data-testid="addRole">
            <strong>+</strong>
          </Button>
        </div>
      ))}
    </div>
  )
}

Roles.propTypes = {
  userProfile: PropTypes.object.isRequired,
  parentId: PropTypes.string,
  mutate: PropTypes.func.isRequired,
}

export default compose(
  graphql(AddRoleToUserProfileMutationDocument, {
    options: ({ parentId }) => ({
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: BrandUserProfilesQueryDocument, variables: { brandId: parentId } },
        { query: DealerUserProfilesQueryDocument, variables: { dealerId: parentId } },
      ],
    }),
  })
)(Roles)
