import * as React from 'react'
import PropTypes from 'prop-types'
import { compose, without } from 'ramda'
import { graphql } from 'react-apollo'
import { translate } from 'react-i18next'
import { BrandQueryDocument, DealersQueryDocument } from '../graphql'
import SecondaryNavBar from 'components/SecondaryNavBar'
import Title from 'components/Title'
import { Tabs, Tab } from '@material-ui/core'
import { TranslatedDealersTable } from 'components/DealersTable'
import ProductLineTable from 'components/ProductLineTable'
import { CreateProductLine } from 'components/CreateEntityButton'
import { LinkToDealer, EditBrand } from 'components/CreateEntityButton'
import CTAButtonWrapper from 'components/CTAButtonWrapper'
import { DeleteBrand } from 'components/TransitionEntityButton'
import Loading from 'components/Loading'
import Link from 'components/Link'
import { SALES_PROGRAMS_PATH } from 'constants/paths'
import ScrollWrapper from 'components/ScrollWrapper'
import SalesProgramsTable from 'components/SalesProgramsTable'
import { UserProfileContainerForBrand } from 'components/userProfile/UserProfileContainer'
import ImageLoader from 'components/ImageLoader'

export class BrandContainer extends React.Component {
  static propTypes = {
    BrandQueryDocument: PropTypes.object.isRequired,
    DealersQueryDocument: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }

  state = {
    tab: 'brand',
    allDealers: [],
  }

  changeTab = (event, tab) => {
    this.setState({ tab })
  }

  render() {
    const {
      state: { tab },
      props: { t },
    } = this
    const { loading: dealersLoading, error: dealersError } = this.props.DealersQueryDocument
    const { loading: brandLoading, error: brandError } = this.props.BrandQueryDocument

    if (brandLoading || dealersLoading) {
      return <Loading />
    }

    if (brandError) {
      throw brandError
    }
    if (dealersError) {
      throw dealersError
    }

    const { dealers: allDealers } = this.props.DealersQueryDocument
    const {
      brand,
      brand: { name, dealers, productLines, logoLocation, salesPrograms },
    } = this.props.BrandQueryDocument

    return (
      <React.Fragment>
        <SecondaryNavBar
          tabs={
            <Tabs value={tab} onChange={this.changeTab}>
              <Tab value="brand" label={t('Brand Details')} />
              <Tab value="productLines" label={t('Product Lines')} />
              <Tab value="dealers" label={t('Dealers')} />
              <Tab value="users" label={t('User Profiles')} />
            </Tabs>
          }
        >
          <Title>{name}</Title>
          <CTAButtonWrapper>
            {tab === 'brand' && <DeleteBrand brand={brand} />}
            {tab === 'brand' && <EditBrand brand={brand} />}
            {tab === 'productLines' && <CreateProductLine brand={brand} />}
            {tab === 'dealers' && <LinkToDealer brand={brand} dealers={without(dealers, allDealers)} />}
          </CTAButtonWrapper>
        </SecondaryNavBar>
        {tab === 'brand' && (
          <ScrollWrapper>
            <React.Fragment>
              <ImageLoader height={300} width={480} location={logoLocation} />
              <br />
              <Link path={SALES_PROGRAMS_PATH} params={{ brandId: brand.id }}>
                Sales Programs
              </Link>

              <SalesProgramsTable brand={brand} salesPrograms={salesPrograms} />
            </React.Fragment>
          </ScrollWrapper>
        )}
        {tab === 'productLines' && (
          <ProductLineTable brand={brand} productLines={productLines} salesPrograms={salesPrograms} />
        )}
        {tab === 'dealers' && <TranslatedDealersTable dealers={dealers} brandId={brand.id} showUnlinkButton={true} />}
        {tab === 'users' && (
          <ScrollWrapper>
            <UserProfileContainerForBrand brandId={brand.id} />
          </ScrollWrapper>
        )}
      </React.Fragment>
    )
  }
}

export default compose(
  translate(),
  graphql(DealersQueryDocument, { name: 'DealersQueryDocument' }),
  graphql(BrandQueryDocument, {
    name: 'BrandQueryDocument',
    options: props => ({
      variables: {
        id: props.match.params.id,
      },
    }),
  })
)(BrandContainer)
