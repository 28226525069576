import * as React from 'react'
import {
  Table,
  TableBody,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  withStyles,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { css } from 'emotion'
import CurrencyFormat from '../../CurrencyFormat'
import { Ad } from '../../../types'
import CampaignTableHeader from './CampaignTableHeader'
import CampaignTableRow from './CampaignTableRow'
import { PHONE_BREAKPOINT } from '../../../constants'

const expansionHeading = css`
  flex-shrink: 0;
  flex-grow: 10;
  flex-basis: 0;
`

const expansionSubheading = css`
  color: grey;
  flex-grow: 1;
  text-align: right;
`

const styles = {
  typography: {
    [PHONE_BREAKPOINT]: {
      display: 'none',
    },
  },
}

const reducer = (accum: number, { budgetInCents }: Ad) => accum + (budgetInCents || 0)

type CampaingsExpansionPanelProps = {
  campaigns: Ad[]
  statusFilter: string
  title: string
  classes: Record<'typography', string>
}

function CampaingsExpansionPanel({ campaigns, statusFilter, title, classes }: CampaingsExpansionPanelProps) {
  const filteredCampaigns = campaigns.filter(({ status }: Ad) => status === statusFilter)
  const filteredCampaignsValue = filteredCampaigns.reduce(reducer, 0)
  const filteredCampaignsCount = filteredCampaigns.length

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subheading" className={expansionHeading}>
          {title}
        </Typography>
        <span className={expansionSubheading}>
          <Typography className={classes.typography} variant="caption">
            Campaigns:{' '}
          </Typography>
          {filteredCampaignsCount}
        </span>
        <span className={expansionSubheading}>
          <Typography className={classes.typography} variant="caption">
            Total Campaign Value:{' '}
          </Typography>
          <CurrencyFormat displayType="text" fixedDecimalScale value={filteredCampaignsValue / 100} />
        </span>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Table>
          <CampaignTableHeader />
          <TableBody>
            {filteredCampaigns.map((campaign: Ad, i: number) => (
              <CampaignTableRow campaign={campaign} key={i} />
            ))}
          </TableBody>
        </Table>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

export default withStyles(styles)(CampaingsExpansionPanel)
