/* CovaultSwitch.jsx
 * Provides a boolean switch to modify one variable for some GraphQL mutation.
 * Recieves a setValue fn from setValueFactory provided by CreateEntityButton.
 *
 * Created by Louis Ritchie on March 26th, 2019
 * louis@louisritchie.com
 */

import * as React from 'react'
import PropTypes from 'prop-types'
import { Switch, FormControlLabel, FormGroup } from '@material-ui/core'
import { translate } from 'react-i18next'

export class CovaultSwitch extends React.PureComponent {
  static defaultProps = {
    fullWidth: true,
    defaultValue: false,
  }

  static propTypes = {
    error: PropTypes.string,
    style: PropTypes.object,
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool,
    hideLabel: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    testid: PropTypes.string,
    setValue: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['number']),
    InputProps: PropTypes.object,
    optional: PropTypes.bool,
  }

  constructor(props) {
    super(props)

    this.props.setValue(props.defaultValue)
    this.state = { value: props.defaultValue }
  }

  _handleChange = (event, checked) => {
    this.setState({ value: checked })
    this.props.setValue(checked)
  }

  render() {
    const {
      props: { error, style, hideLabel, InputProps, label, name, t, id, testid },
      state: { value },
    } = this

    return (
      <FormGroup>
        <FormControlLabel
          label={label}
          control={
            <Switch
              checked={value}
              value="switch"
              style={style}
              helperText={error && t(error)}
              id={id}
              data-testid={testid}
              InputProps={{ inputProps: { maxLength: 255 }, ...InputProps }}
              label={!hideLabel ? label : void 0}
              name={name}
              onChange={this._handleChange}
            />
          }
        />
      </FormGroup>
    )
  }
}

export default translate()(CovaultSwitch)
