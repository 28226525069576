import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import sessionState from './sessionState'
import auth from './auth0'

export default combineReducers({
  auth,
  router: routerReducer,
  sessionState,
})
