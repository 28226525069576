/* Image.jsx
 * This component takes a src URL and displays an image. It attempts to reload the image a number of times.
 * If you give it a src URL that has a size encoded in it, such as .../300x300/brand/1/image.jpg, then it will
 * just serve that image. If you instead pass it a src URL that does not have a size encoded in it, then it
 * will encode your given height and width in the URL and then try and serve that image, reloading each second
 * as we wait for that image to be generated in the AWS Lamda pipeline.
 *
 * Created by Louis Ritchie on June 13th, 2018
 * louiscritchie@gmail.com
 */

import * as React from 'react'
import PropTypes from 'prop-types'
import { css } from 'emotion'
import CircularProgress from '@material-ui/core/CircularProgress'
import { defaultTo } from 'ramda'

const MAX_RETRIES = 10

export default class ImageLoader extends React.Component {
  static propTypes = {
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    location: PropTypes.shape({
      domain: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }),
    style: PropTypes.object,
    transformation: PropTypes.string,
  }

  constructor(props) {
    super(props)
    const { domain, path, key } = defaultTo({}, props.location)

    const image = new Image()

    this.state = {
      image,
      loaded: false,
      failed: !domain || !path || !key,
      transformation: props.transformation ? props.transformation : 'max',
    }

    this.url = `https://${domain}${path}${props.width}x${props.height}/${this.state.transformation}/${key}`

    image.src = this.url
    image.onload = () => this.setState({ loaded: true })
    image.onerror = () => this.handleRetry(image)

    this.retryCount = 0
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.location) return

    if (this.mounted && prevProps.location.key !== this.props.location.key) {
      const {
        props: {
          location: { domain, path, key },
          height,
          width,
        },
        state: { transformation },
      } = this

      const image = new Image()

      this.setState({
        image,
        loaded: false,
      })

      this.url = `https://${domain}${path}${width}x${height}/${transformation}/${key}`

      image.src = this.url
      image.onload = () => this.setState({ loaded: true })
      image.onerror = () => this.handleRetry(image)

      this.retryCount = 0
    }
  }

  handleRetry = image => {
    if (!this.mounted) {
      return
    }

    if (this.retryCount >= MAX_RETRIES) {
      return this.setState({ loaded: false, failed: true })
    }

    setTimeout(() => {
      image.src = this.url
    }, 1000)
    this.retryCount++
  }

  render() {
    const { height } = this.props

    if (this.state.loaded) {
      return <img style={this.props.style} src={this.url} className={imageStyle} alt="" />
    } else if (this.state.failed) {
      return (
        <img
          style={{ height, opacity: 0.4, ...this.props.style }}
          src={require('../assets/ImagePlaceholder-2.svg')}
          className={imageStyle}
          alt=""
        />
      )
    } else {
      return (
        <div className={progressWrapperStyle} style={{ height, ...this.props.style }}>
          <CircularProgress />
        </div>
      )
    }
  }
}

const imageStyle = css`
  display: block;
  width: auto;
  margin: auto;
`
const progressWrapperStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`
