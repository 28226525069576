/* Breakpoints (px) to use in CSS media queries.
 */
export const PHONE_SIZE = 480
export const TABLET_SIZE = 768
export const LAPTOP_SIZE = 1024
export const DESKTOP_SIZE = 1200

export const BREAKPOINTS = {
  phone: `${PHONE_SIZE}px`,
  tablet: `${TABLET_SIZE}px`,
  laptop: `${LAPTOP_SIZE}px`,
  desktop: `${DESKTOP_SIZE}px`,
}

export const PHONE_BREAKPOINT = `@media (max-width: ${BREAKPOINTS.phone})`
export const TABLET_BREAKPOINT = `@media (max-width: ${BREAKPOINTS.tablet})`
export const LAPTOP_BREAKPOINT = `@media (max-width: ${BREAKPOINTS.laptop})`
export const DESKTOP_BREAKPOINT = `@media (max-width: ${BREAKPOINTS.desktop})`

export const PHONE_BREAKPOINT_INVERTED = `@media (min-width: ${BREAKPOINTS.phone})`
export const TABLET_BREAKPOINT_INVERTED = `@media (min-width: ${BREAKPOINTS.tablet})`
export const LAPTOP_BREAKPOINT_INVERTED = `@media (min-width: ${BREAKPOINTS.laptop})`
export const DESKTOP_BREAKPOINT_INVERTED = `@media (min-width: ${BREAKPOINTS.desktop})`
