import React from 'react'
import { compose, pick } from 'ramda'
import { CreateStripeCardMutationDocument } from '../graphql'
import { css } from 'emotion'
import { graphql } from 'react-apollo'
import { StripeCardsQueryDocument } from '../graphql'
import { Trans } from 'react-i18next'
import CovaultAuth from 'components/CovaultAuth'
import withDealerId from 'components/WithDealerId'
import withSnackbar from 'components/WithSnackbar'
import { CardElement, injectStripe } from 'react-stripe-elements'
import { Button, Dialog, DialogActions, Icon, LinearProgress, TextField } from '@material-ui/core'
import { CEBDialogTitle, CEBDialogContent, ErrorBar, Headline } from 'components/materialUI'
import TextShrinker from 'components/TextShrinker'

const INITIAL_STATE = {
  dialogueOpen: false,
  errorMessage: undefined,
  loading: false,
  name: '',
  address_line1: '',
  address_line2: '',
  address_city: '',
  address_state: '',
  address_zip: '',
  address_country: 'Canada',
  cardAdded: false,
}

class StripeAddCard extends React.Component {
  constructor(props) {
    super(props)
    this.submit = this.submit.bind(this)
    this.state = {
      ...INITIAL_STATE,
      stripeCardElementKey: 1,
      submittable: false,
    }
  }

  _toggleDialogueOpen = () => this.setState({ dialogueOpen: !this.state.dialogueOpen })

  async submit(ev) {
    this.setState({ errorMessage: undefined, loading: true })

    const { dealerId } = this.props
    const payload = pick(
      ['name', 'address_line1', 'address_line2', 'address_city', 'address_state', 'address_zip', 'address_country'],
      this.state
    )

    const {
      token: { id: token },
    } = await this.props.stripe.createToken(payload)

    await this.props
      .mutate({
        variables: {
          token,
          dealerId,
        },
      })
      .then(
        ({ data }) =>
          this.setState({
            ...INITIAL_STATE,
            stripeCardElementKey: this.state.stripeCardElementKey + 1,
          }),
        error => {
          let errorMessage = ''

          if (!error || !error.graphQLErrors || !error.graphQLErrors.length || !error.graphQLErrors[0].message) {
            errorMessage = 'An error occurred while adding this card.'
          } else {
            errorMessage = error.graphQLErrors[0].message
          }

          this.setState({ errorMessage, loading: false })
        }
      )
  }

  handleFieldChange = event => {
    const { name, value } = event.target

    const change = {}
    change[name] = value
    this.setState(change)
  }

  handleCardElementChange = ({ error, complete }) => this.setState({ submittable: !error && complete })

  render() {
    const {
      dialogueOpen,
      errorMessage,
      loading,
      submittable,
      name,
      address_line1,
      address_line2,
      address_city,
      address_state,
      address_zip,
      cardAdded,
      stripeCardElementKey,
    } = this.state

    if (cardAdded) {
      return null
    }

    return (
      <React.Fragment>
        <Button variant="contained" color="primary" onClick={this._toggleDialogueOpen}>
          <TextShrinker fullText="Add Credit Card" shortText="Add Card" />
        </Button>
        <Dialog open={dialogueOpen} onClose={this._toggleDialogueOpen}>
          <CEBDialogTitle>
            <div className={headlineWrapperStyle}>
              <Headline>Add a Credit Card</Headline>
              <div>
                <img src={require('../assets/poweredByStripe.svg')} alt="" />
                <Icon className={iconStyle}>lock</Icon>
              </div>
            </div>
          </CEBDialogTitle>
          <CEBDialogContent>
            <TextField
              label="Card Holder Name"
              name="name"
              value={name}
              onChange={this.handleFieldChange}
              InputLabelProps={{
                shrink: true,
              }}
              required
              fullWidth
            />
            <TextField
              label="Address Line 1"
              name="address_line1"
              value={address_line1}
              onChange={this.handleFieldChange}
              InputLabelProps={{
                shrink: true,
              }}
              required
              fullWidth
            />
            <TextField
              label="Address Line 2"
              name="address_line2"
              value={address_line2}
              onChange={this.handleFieldChange}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
            <TextField
              label="City"
              name="address_city"
              value={address_city}
              onChange={this.handleFieldChange}
              InputLabelProps={{
                shrink: true,
              }}
              required
              fullWidth
            />
            <TextField
              label="State / Province"
              name="address_state"
              value={address_state}
              onChange={this.handleFieldChange}
              InputLabelProps={{
                shrink: true,
              }}
              required
              fullWidth
            />
            <TextField
              label="Country"
              name="address_zip"
              value={address_zip}
              onChange={this.handleFieldChange}
              InputLabelProps={{
                shrink: true,
              }}
              required
              fullWidth
            />
            <div className={cardWrapperStyle}>
              <CardElement
                key={stripeCardElementKey}
                style={cardElementStyle}
                onChange={this.handleCardElementChange}
              />
            </div>
          </CEBDialogContent>
          <DialogActions>
            <div className={buttonWrapperStyle}>
              <Button color="primary" variant="contained" onClick={this.submit} disabled={!submittable || loading}>
                <Trans>{loading ? 'Submitting' : 'Add Card'}</Trans>
              </Button>
            </div>
          </DialogActions>
          {loading && <LinearProgress />}
          {errorMessage && <ErrorBar>{errorMessage}</ErrorBar>}
        </Dialog>
      </React.Fragment>
    )
  }
}

export default compose(
  withSnackbar,
  withDealerId,
  injectStripe,
  CovaultAuth({
    requiredParent: ['dealer'],
    requiredPermission: 'card:manage',
  }),
  graphql(CreateStripeCardMutationDocument, {
    options: ({ snackbarContext, dealerId }) => ({
      refetchQueries: [{ query: StripeCardsQueryDocument, variables: { dealerId } }],
      awaitRefetchQueries: true,
      onCompleted: () => snackbarContext.snackbarMessage('Card Added', 'Card Created'),
    }),
  })
)(StripeAddCard)

const buttonWrapperStyle = css`
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
`
const cardWrapperStyle = css`
  margin-top: 32px;
`
const headlineWrapperStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const iconStyle = css`
  color: rgba(66, 71, 112, 0.502);
  margin-left: 8px;
`
var cardElementStyle = {
  base: {
    padding: '6px 0 7px',
    lineHeight: '1.1875em',
    fontSize: '16px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    '::placeholder': {
      color: '#CFD7DF',
    },
  },
  invalid: {
    color: '#e5424d',
    ':focus': {
      color: '#303238',
    },
  },
}
