import * as React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'ramda'
import { translate } from 'react-i18next'
import { css } from 'emotion'
import { GridList, GridListTile } from '@material-ui/core'
import CovaultDatePicker from 'components/CovaultDatePicker'
import CovaultTextField from 'components/CovaultTextField'
import doubleClickLogo from 'assets/destination-doubleclick.png'
import facebookLogo from 'assets/destination-facebook.png'
import newspaperLogo from 'assets/destination-offline.png'
import CurrencyFormat from 'components/CurrencyFormat'
import DistanceFormat from 'components/DistanceFormat'
import { covaultFieldContainerStyle } from 'components/materialUI'

const gridImg = css`
  width: 160px;
  height: 190px;
`
const gridListTile = css`
  cursor: pointer;
`

const halfWidth = {
  width: '50%',
}

class AdForm extends React.Component {
  componentWillUnmount = () => this.props.handleUnmount && this.props.handleUnmount()

  render() {
    const {
      errorFields,
      showChannelGrid,
      showBaseOptions,
      selectedChannel,
      setChannelFunc,
      setValueFactory,
      t,
      variables,
    } = this.props

    const doubleClickOrFacebook = selectedChannel === 'FACEBOOK' || selectedChannel === 'DOUBLE_CLICK'

    return (
      <div className={covaultFieldContainerStyle}>
        {showChannelGrid && (
          <React.Fragment>
            <GridList cellHeight={190} cols={3}>
              <GridListTile
                className={gridListTile}
                onClick={() => {
                  setChannelFunc('DOUBLE_CLICK')
                }}
              >
                <img className={gridImg} src={doubleClickLogo} alt={t('DoubleClick Logo')} />
              </GridListTile>
              <GridListTile
                className={gridListTile}
                onClick={() => {
                  setChannelFunc('FACEBOOK')
                }}
              >
                <img className={gridImg} src={facebookLogo} alt={t('Facebook Logo')} />
              </GridListTile>
              <GridListTile
                className={gridListTile}
                onClick={() => {
                  setChannelFunc('OFFLINE')
                }}
              >
                <img className={gridImg} src={newspaperLogo} alt={t('Newspaper Logo')} />
              </GridListTile>
            </GridList>
          </React.Fragment>
        )}
        {showBaseOptions && (
          <React.Fragment>
            <CovaultTextField
              id="name"
              error={errorFields['name']}
              defaultValue={variables.name || ''}
              label={t('Name')}
              setValue={setValueFactory('name')}
            />
            <CovaultDatePicker
              testid="startDate"
              autoOk
              style={halfWidth}
              defaultValue={variables.startDate}
              minDate={variables.salesProgramStartDate}
              maxDate={variables.salesProgramEndDate}
              emptyLabel="YYYY-MM-DD"
              label={t('Start Date')}
              setValue={setValueFactory('startDate')}
              error={errorFields['startDate']}
            />
            <CovaultDatePicker
              testid="endDate"
              autoOk
              style={halfWidth}
              defaultValue={variables.endDate}
              minDate={variables.salesProgramStartDate}
              maxDate={variables.salesProgramEndDate}
              emptyLabel="YYYY-MM-DD"
              label={t('End Date')}
              setValue={setValueFactory('endDate')}
              error={errorFields['endDate']}
            />
            <CovaultTextField
              id="budget"
              error={errorFields['budgetInCents']}
              style={doubleClickOrFacebook ? halfWidth : null}
              defaultValue={variables.budget || null}
              label={t('Budget')}
              setValue={setValueFactory('budget')}
              InputProps={{
                inputComponent: CurrencyFormat,
              }}
            />

            {doubleClickOrFacebook && (
              <React.Fragment>
                <CovaultTextField
                  id="radius"
                  error={errorFields['radius']}
                  style={halfWidth}
                  defaultValue={variables.radius || ''}
                  fullWidth={false}
                  label={t('Target Radius')}
                  setValue={setValueFactory('radius')}
                  InputProps={{
                    inputComponent: DistanceFormat,
                  }}
                  optional
                />
                <CovaultTextField
                  id="targetAddressLine1"
                  error={errorFields['targetAddressLine1']}
                  label="Target Location"
                  defaultValue={variables.targetAddressLine1}
                  setValue={() => {}}
                  disabled
                />
                <CovaultTextField
                  id="targetAddressLine2"
                  error={errorFields['targetAddressLine2']}
                  label="Target Location Line 2"
                  defaultValue={variables.targetAddressLine2}
                  setValue={() => {}}
                  disabled
                  hideLabel
                />
                <CovaultTextField
                  id="audience"
                  error={errorFields['audience']}
                  defaultValue={variables.audience || ''}
                  label={t('Audience (eg. age range, gender, interests)')}
                  setValue={setValueFactory('audience')}
                  optional
                />
              </React.Fragment>
            )}
            {selectedChannel === 'OFFLINE' && (
              <CovaultTextField
                id="emailDestination"
                defaultValue={variables.emailDestination || ''}
                error={errorFields['emailDestination']}
                label={t('Media Contact Email')}
                setValue={setValueFactory('emailDestination')}
              />
            )}
          </React.Fragment>
        )}
      </div>
    )
  }
}

AdForm.propTypes = {
  errorFields: PropTypes.object,
  handleUnmount: PropTypes.func,
  selectedChannel: PropTypes.string,
  setChannelFunc: PropTypes.func,
  setValueFactory: PropTypes.func.isRequired,
  showBaseOptions: PropTypes.bool,
  showChannelGrid: PropTypes.bool,
  t: PropTypes.func.isRequired,
  variables: PropTypes.object.isRequired,
}

export default compose(translate())(AdForm)
