import { contains, merge, pipe, insert, slice, without } from 'ramda'
import { BUMP_CAMPAIGN, UPDATE_SESSION_STATE, CLEAR_SESSION_STATE } from '../actions/sessionState'

const INITIAL_STATE = {
  brandId: void 0,
  recentCampaigns: [],
  salesProgram: void 0,
}

const sessionStateReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case BUMP_CAMPAIGN:
      let nextRecentCampaigns

      if (!payload.campaign) {
        return state
      }

      if (contains(payload.campaign, state.recentCampaigns)) {
        nextRecentCampaigns = pipe(
          without([payload.campaign]),
          insert(0, payload.campaign)
        )(state.recentCampaigns)
      } else {
        nextRecentCampaigns = pipe(
          insert(0, payload.campaign),
          slice(0, 5)
        )(state.recentCampaigns)
      }

      return merge(state, { recentCampaigns: nextRecentCampaigns })
    case UPDATE_SESSION_STATE:
      return merge(state, payload.sessionStateUpdate)
    case CLEAR_SESSION_STATE:
      return INITIAL_STATE
    default:
      return state
  }
}

export default sessionStateReducer
