import * as React from 'react'
import { path } from 'ramda'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

interface BrandId {
  brandId: string
}

type Omit<T, K> = Pick<T, Exclude<keyof T, keyof K>>

function mapStateToProps(state: any) {
  return {
    brandId: state.sessionState.brandId,
  }
}

function withBrandId(Component: any) {
  return (props: any) => {
    let brandId = path(['match', 'params', 'brandId'], props)

    if (!brandId && props.brandId) {
      brandId = props.brandId
    }

    return <Component {...props} brandId={brandId} />
  }
}

export default <T extends any>(Component: React.ComponentType<T & BrandId>): React.ComponentType<Omit<T, BrandId>> =>
  connect(mapStateToProps)(withRouter(withBrandId(Component))) as React.ComponentType<Omit<T, BrandId>>
