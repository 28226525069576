/* CovaultDatePicker.jsx
 * Provides a date picker to set a date for a GraphQL mutation
 * Receives a setValue fn from setValueFactory provided by CreateEntityButton.
 */

import * as React from 'react'
import PropTypes from 'prop-types'
import { DatePicker } from 'material-ui-pickers'
import { css } from 'emotion'
import moment from 'moment'

const datePickerStyle = css`
  margin-top: 10px;
`

export class CovaultDatePicker extends React.Component {
  static defaultProps = {
    defaultValue: moment().format('YYYY-MM-DD'),
  }

  static propTypes = {
    style: PropTypes.object,
    autoOk: PropTypes.bool,
    defaultValue: PropTypes.string,
    disablePast: PropTypes.bool,
    label: PropTypes.string,
    minDate: PropTypes.string,
    maxDate: PropTypes.string,
    setValue: PropTypes.func.isRequired,
    testid: PropTypes.string,
  }

  state = {
    value: void 0,
  }

  componentDidMount() {
    this._setValue(this.props.defaultValue)
  }

  _setValue = value => {
    this.setState({ value })
    this.props.setValue(value)
  }

  _handleChange = value => {
    const formatedValue = value.format('YYYY-MM-DD')
    this._setValue(formatedValue)
  }

  render() {
    const {
      props: { autoOk, defaultValue, disableFuture, disablePast, error, label, minDate, maxDate, testid, style },
      state: { value },
    } = this

    return (
      <DatePicker
        className={datePickerStyle}
        data-testid={testid}
        style={style}
        label={label}
        value={value || defaultValue}
        onChange={this._handleChange}
        helperText={error}
        leftArrowIcon={'<'}
        rightArrowIcon={'>'}
        minDate={minDate}
        maxDate={maxDate}
        format={'LL'}
        error={Boolean(error)}
        emptyLabel=" "
        autoOk={autoOk}
        disablePast={disablePast}
        disableFuture={disableFuture}
      />
    )
  }
}

export default CovaultDatePicker
