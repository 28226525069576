import { createStore, applyMiddleware } from 'redux'
import rootReducer from 'reducers'
import { composeWithDevTools } from 'redux-devtools-extension'
import { preloadState, saveState } from 'utils/storeCache'

export default function(middlewares) {
  const store = createStore(rootReducer, preloadState, composeWithDevTools(applyMiddleware(...middlewares)))

  store.subscribe(() => {
    saveState('auth', store.getState().auth)
  })

  return store
}
