import * as React from 'react'
import { redirectUnlessAuthenticated } from '../utils/authWrappers'
import withNavBars from 'components/WithNavBars'
import { compose } from 'ramda'
import { Route } from 'react-router-dom'

export function AuthenticatedNavRoute(props) {
  const { component } = props

  const decoratedComponent = compose(
    redirectUnlessAuthenticated,
    withNavBars
  )(component)

  return <Route {...props} component={decoratedComponent} />
}

export default AuthenticatedNavRoute
