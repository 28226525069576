import { curry, any, isNil, is, contains, path } from 'ramda'

export const getAuthClaimFromState = path(['auth', 'profile', 'https://covault.app/auth'])

export const getAuthClaimFromAuth = path(['profile', 'https://covault.app/auth'])

const hasPermission = (claim, permission) => {
  const permissionList = path(['permissions'], claim)

  if (isNil(permissionList)) {
    throw new Error('claim object missing permissions')
  }

  return contains(permission, permissionList)
}

const hasParent = curry((parent, claim) => {
  const parentType = path(['parent', 'type'], claim)

  if (isNil(parentType)) {
    throw new Error('claim object missing parent type')
  }

  return parentType === parent
})

const permissionQueryCheck = curry((claim, query) => {
  const permission = path(['permission'], query)
  const parent = path(['parent'], query)

  if (isNil(permission) || isNil(parent)) {
    throw new Error('Permission object requires values for parent and permission')
  }

  return hasPermission(claim, permission) && hasParent(parent, claim)
})

const permissionOrQuery = curry((claim, criteria) => {
  if (is(String, criteria)) {
    return hasPermission(claim, criteria)
  } else {
    return permissionQueryCheck(claim, criteria)
  }
})

export const permissionCheck = curry((claim, criteria) => {
  if (is(Array, criteria)) {
    return any(permissionOrQuery(claim), criteria)
  } else {
    return permissionOrQuery(claim, criteria)
  }
})

export const isBrand = hasParent('brand')
export const isCovault = hasParent('covault')
export const isDealer = hasParent('dealer')
export const getParentId = path(['parent', 'id'])
export const getParentType = path(['parent', 'type'])
export const getPermissions = path(['permissions'])
