import * as React from 'react'
import { compose } from 'ramda'
import { Elements } from 'react-stripe-elements'
import { graphql } from 'react-apollo'
import { StripeCardsQueryDocument, DealerQueryDocument } from '../graphql'
import { Trans } from 'react-i18next'
import { translate } from 'react-i18next'
import CovaultAuth from 'components/CovaultAuth'
import graphQLData from 'hoc/GraphQLData'
import SecondaryNavBar from 'components/SecondaryNavBar'
import StripeAddCard from 'components/StripeAddCard'
import StripeCardList from 'components/StripeCardList'
import Title from 'components/Title'
import withDealerId from 'components/WithDealerId'
import ScrollWrapper from 'components/ScrollWrapper'
import { SectionPaper, StyledCardHeader, TranslatedParagraph } from 'components/materialUI'
import { css } from 'emotion'
import { Button, CardContent, withStyles } from '@material-ui/core'
import { Query } from 'react-apollo'
import { getAuthClaimFromAuth, getParentId } from 'utils/permissions'
import ImageLoader from 'components/ImageLoader'
import { auth0Logout } from 'actions/auth0'
import { connect } from 'react-redux'

export class UserSettingsContainer extends React.Component {
  state = { dialogueOpen: false }

  _toggleDialogue = () => this.setState({ dialogueOpen: !this.state.dialogueOpen })

  render() {
    const {
      auth,
      auth: { profile },
      auth0Logout,
      data: {
        dealer: {
          facebookSlug,
          stripeCustomer: { stripeCards },
        },
      },
    } = this.props

    const dealerId = getParentId(getAuthClaimFromAuth(auth))

    return (
      <React.Fragment>
        <SecondaryNavBar>
          <Title>
            <Trans>User Settings</Trans>
          </Title>
          <Button variant="contained" color="secondary" data-testid="logout" onClick={auth0Logout}>
            Logout
          </Button>
        </SecondaryNavBar>
        <ScrollWrapper>
          <FirstSectionPaper>
            <div className={paymentSectionHeaderWrapper}>
              <div>
                <StyledCardHeader title="Payment Information" />
                <CardContentStyled>
                  <TranslatedParagraph>
                    When your Credit Card is added, Click “Campaigns” to navigate back to your recent campaign.
                  </TranslatedParagraph>
                  <TranslatedParagraph>
                    Your Credit Card will only be charged Ad Spend that you select. During your Free Trial, there are no
                    additional fees.
                  </TranslatedParagraph>
                </CardContentStyled>
              </div>
              {stripeCards && stripeCards.length > 0 && (
                <Elements>
                  <StripeAddCard />
                </Elements>
              )}
            </div>
            <CardContentStyled>
              <StripeCardList stripeCards={stripeCards} dealerId={dealerId} />
            </CardContentStyled>
          </FirstSectionPaper>
          <SectionPaper>
            <StyledCardHeader title="Link Covault to Facebook" />
            <CardContent>
              <TranslatedParagraph>1. Click Settings at the top of your Page.</TranslatedParagraph>
              <TranslatedParagraph>2. Click Page Roles in the left column.</TranslatedParagraph>
              <TranslatedParagraph>3. Accept “Covault App” Agency Request</TranslatedParagraph>
              <br />
              {facebookSlug && (
                <div className={connectToFacebookCTAWrapper}>
                  <TranslatedParagraph>Click here to link Covault to Facebook:</TranslatedParagraph>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://business.facebook.com/${facebookSlug}/settings/?tab=admin_roles`}
                  >
                    <Button color="primary" variant="contained">
                      Connect to Facebook
                    </Button>
                  </a>
                </div>
              )}
            </CardContent>
          </SectionPaper>
          <SectionPaper>
            <StyledCardHeader title="Contact Details" />
            <CardContent>
              <TranslatedParagraph>Name: {profile.nickname}</TranslatedParagraph>
              <TranslatedParagraph>Email: {profile.name}</TranslatedParagraph>
              <Query query={DealerQueryDocument} variables={{ id: dealerId }}>
                {({ data, loading, error }) => {
                  if (loading || error) {
                    return null
                  }

                  if (!data.dealer.logoLocation) {
                    return null
                  }

                  return (
                    <ImageLoader
                      height={300}
                      width={300}
                      location={data.dealer.logoLocation}
                      style={{ margin: 'unset' }}
                    />
                  )
                }}
              </Query>
            </CardContent>
          </SectionPaper>
        </ScrollWrapper>
      </React.Fragment>
    )
  }
}

const FirstSectionPaper = withStyles({
  root: {
    marginTop: 24,
  },
})(SectionPaper)
const CardContentStyled = withStyles({
  root: {
    paddingRight: 0,
  },
})(CardContent)

export default compose(
  translate(),
  withDealerId,
  CovaultAuth({
    requiredParent: ['dealer'],
    requiredPermission: 'card:view',
  }),
  graphql(StripeCardsQueryDocument, {
    options: ({ dealerId }) => {
      return {
        variables: { dealerId: dealerId },
      }
    },
  }),
  connect(
    null,
    { auth0Logout }
  ),
  graphQLData
)(UserSettingsContainer)

const connectToFacebookCTAWrapper = css`
  display: flex;
  align-items: center;
  margin-top: 24px;

  > p {
    margin: 1px 14px 0 0;
  }
`

const paymentSectionHeaderWrapper = css`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    width: 70%;
  }
`
