import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper'
import { connectedReduxRedirect } from 'redux-auth-wrapper/history4/redirect'
import { routerActions } from 'react-router-redux'
import { complement, path } from 'ramda'
import { ROOT_PATH, LOGIN_PATH } from 'constants/paths'
import { COVAULT_URL_PASSTHRU_KEY } from 'constants/urlPassthru'

export const redirectUnlessAuthenticated = connectedReduxRedirect({
  redirectPath: LOGIN_PATH,
  allowRedirectBack: false,
  authenticatedSelector: path(['auth', 'isAuthenticated']),
  wrapperDisplayName: 'redirectUnlessAuthenticated',
  redirectAction: routerActions.replace,
})

// This wrapper wraps Login only. If it's extended for other purposes, URL passthru may break.
export const redirectIfAuthenticated = connectedReduxRedirect({
  redirectPath: state => {
    if (!path(['auth', 'isAuthenticated'], state)) {
      return // if we aren't authenticated, then this redirect isn't used.
    }

    if (!window.sessionStorage.getItem(COVAULT_URL_PASSTHRU_KEY)) {
      return ROOT_PATH
    }

    const redirectPath = window.sessionStorage.getItem(COVAULT_URL_PASSTHRU_KEY)
    window.sessionStorage.removeItem(COVAULT_URL_PASSTHRU_KEY)
    return redirectPath
  },
  allowRedirectBack: false,
  authenticatedSelector: complement(path(['auth', 'isAuthenticated'])),
  wrapperDisplayName: 'userIsNotAuthenticated',
  redirectAction: routerActions.replace,
})

export const hideUnlessAuthenticated = connectedAuthWrapper({
  authenticatedSelector: path(['auth', 'isAuthenticated']),
  wrapperDisplayName: 'hideUnlessAuthenticated',
})
