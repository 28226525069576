import * as React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'ramda'
import { graphql } from 'react-apollo'
import { Trans } from 'react-i18next'
import SalesProgramsTable from 'components/SalesProgramsTable'
import Title from 'components/Title'
import SecondaryNavBar from 'components/SecondaryNavBar'
import { SalesProgramsQueryDocument } from '../graphql'
import withBrandId from 'components/WithBrandId'
import { CovaultAuth, auth } from 'components/CovaultAuth'
import Loading from 'components/Loading'
import { CreateSalesProgram } from 'components/EntityButtons'
import ScrollWrapper from 'components/ScrollWrapper'

export function SalesProgramsContainer(props) {
  const {
    auth: { isDealer },
    brandId,
    data
  } = props

  if (!brandId) {
    return null
  }

  if (data && data.error) {
    throw data.error
  }

  return (
    <React.Fragment>
      {data && data.loading && <Loading />}

      {data &&
        data.brand && (
          <React.Fragment>
            <SecondaryNavBar>
              <Title>
                {isDealer && data.brand.name}
                <Trans> Sales Programs</Trans>
              </Title>
              <CovaultAuth requiredPermission="sales_program:manage" requiredParent={['brand', 'covault']}>
                <CreateSalesProgram brandId={brandId} />
              </CovaultAuth>
            </SecondaryNavBar>
            <ScrollWrapper>
              <SalesProgramsTable brand={data.brand} salesPrograms={data.brand.salesPrograms} />
            </ScrollWrapper>
          </React.Fragment>
        )}
    </React.Fragment>
  )
}

SalesProgramsContainer.propTypes = {
  auth: PropTypes.object.isRequired,
  brandId: PropTypes.string,
  data: PropTypes.object
}

export default compose(
  auth({
    requiredPermission: 'sales_program:view'
  }),
  withBrandId,
  graphql(SalesProgramsQueryDocument, {
    skip: props => !props.brandId,
    options: ({ brandId }) => ({ variables: { brandId } })
  })
)(SalesProgramsContainer)
