import * as React from 'react'
import { compose } from 'ramda'
import { graphql, Query } from 'react-apollo'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { clearSessionState, updateSessionState } from 'actions/sessionState'
import { Badge, Dialog, DialogTitle, GridList, GridListTile, GridListTileBar, withStyles } from '@material-ui/core'
import { translate } from 'react-i18next'
import { AdsQueryWithStatusForCountByBrand, BrandsQueryDocument } from '../graphql'
import { hideUnlessAuthenticated } from 'utils/authWrappers'
import withBrandId from 'components/WithBrandId'
import { generatePath } from 'react-router'
import { DASHBOARD_PATH } from 'constants/paths'
import { auth } from 'components/CovaultAuth'
import ImageLoader from 'components/ImageLoader'
import graphQLData from '../hoc/GraphQLData'
import { css } from 'emotion'

const mapDispatchToProps = dispatch => ({
  clearSessionState: () => dispatch(clearSessionState()),
  updateSessionState: args => dispatch(updateSessionState(args)),
})

const brandNameTextStyle = css`
  max-width: 165px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
const styles = theme => ({
  root: {
    padding: '3px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 450,
  },
  gridListTile: {
    textAlign: 'center',
    cursor: 'pointer',
  },
})

const StyledGridListTileBar = withStyles({
  root: {
    background: 'rgba(0, 0, 0, 0.7)',
  },
  title: {
    paddingTop: 10,
    marginBottom: 10,
  },
})(GridListTileBar)

export class BrandPicker extends React.Component {
  selectBrandId(brandId) {
    this.props.clearSessionState()
    this.props.updateSessionState({ brandId, recentCampaigns: [] })
    this.props.history.push(generatePath(DASHBOARD_PATH, { brandId }))
  }

  redirectOnSingleBrand() {
    const {
      auth: { isCovault },
      brandId,
      data: { brands, loading },
    } = this.props

    if (!brandId && !loading && !isCovault && brands.length === 1) {
      this.selectBrandId(brands[0].id)
    }
  }

  componentDidMount() {
    this.redirectOnSingleBrand()
  }

  componentDidUpdate() {
    this.redirectOnSingleBrand()
  }

  render() {
    const {
      auth: { isCovault },
      brandId,
      data: { brands },
      classes,
      t,
    } = this.props

    if (brandId || isCovault || !brands || brands.length === 1) {
      return null
    }

    return (
      <Dialog open>
        <DialogTitle>{t('Select a Brand')}</DialogTitle>

        <div className={classes.root}>
          {brands && (
            <GridList cellHeight={175} className={classes.gridList}>
              {brands.map((brand, i) => (
                <GridListTile
                  key={brand.id}
                  onClick={() => this.selectBrandId(brand.id)}
                  className={classes.gridListTile}
                >
                  <ImageLoader location={brand.logoLocation} width={175} height={175} />
                  <Query
                    query={AdsQueryWithStatusForCountByBrand}
                    variables={{ id: brand.id, adStatus: ['APPROVED', 'REJECTED'] }}
                  >
                    {({ data }) => {
                      let count = 0

                      if (data.brand) {
                        count = data.brand.ads.length
                      }

                      return (
                        <StyledGridListTileBar
                          title={
                            <Badge invisible={count === 0} data-testid="badge" badgeContent={count} color="error">
                              <span className={brandNameTextStyle}>{brand.name}</span>
                            </Badge>
                          }
                        />
                      )
                    }}
                  </Query>
                </GridListTile>
              ))}
            </GridList>
          )}
        </div>
      </Dialog>
    )
  }
}

BrandPicker.propTypes = {
  auth: PropTypes.object.isRequired,
  brandId: PropTypes.string,
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
}

export default compose(
  auth(),
  hideUnlessAuthenticated,
  connect(
    void 0,
    mapDispatchToProps
  ),
  translate(),
  withStyles(styles),
  graphql(BrandsQueryDocument),
  withBrandId,
  graphQLData
)(BrandPicker)
