/* CovaultTextField.jsx
 * Provides a text field to modify one variable for some GraphQL mutation.
 * Recieves a setValue fn from setValueFactory provided by CreateEntityButton.
 *
 * Created by Louis Ritchie on April 25th, 2018
 * louiscritchie@gmail.com
 */

import * as React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import { translate } from 'react-i18next'

export class CovaultTextField extends React.PureComponent {
  static defaultProps = {
    fullWidth: true,
    defaultValue: '',
  }

  static propTypes = {
    error: PropTypes.string,
    style: PropTypes.object,
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    hideLabel: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    testid: PropTypes.string,
    setValue: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['number']),
    InputProps: PropTypes.object,
    optional: PropTypes.bool,
  }

  constructor(props) {
    super(props)

    const defaultValue = this._coerceToType(props.defaultValue)

    this.props.setValue(defaultValue)
    this.state = {
      value: defaultValue,
    }
  }

  _coerceToType = value => (this.props.type === 'number' ? Number(value) : value)

  _setValue = value => {
    let nextValue = this._coerceToType(value)
    this.setState({ value: nextValue })
    this.props.setValue(nextValue)
  }

  _handleChange = ({ target: { value } }) => this._setValue(value)

  render() {
    const {
      props: { error, style, disabled, fullWidth, hideLabel, InputProps, label, name, t, type, id, testid },
      state: { value },
    } = this

    return (
      <TextField
        value={value}
        style={style}
        disabled={disabled}
        error={Boolean(error)}
        fullWidth={fullWidth}
        helperText={error && t(error)}
        id={id}
        data-testid={testid}
        InputProps={InputProps}
        label={!hideLabel ? label : void 0}
        name={name}
        onChange={this._handleChange}
        type={type}
      />
    )
  }
}

export default translate()(CovaultTextField)
