import gql from 'graphql-tag'
import { userProfileFragment } from './'

export const BrandUserProfilesQueryDocument = gql`
  query($brandId: ID!) {
    parent: brand(id: $brandId) {
      id
      name
      userProfiles {
        name
        id
      }
    }
  }
`

export const BrandUserProfileQueryDocument = gql`
  query($parentId: ID!, $userProfileId: ID!) {
    parent: brand(id: $parentId) {
      id
      name
      userProfile(id: $userProfileId) {
        ...userProfile
      }
    }
  }

  ${userProfileFragment}
`

export const DealerUserProfilesQueryDocument = gql`
  query($dealerId: ID!) {
    parent: dealer(id: $dealerId) {
      id
      name
      userProfiles {
        name
        id
      }
    }
  }
`

export const DealerUserProfileQueryDocument = gql`
  query($parentId: ID!, $userProfileId: ID!) {
    parent: dealer(id: $parentId) {
      id
      name
      userProfile(id: $userProfileId) {
        ...userProfile
      }
    }
  }

  ${userProfileFragment}
`
export const CreateUserProfileMutationDocument = gql`
  mutation CreateUserProfile($brandId: ID, $dealerId: ID, $name: String!) {
    createUserProfile(brandId: $brandId, dealerId: $dealerId, name: $name) {
      name
      id
    }
  }
`

export const CreateUserProfileAuthMutationDocument = gql`
  mutation CreateUserProfileAuth($userProfileId: ID!, $email: String!) {
    createUserProfileAuth(id: $userProfileId, email: $email) {
      name
      id
      auth {
        email
      }
    }
  }
`

export const AddRoleToUserProfileMutationDocument = gql`
  mutation AddRoleToUserProfile($userProfileId: ID!, $roleId: String!) {
    addRoleToUserProfile(id: $userProfileId, roleId: $roleId) {
      ...userProfile
    }
  }

  ${userProfileFragment}
`

export const OnboardUserProfileMutationDocument = gql`
  mutation OnboardUserProfile($userProfileId: ID!) {
    onboardUserProfile(id: $userProfileId) {
      name
      id
    }
  }
`
