import * as React from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'

function DistanceFormat(props) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      ref={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        })
      }}
      allowNegative={false}
      thousandSeparator
      suffix="km"
    />
  )
}

DistanceFormat.propTypes = {
  inputRef: PropTypes.func,
  onChange: PropTypes.func
}

export default DistanceFormat
