export const saveState = (key, obj) => {
  try {
    const serializedState = JSON.stringify(obj)
    window.localStorage.setItem(key, serializedState)
  } catch (e) {}
}

export const loadState = key => {
  try {
    const serializedState = window.localStorage.getItem(key)

    if (serializedState === null) {
      return undefined
    }

    return JSON.parse(serializedState)
  } catch (e) {
    return undefined
  }
}

export const preloadState = {
  auth: loadState('auth'),
}
