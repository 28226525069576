import * as React from 'react'
import PropTypes from 'prop-types'
import Link from 'components/Link'
import { AppBar, Icon, Toolbar } from '@material-ui/core'
import { css } from 'emotion'
import { CovaultAuth, auth } from 'components/CovaultAuth'
import BrandDisplay from 'components/BrandDisplay'
import { BREAKPOINTS, USER_SETTINGS_PATH } from '../constants'
import { auth0Logout } from 'actions/auth0'
import { connect } from 'react-redux'
import { compose } from 'ramda'

const toolbarStyle = css`
  display: grid !important;
  padding-left: 10px !important;
  grid-template-columns: 7fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: '. . . .';

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    // IE specific styles
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: ${BREAKPOINTS.phone}) {
    grid-template-columns: 1fr 1fr 80px 60px;
  }
`
const toolbarItemContainer = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 6px;
`
const logoWrapperStyle = css`
  display: flex;
  align-items: center;
  width: 160px;

  @media (max-width: ${BREAKPOINTS.laptop}) {
    width: 125px;
  }
`
const logoStyle = css`
  width: 100%;
`
const iconStyle = css`
  color: white;
  cursor: pointer;
  :hover {
    color: #999;
  }
`
const nicknameStyle = css`
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 8px;

  @media (max-width: ${BREAKPOINTS.tablet}) {
    display: none;
  }
`
const settingsIconStyle = css`
  margin: 2px 12px 0 8px;
`

const logoutIconStyle = css`
  margin-left: 8px;
  margin-right: 16px;
`

export function NavBar(props) {
  const {
    auth: { isAuthenticated, profile },
    auth0Logout,
  } = props

  return (
    <AppBar position="sticky" color="inherit">
      <Toolbar className={toolbarStyle} disableGutters>
        <div className={logoWrapperStyle}>
          <img className={logoStyle} src={require('../assets/covault-logo.png')} alt="covault logo" />
        </div>

        <BrandDisplay />

        <div className={toolbarItemContainer}>
          <span data-testid="loggedInAs" className={nicknameStyle}>
            {profile.nickname}
          </span>
          <CovaultAuth requiredParent="dealer">
            <Link path={USER_SETTINGS_PATH}>
              <Icon className={`${iconStyle} ${settingsIconStyle}`} data-testid="settings">settings</Icon>
            </Link>
          </CovaultAuth>
          {isAuthenticated && (
            <CovaultAuth requiredParent={['brand', 'covault']}>
              <Icon data-testid="logout" onClick={auth0Logout} className={`${iconStyle} ${logoutIconStyle}`}>
                exit_to_app
              </Icon>
            </CovaultAuth>
          )}
        </div>
      </Toolbar>
    </AppBar>
  )
}

NavBar.propTypes = {
  auth: PropTypes.object,
  auth0Logout: PropTypes.func,
}

export default compose(
  auth(),
  connect(
    null,
    { auth0Logout }
  )
)(NavBar)
