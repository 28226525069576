import gql from 'graphql-tag'
import {
  adFragment,
  adPartialFragment,
  campaignTemplateFragment,
  creativeFragment,
  dealerFragment,
  salesProgramFragment,
} from '../graphql'

export const CreateAdMutationDocument = gql`
  mutation AdCreate($ad: AdCreateParams!, $salesProgramId: ID!) {
    createAd(ad: $ad, salesProgramId: $salesProgramId) {
      ...adPartial
      creatives {
        id
      }
    }
  }

  ${adPartialFragment}
`

export const UpdateAdMutationDocument = gql`
  mutation AdUpdate($ad: AdUpdateParams!, $id: ID!) {
    updateAd(ad: $ad, id: $id) {
      id
    }
  }
`

export const DeleteAdMutationDocument = gql`
  mutation DeleteAd($id: ID!) {
    deleteAd(id: $id) {
      id
    }
  }
`

export const AdsQueryDocument = gql`
  query($brandId: ID!) {
    brand(id: $brandId) {
      id
      ads {
        ...ad
        dealer {
          ...dealer
        }
        salesProgram {
          ...salesProgram
        }
      }
      salesPrograms {
        ...salesProgram
        campaignTemplates {
          ...campaignTemplate
        }
      }
    }
  }

  ${adFragment}
  ${dealerFragment}
  ${salesProgramFragment}
  ${campaignTemplateFragment}
`

export const AdsQueryWithStatusForCount = gql`
  query AdsQueryWithStatusForCount($adStatus: AdStatus!) {
    dealers {
      id
      ads(status: $adStatus) {
        id
      }
    }
  }
`

export const AdsQueryWithStatusForCountByBrand = gql`
  query AdsQueryWithStatusForCountByBrand($id: ID!, $adStatus: [AdStatus!]) {
    brand(id: $id) {
      id
      ads(status: $adStatus) {
        id
      }
    }
  }
`

export const AdQueryDocument = gql`
  query GetAd($id: ID!) {
    dealers {
      ...dealer
      ad(id: $id) {
        ...adPartial
        creatives {
          ...creative
        }
        salesProgram {
          id
          name
          startDate
          endDate
        }
        statusLogs {
          status
          notes
          date
        }
        submittedOn
        channelQuestions {
          valueType
          name
          label
          required
          ... on StringChannelQuestion {
            value
            default
          }
          ... on DateChannelQuestion {
            value
            default
          }
          ... on IntegerChannelQuestion {
            value
            default
          }
          ... on EnumChannelQuestion {
            value
            values
            displayNames
            default
          }
          ... on UnitChannelQuestion {
            quantityValue
            quantityDefault
            unitValue
            unitValues
            unitDefault
          }
        }
      }
    }
  }

  ${adPartialFragment}
  ${creativeFragment}
  ${dealerFragment}
`

export const SubmitAdMutationDocument = gql`
  mutation SubmitAd($id: ID!) {
    submitAd(id: $id) {
      id
    }
  }
`

export const ApproveAdMutationDocument = gql`
  mutation ApproveAd($id: ID!) {
    approveAd(id: $id) {
      id
    }
  }
`

export const ApproveAdWithNotesMutationDocument = gql`
  mutation ApproveAd($id: ID!, $notes: String!) {
    approveAd(id: $id, notes: $notes) {
      id
    }
  }
`

export const RejectAdMutationDocument = gql`
  mutation RejectAd($id: ID!, $notes: String!) {
    rejectAd(id: $id, notes: $notes) {
      id
    }
  }
`

export const PublishAdMutationDocument = gql`
  mutation PublishAd($id: ID!) {
    publishAd(id: $id) {
      id
    }
  }
`

export const PublishAdWithoutApprovalMutationDocument = gql`
  mutation PublishAdWithoutApproval($id: ID!) {
    publishAdWithoutApproval(id: $id) {
      id
    }
  }
`
