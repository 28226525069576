import * as React from 'react'
import { Trans } from 'react-i18next'
import SecondaryNavBar from 'components/SecondaryNavBar'
import Title from 'components/Title'
import AdsTable from 'components/AdsTable'
import { auth } from 'components/CovaultAuth'
import PropTypes from 'prop-types'
import { graphql } from 'react-apollo'
import { AdsQueryDocument } from '../graphql'
import Loading from 'components/Loading'
import withBrandId from 'components/WithBrandId'
import { compose } from 'ramda'
import SalesProgramSelectDialog from '../components/SalesProgramSelectDialog'
import { Button } from '@material-ui/core'
import CTAButtonWrapper from 'components/CTAButtonWrapper'
import RunCampaignButton from '../campaign/RunCampaignButton'
import SinglePartyDealerOnly from 'hoc/SinglePartyDealerOnly'
import isSinglePartyDealer from 'hoc/IsSinglePartyDealer'
import { hasDatePassed } from '../utils/helpers'

export class AdsContainer extends React.Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    data: PropTypes.object,
  }

  state = { open: false }

  handleSalesProgramSelectClose = () => {
    this.setState({ open: false })
  }

  handleOpenSalesProgramSelect = () => {
    this.setState({ open: true })
  }

  render() {
    const {
      data,
      auth: { isDealer },
      isSinglePartyDealer,
    } = this.props

    if (!data) {
      return null
    }

    const { brand, error, loading } = data

    if (loading) {
      return <Loading />
    }

    if (error) {
      throw error
    }

    return (
      <React.Fragment>
        <SecondaryNavBar>
          <Title>
            <Trans>{isSinglePartyDealer ? 'Digital Templates' : 'Campaigns'}</Trans>
          </Title>
          {!!(
            isDealer &&
            brand.salesPrograms.filter(salesProgram => !hasDatePassed(Date.parse(salesProgram.endDate))).length
          ) && (
            <CTAButtonWrapper>
              <SinglePartyDealerOnly inverted={true}>
                <Button color="primary" variant="contained" onClick={this.handleOpenSalesProgramSelect}>
                  Create Campaign
                </Button>
              </SinglePartyDealerOnly>
              <SinglePartyDealerOnly>
                <RunCampaignButton />
              </SinglePartyDealerOnly>
              <SalesProgramSelectDialog
                open={this.state.open}
                onClose={this.handleSalesProgramSelectClose}
                salesPrograms={brand.salesPrograms}
              />
            </CTAButtonWrapper>
          )}
        </SecondaryNavBar>
        <AdsTable ads={brand.ads} />
      </React.Fragment>
    )
  }
}

export default compose(
  auth({ requiredPermission: 'ad:view' }),
  withBrandId,
  graphql(AdsQueryDocument, {
    skip: ({ brandId }) => !brandId,
    options: ({ brandId }) => ({ variables: { brandId } }),
  }),
  isSinglePartyDealer
)(AdsContainer)
