import * as React from 'react'
import PropTypes from 'prop-types'
import { compose, prop } from 'ramda'
import { translate } from 'react-i18next'
import { withApollo } from 'react-apollo'
import CreateEntityButton from 'components/CreateEntityButton'
import CovaultTextField from 'components/CovaultTextField'
import CovaultFilePicker from 'components/CovaultFilePicker'
import CovaultAuth from 'components/CovaultAuth'
import { DeleteCreative } from 'components/TransitionEntityButton'
import DownloadLocationButton from 'components/DownloadLocationButton'
import { AdQueryDocument, UpdateCreativeMutationDocument } from '../../graphql'
import withSnackbar from 'components/WithSnackbar'
import FacebookAd from 'components/FacebookAd'
import { removeUndefined, tripleMergeDeep } from 'utils/helpers'
import { uploadFile, uploadTemporaryFile } from './uploadFileHelper'

export class EditFacebookCreative extends React.Component {
  static propTypes = {
    ad: PropTypes.object.isRequired,
    creative: PropTypes.object.isRequired,
    dealer: PropTypes.object.isRequired,
  }

  state = {
    file: void 0,
    loading: void 0,
    location: void 0,
    progress: void 0,
  }

  render() {
    const {
      props: { ad, creative, dealer, t },
      state: { loading, location, progress },
    } = this

    return (
      <CreateEntityButton
        confirmBeforeClose={true}
        mutation={UpdateCreativeMutationDocument}
        buttonText={t('Edit Creative')}
        onCompleted={async ({ updateCreative: { assumeRole } }) => {
          await uploadFile.bind(this)(assumeRole, 'file', { errorMessage: 'Creative upload failed.' })
        }}
        initialVariables={[['id', creative.id]]}
        refetchQueries={() => [{ query: AdQueryDocument, variables: { id: ad.id } }]}
        progress={progress}
        loading={loading}
        submitHook={() => this.setState({ loading: true })}
        errorHook={() => this.setState({ loading: false })}
        livePreview={variables => (
          <FacebookAd
            dealer={dealer}
            creative={tripleMergeDeep(creative, removeUndefined(variables.creative), removeUndefined({ location }))}
          />
        )}
        additionalButtons={() => (
          <React.Fragment>
            <DeleteCreative creative={creative} ad={ad} />
            <DownloadLocationButton location={creative.location} />
          </React.Fragment>
        )}
      >
        {(setValueFactory, errorFields) => (
          <React.Fragment>
            <CovaultFilePicker
              label={t('Image')}
              setValue={(hasFile, file) => {
                if (!hasFile) {
                  setValueFactory('creative.filename', true)(null)
                  this.setState({ file: void 0 })
                } else {
                  this.setState({ file })
                  setValueFactory('creative.filename', true)(file.name)
                  uploadTemporaryFile.bind(this)(file, 'file', 'location', { errorMessage: 'Creative upload failed.' })
                }
              }}
            />
            <CovaultTextField
              defaultValue={prop('text', creative)}
              id="text"
              error={errorFields['text']}
              label={t('Post Text')}
              setValue={setValueFactory('creative.text')}
              InputProps={{ multiline: true, rowsMax: 6 }}
            />
            <CovaultTextField
              defaultValue={prop('name', creative)}
              id="name"
              error={errorFields['name']}
              label={t('Headline')}
              setValue={setValueFactory('creative.name')}
              InputProps={{ inputProps: { maxLength: 255 } }}
            />
            <CovaultTextField
              defaultValue={prop('description', creative)}
              id="description"
              error={errorFields['description']}
              label={t('Newsfeed Description')}
              setValue={setValueFactory('creative.description')}
              InputProps={{ inputProps: { maxLength: 255 } }}
            />
            <CovaultTextField
              defaultValue={prop('clickThroughUrl', creative)}
              id="clickThroughUrl"
              error={errorFields['clickThroughUrl']}
              label={t('Click-through URL')}
              setValue={setValueFactory('creative.clickThroughUrl')}
            />
          </React.Fragment>
        )}
      </CreateEntityButton>
    )
  }
}

export default compose(
  withSnackbar,
  translate(),
  CovaultAuth({ requiredParent: 'dealer' }),
  withApollo
)(EditFacebookCreative)
