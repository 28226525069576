import * as React from 'react'
import PropTypes from 'prop-types'
import { css } from 'emotion'
import FacebookAd from 'components/FacebookAd'
import { EditFacebookCreative } from 'components/EntityButtons'
import { EditFacebookCreativeTemplate } from 'components/EntityButtons'
import { DeleteCreativeButton, DeleteCreativeTemplateButton } from 'components/TransitionEntityButton'

const editButtonStyle = css`
  position: absolute;
  text-align: center;
  width: 100%;
  bottom: 45%;
`
const facebookAdContainer = css`
  margin: 10px 0 0 10px;
  position: relative;
`
const facebookAdOpacityLayer = css`
  transition: opacity 0.2s;
  will-change: opacity;
`

class FacebookCard extends React.Component {
  static propTypes = {
    campaign: PropTypes.object.isRequired,
    creative: PropTypes.object.isRequired,
    editable: PropTypes.bool.isRequired,
    salesProgramId: PropTypes.string,
    isTemplate: PropTypes.bool,
  }

  state = {
    hovering: false,
  }

  onMouseEnterHandler = () => this.setState({ hovering: true })

  onMouseLeaveHandler = () => this.setState({ hovering: false })

  render() {
    const {
      props: { isTemplate = false, creative, dealer, editable, campaign, salesProgramId },
      state: { hovering },
    } = this

    return (
      <React.Fragment>
        <div
          className={facebookAdContainer}
          onMouseEnter={this.onMouseEnterHandler}
          onMouseLeave={this.onMouseLeaveHandler}
        >
          <div className={facebookAdOpacityLayer} style={editable ? { opacity: hovering ? 0.2 : 1 } : {}}>
            <FacebookAd dealer={dealer} creative={creative} />
          </div>
          <div
            className={editButtonStyle}
            style={{ display: hovering && editable ? 'block' : 'none' }}
            onClick={this.onMouseLeaveHandler}
            onMouseEnter={this.onMouseEnterHandler}
          >
            {editable &&
              !isTemplate && (
                <React.Fragment>
                  <EditFacebookCreative creative={creative} dealer={dealer} ad={campaign} />
                  <br />
                  <br />
                  <DeleteCreativeButton creative={creative} ad={campaign} buttonType="button" />
                </React.Fragment>
              )}
            {editable &&
              isTemplate && (
                <React.Fragment>
                  <EditFacebookCreativeTemplate
                    creativeTemplate={creative}
                    dealer={dealer}
                    campaignTemplate={campaign}
                    salesProgramId={salesProgramId}
                  />
                  <br />
                  <br />
                  <DeleteCreativeTemplateButton
                    creativeTemplate={creative}
                    campaignTemplate={campaign}
                    buttonType="button"
                  />
                </React.Fragment>
              )}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default FacebookCard
