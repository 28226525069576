import 'core-js'
import 'unfetch/polyfill'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { MuiThemeProvider } from '@material-ui/core/styles'
import CovaultTheme from 'styles/covaultTheme'
import App from 'containers/App'
import registerServiceWorker from 'registerServiceWorker'
import { ApolloProvider } from 'react-apollo'
import { Provider as ReduxProvider } from 'react-redux'
import { I18nextProvider } from 'react-i18next'
import createStore from 'providers/store'
import createClient from 'providers/apollo'
import i18n from 'providers/i18n'
import { ConnectedRouter, routerMiddleware } from 'react-router-redux'
import { RouterToUrlQuery } from 'react-url-query'
import createHistory from 'history/createBrowserHistory'
import auth0Middleware from 'middleware/auth0'
import urlPassthruMiddleware from 'middleware/urlPassthru'
import { createEpicMiddleware } from 'redux-observable'
import Epics from 'epics'
import 'styles/index.css'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import MomentUtils from '@date-io/moment'
import registerHotjar from 'utils/hotjar'
import { ravenInstall } from 'utils/ravenInstall'
import { StripeProvider } from 'react-stripe-elements'

ravenInstall()

const history = createHistory()

const store = createStore([
  createEpicMiddleware(Epics),
  routerMiddleware(history),
  auth0Middleware,
  urlPassthruMiddleware,
])

const apolloClient = createClient({ store })

ReactDOM.render(
  <ReduxProvider store={store}>
    <ApolloProvider client={apolloClient}>
      <MuiThemeProvider theme={CovaultTheme}>
        <I18nextProvider i18n={i18n}>
          <ConnectedRouter history={history}>
            <RouterToUrlQuery>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLIC_KEY}>
                  <App />
                </StripeProvider>
              </MuiPickersUtilsProvider>
            </RouterToUrlQuery>
          </ConnectedRouter>
        </I18nextProvider>
      </MuiThemeProvider>
    </ApolloProvider>
  </ReduxProvider>,
  document.getElementById('root')
)

registerServiceWorker()
registerHotjar()
