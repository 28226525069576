// see this document for help understand CEB. https://covault-documentation.s3-us-west-2.amazonaws.com/create-entity-button.png

import * as React from 'react'
import PropTypes from 'prop-types'
import Raven from 'raven-js'
import { mergeDeepRight, path, prop, reduce } from 'ramda'
import { merge, set } from 'lodash'
import { Mutation } from 'react-apollo'
import { Trans } from 'react-i18next'
import { Button, Dialog, DialogActions, LinearProgress, withStyles } from '@material-ui/core'
import { ErrorBar, CEBDialogContent, CEBDialogTitle, Headline } from 'components/materialUI'
import TextShrinker from 'components/TextShrinker'
import { CloseConfirmationDialog } from 'campaign/RunCampaignButton'
import { CenteredPurpleText } from 'components/materialUI'
import { css } from 'emotion'

class CreateEntityButton extends React.Component {
  static propTypes = {
    buttonProps: PropTypes.object,
    buttonText: PropTypes.string.isRequired,
    confirmBeforeClose: PropTypes.bool,
    shortButtonText: PropTypes.string,
    children: PropTypes.func.isRequired,
    errorHook: PropTypes.func,
    initialVariables: PropTypes.array,
    mapMutationVariables: PropTypes.func,
    mutation: PropTypes.object.isRequired,
    onCompleted: PropTypes.func,
    refetchQueries: PropTypes.func,
    resetOnOpen: PropTypes.bool,
    submitHook: PropTypes.func,
    titleText: PropTypes.string,
    update: PropTypes.func,
  }

  DEFAULT_ERROR_STATE = { errorFields: {}, errorMessage: void 0 }

  state = {
    dialogueOpen: false,
    showCloseConfirmation: false,
  }

  _genInitialState = props => ({
    variables: props.initialVariables ? this._genFormState(props.initialVariables) : {},
    ...this.DEFAULT_ERROR_STATE,
  })

  // accepts an array of [path, value] tuples, where path is a path-like string: "my.path[0].to['object']"
  _genFormState = reduce((obj, [path, val]) => set(obj, path, val), {})

  _openDialogue = () => this.setState({ dialogueOpen: true, ...this._genInitialState(this.props) })

  _closeDialogue = () => this.setState({ dialogueOpen: false, variables: {} })

  _closeWithConfirmation = () => this.setState({ showCloseConfirmation: true })

  _closeConfirmed = () => this.setState({ dialogueOpen: false, showCloseConfirmation: false })

  _closeCancelled = () => this.setState({ dialogueOpen: true, showCloseConfirmation: false })

  _submitMutation = submit => {
    const { entityId, mapMutationVariables, submitHook } = this.props
    let { variables } = this.state

    this.setState(this.DEFAULT_ERROR_STATE)

    submitHook && submitHook()

    if (mapMutationVariables) {
      variables = mapMutationVariables(variables)
    }

    if (entityId) {
      variables = { ...variables, id: entityId }
    }

    submit({ variables })
  }

  _onCompleted = async data => {
    const returnValue = this.props.onCompleted && (await this.props.onCompleted(data))

    // In the following code, we are checking if returnValue is a promise; if so, we make sure to close after it's done.
    // TODO check that we ever need to have a promise returned, and remove the "coincidence programming" that follows
    if (returnValue && returnValue.then === 'function') {
      returnValue.then(this._closeDialogue, err => console.error(err))
    } else {
      this._closeDialogue()
    }
  }

  _onMutationError = error => {
    this.setState(CreateEntityButton._genErrorState(error))

    this.props.errorHook && this.props.errorHook()
  }

  static _genErrorState = error => {
    let errorFields = {}
    let errorMessage

    if (error.graphQLErrors && error.graphQLErrors.length > 0) {
      for (let gqlError of error.graphQLErrors) {
        errorFields = mergeDeepRight(errorFields, gqlError.fields)
        const messagePath = typeof prop('message', gqlError) === 'object' ? ['message', 'message'] : ['message']
        errorMessage = `${errorMessage ? errorMessage + ', ' : ''}${path(messagePath, gqlError)}`
      }
    } else {
      Raven.captureException(error)
      errorMessage = 'An unspecified error occurred.'
    }

    return { errorFields, errorMessage }
  }

  // The meat & potatoes of the CEB framework. You tell it what your fields + object looks like, it gives you the form.
  setValueFactory = path => value => this.setState({ variables: merge(this.state.variables, set({}, path, value)) })

  render() {
    const {
      props: {
        additionalButtons,
        buttonText,
        confirmBeforeClose,
        buttonProps,
        children,
        classes,
        livePreview,
        loading,
        mutation,
        progress,
        refetchQueries,
        shortButtonText,
        titleText = buttonText,
        update,
      },
      state: { showCloseConfirmation, dialogueOpen, errorFields, errorMessage, variables },
    } = this

    return (
      <React.Fragment>
        <Button
          color="primary"
          variant="contained"
          onClick={this._openDialogue}
          id="create-entity-button-open-button"
          data-testid={`${buttonText}-${dialogueOpen ? 'open' : 'closed'}`}
          {...buttonProps}
        >
          <Trans>
            <TextShrinker fullText={buttonText} shortText={shortButtonText} />
          </Trans>
        </Button>

        {dialogueOpen && (
          <Mutation
            mutation={mutation}
            onCompleted={this._onCompleted}
            onError={this._onMutationError}
            refetchQueries={refetchQueries}
            update={(...args) => update && update(...args)}
          >
            {submit => (
              <Dialog
                classes={livePreview ? classes : {}}
                onClose={confirmBeforeClose ? this._closeWithConfirmation : this._closeDialogue}
                disableEnforceFocus
                data-testid="create-entity-button-dialogue"
                open
              >
                <CEBDialogTitle>
                  <Headline>{titleText}</Headline>
                </CEBDialogTitle>

                <div className={livePreview ? livePreviewContentStyle : ''}>
                  {livePreview && <div className={livePreviewWrapper}>{livePreview(variables)}</div>}
                  <CEBDialogContent>{children(this.setValueFactory, errorFields, variables)}</CEBDialogContent>
                </div>

                <DialogActions>
                  {additionalButtons && additionalButtons()}
                  <Button color="primary" onClick={this._closeDialogue}>
                    <Trans>Cancel</Trans>
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => this._submitMutation(submit)}
                    id="create-entity-button-submit-button"
                    disabled={loading}
                  >
                    {loading ? <Trans>processing</Trans> : <Trans>ok</Trans>}
                  </Button>
                </DialogActions>
                {(loading || progress) && (
                  <LinearProgress
                    variant={progress ? 'determinate' : 'indeterminate'}
                    value={progress ? progress * 100 : 0}
                    data-testid="create-entity-button-progress-bar"
                  />
                )}
                {errorMessage && <ErrorBar>{errorMessage}</ErrorBar>}
                <CloseConfirmationDialog maxWidth="lg" open={showCloseConfirmation} scroll="paper" disablePortal={true}>
                  <CenteredPurpleText>
                    Your changes are not saved. <b>Discard changes?</b>
                  </CenteredPurpleText>
                  <DialogActions>
                    <Button variant="contained" onClick={this._closeConfirmed}>
                      Discard Changes
                    </Button>
                    <Button variant="contained" color="primary" onClick={this._closeCancelled}>
                      Continue Editing Campaign
                    </Button>
                  </DialogActions>
                </CloseConfirmationDialog>
              </Dialog>
            )}
          </Mutation>
        )}
      </React.Fragment>
    )
  }
}

const styles = () => ({ paper: { maxWidth: 950 } })
const livePreviewContentStyle = css`
  display: flex;
`
const livePreviewWrapper = css`
  padding: 24px 0 24px 24px;
`

export default withStyles(styles)(CreateEntityButton)
