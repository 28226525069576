import * as React from 'react'
import { css } from 'emotion'
import moment from 'moment'
import Icon from '@material-ui/core/Icon'
import { Trans } from 'react-i18next'
import PropTypes from 'prop-types'
import { Query } from 'react-apollo'
import { compose, defaultTo, flatten, length, prop, map } from 'ramda'
import { CreateRequest } from 'components/TransitionEntityButton'
import { NavLink } from 'components/Link'
import { CovaultAuth, auth } from 'components/CovaultAuth'
import { AdsQueryWithStatusForCount, AdsQueryWithStatusForCountByBrand, SalesProgramsQueryDocument } from '../graphql'
import withBrandId from 'components/WithBrandId'
import { Badge, List, ListItem, ListItemText, Drawer, withStyles } from '@material-ui/core'
import {
  TABLET_BREAKPOINT,
  LAPTOP_BREAKPOINT,
  BREAKPOINTS,
  DASHBOARD_PATH,
  SALES_PROGRAMS_PATH,
  ADS_PATH,
  BRANDS_PATH,
  DEALERS_PATH,
} from '../constants'
import SinglePartyDealerOnly from 'hoc/SinglePartyDealerOnly'

const buttonWrapper = css`
  margin-top: 18px;
  padding: 4px;
  text-align: center;

  @media (max-width: ${BREAKPOINTS.tablet}) {
    display: none;
  }
`

const linkStyle = css`
  text-decoration: none;
  color: #fff;
  font-size: 0.9em;

  @media (max-width: ${BREAKPOINTS.tablet}) {
    flex-grow: 1;
  }

  > div {
    @media (min-width: ${BREAKPOINTS.tablet}) {
      padding-left: 10px !important;
      padding-right: 0 !important;
    }
    @media (max-width: ${BREAKPOINTS.tablet}) {
      text-align: center;
    }
  }

  &.active {
    > div {
      background-color: #201f2c;
      color: #fff;
    }
  }

  :hover > div {
    background-color: #7447ff;
  }
`
const docked = css`
  height: 100%;
`
const paper = css`
  position: relative !important;
  z-index: 1000 !important;
  background-color: inherit !important;
`

const StyledList = withStyles({
  root: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    [TABLET_BREAKPOINT]: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'stretch',
      padding: 0,
    },
  },
})(List)
const StyledListItemText = withStyles({
  root: {
    padding: '0 6px 0 16px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    [LAPTOP_BREAKPOINT]: {
      padding: '0 6px 0 0',
    },
    [TABLET_BREAKPOINT]: {
      padding: '0 6px',
      whiteSpace: 'nowrap',
    },
  },
})(ListItemText)
const StyledIcon = withStyles({
  root: {
    [LAPTOP_BREAKPOINT]: { display: 'none' },
  },
})(Icon)
const StyledBadge = withStyles({
  root: {
    [LAPTOP_BREAKPOINT]: {
      position: 'absolute',
      right: 16,
      top: 19,
    },
    [TABLET_BREAKPOINT]: {
      position: 'relative',
      right: '5%',
      top: 0,
      order: 1,
    },
  },
})(Badge)

const Link = ({ exact, badgeContent, icon, path, params, title, location }) => {
  const content = (
    <StyledListItemText disableTypography>
      <Trans>{title}</Trans>
    </StyledListItemText>
  )

  const iconComponent = <StyledIcon>{icon}</StyledIcon>

  return (
    <NavLink exact={exact} path={path} className={linkStyle} location={location} params={params}>
      <ListItem button disableRipple>
        {badgeContent ? (
          <StyledBadge data-testid="badge" badgeContent={badgeContent} children={iconComponent} color="error" />
        ) : (
          iconComponent
        )}
        {content}
      </ListItem>
    </NavLink>
  )
}

Link.propTypes = {
  badgeContent: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  exact: PropTypes.bool,
  icon: PropTypes.string,
  location: PropTypes.object,
  params: PropTypes.object,
  path: PropTypes.string,
  title: PropTypes.string,
}

export function Sidebar({ auth: { parentType }, location, brandId }) {
  return (
    <Drawer variant="permanent" classes={{ docked, paper }}>
      <StyledList component="nav">
        <Link exact icon="dashboard" location={location} path={DASHBOARD_PATH} title="Dashboard" params={{ brandId }} />

        <CovaultAuth
          requiredPermission={['sales_program:view', 'sales_program:manage']}
          requiredParent={['dealer', 'brand']}
        >
          <SinglePartyDealerOnly inverted={true}>
            <Link
              icon="local_offer"
              location={location}
              path={SALES_PROGRAMS_PATH}
              title="Sales Programs"
              params={{ brandId }}
            />
          </SinglePartyDealerOnly>
        </CovaultAuth>

        <CovaultAuth requiredPermission={['ad:manage', 'ad:approve']} requiredParent="brand">
          <CampaignSidebarItemForBrand location={location} brandId={brandId} />
        </CovaultAuth>

        <CovaultAuth requiredPermission={['ad:manage', 'ad:approve']} requiredParent="dealer">
          <CampaignSidebarItemForDealer location={location} brandId={brandId} />
        </CovaultAuth>

        <CovaultAuth requiredPermission={'brand:manage'} requiredParent="covault">
          <Link icon="domain" location={location} path={BRANDS_PATH} permission={'brand:manage'} title="Brands" />
        </CovaultAuth>

        <CovaultAuth requiredPermission={'dealer:manage'} requiredParent="covault">
          <Link icon="store" path={DEALERS_PATH} title="Dealers" location={location} />
        </CovaultAuth>

        <CovaultAuth requiredParent="dealer">
          <div className={buttonWrapper}>
            <Query query={SalesProgramsQueryDocument} variables={{ brandId }}>
              {({ data, loading, error }) => {
                if (loading || error) {
                  return null
                }

                const today = moment().format('YYYY-MM-DD')
                const salesPrograms = data.brand.salesPrograms.filter(sp => sp.endDate > today)

                if (salesPrograms.length > 0) {
                  return (
                    <SinglePartyDealerOnly inverted={true}>
                      <CreateRequest
                        brandId={brandId}
                        salesProgramId={salesPrograms[0].id}
                        campaignDefaultData={{
                          budgetInCents: 100,
                          name:
                            'Describe the marketing activity here.\r\n\r\nWhen you are ready, click "submit" to send to the dealer.',
                          startDate: moment().format('YYYY-MM-DD'),
                          endDate: moment()
                            .add(10, 'day')
                            .format('YYYY-MM-DD'),
                          channel: 'REQUEST',
                        }}
                      />
                    </SinglePartyDealerOnly>
                  )
                } else {
                  return null
                }
              }}
            </Query>
          </div>
        </CovaultAuth>
      </StyledList>
    </Drawer>
  )
}

function CampaignSidebarItemForBrand({ brandId, location }) {
  return (
    <Query
      query={AdsQueryWithStatusForCount}
      variables={{ adStatus: 'SUBMITTED' }}
      pollInterval={parseInt(process.env.REACT_APP_POLL_INTERVAL)}
    >
      {({ data }) => {
        const dealers = defaultTo([], prop('dealers', data))
        const ads = flatten(map(prop('ads'), dealers))
        const count = length(ads)

        return (
          <Link
            location={location}
            icon="photo"
            path={ADS_PATH}
            title="Campaigns"
            badgeContent={count > 0 && count}
            params={{ brandId }}
          />
        )
      }}
    </Query>
  )
}

function CampaignSidebarItemForDealer({ brandId, location }) {
  if (!brandId) {
    return <Link location={location} icon="photo" path={ADS_PATH} title="Campaigns" params={{ brandId }} />
  }

  return (
    <CovaultAuth requiredPermission={['ad:manage', 'ad:approve']} requiredParent="dealer">
      <Query
        query={AdsQueryWithStatusForCountByBrand}
        variables={{
          id: brandId,
          adStatus: ['APPROVED', 'REJECTED'],
        }}
        pollInterval={parseInt(process.env.REACT_APP_POLL_INTERVAL)}
      >
        {({ data }) => {
          let count

          if (data.brand) {
            count = length(data.brand.ads)
          }

          return (
            <React.Fragment>
              <SinglePartyDealerOnly inverted={true}>
                <Link
                  location={location}
                  icon="photo"
                  path={ADS_PATH}
                  title="Campaigns"
                  badgeContent={count && count > 0 && count}
                  params={{ brandId }}
                />
              </SinglePartyDealerOnly>
              <SinglePartyDealerOnly>
                <Link
                  location={location}
                  icon="photo"
                  path={ADS_PATH}
                  title="Digital Templates"
                  badgeContent={count && count > 0 && count}
                  params={{ brandId }}
                />
              </SinglePartyDealerOnly>
            </React.Fragment>
          )
        }}
      </Query>
    </CovaultAuth>
  )
}

Sidebar.propTypes = {
  auth: PropTypes.object.isRequired,
  brandId: PropTypes.string,
  location: PropTypes.object,
}

export default compose(
  auth(),
  withBrandId
)(Sidebar)
