import * as React from 'react'
import ImageLoader from '../../ImageLoader'
import { Card, CardHeader, CardContent, withStyles } from '@material-ui/core'
import { css } from 'emotion'
import { BREAKPOINTS } from '../../../constants'
import { Dealer } from '../../../types'
import CampaingsExpansionPanel from './CampaignExpansionPanel'
import { PHONE_BREAKPOINT, TABLET_BREAKPOINT } from '../../../constants'

const cardStyle = css`
  margin: 25px;

  @media (max-width: ${BREAKPOINTS.tablet}) {
    margin: 25px 5px 5px 5px;
  }
`

const styles = {
  root: {
    [TABLET_BREAKPOINT]: {
      padding: 5,
      '&:last-child': {
        paddingBottom: 10,
      },
    },
    [PHONE_BREAKPOINT]: {
      padding: 0,
      '&:last-child': {
        paddingBottom: 0,
      },
    },
  },
}

type DealerViewProps = {
  dealer: Dealer
  classes: Record<'root', string>
}

function DealerView({ dealer: { name, logoLocation, ads }, classes }: DealerViewProps) {
  return (
    <Card className={cardStyle}>
      <CardHeader avatar={<ImageLoader location={logoLocation} width={200} height={40} />} title={name} />
      <CardContent classes={classes}>
        <CampaingsExpansionPanel title="Campaigns Awaiting Approval" campaigns={ads} statusFilter="SUBMITTED" />
        <CampaingsExpansionPanel title="Published Campaigns" campaigns={ads} statusFilter="PUBLISHED" />
      </CardContent>
    </Card>
  )
}

export default withStyles(styles)(DealerView)
