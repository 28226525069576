import * as React from 'react'
import PropTypes from 'prop-types'
import { css } from 'emotion'
import { connect } from 'react-redux'
import { clearSessionState } from 'actions/sessionState'
import withBrandId from 'components/WithBrandId'
import Link from 'components/Link'
import { ROOT_PATH } from 'constants/paths'
import { Badge } from '@material-ui/core'
import { compose, find } from 'ramda'
import { graphql, Query } from 'react-apollo'
import { AdsQueryWithStatusForCount, AdsQueryWithStatusForCountByBrand, BrandsQueryDocument } from '../graphql'
import { BREAKPOINTS } from '../constants'
import { defaultTo, flatten, length, prop, map } from 'ramda'
import ImageLoader from 'components/ImageLoader'

const mapDispatchToProps = dispatch => ({
  clearSessionState: () => dispatch(clearSessionState()),
})

const toolbarItemContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;

  @media (max-width: ${BREAKPOINTS.phone}) {
    overflow: hidden;
  }
`

const avatarStyle = css`
  @media (max-width: ${BREAKPOINTS.tablet}) {
    display: none;
  }
`

const changeBrandButtonStyle = css`
  color: white;
  cursor: pointer;
  text-decoration: underline;

  :hover {
    color: #999;
  }

  @media (max-width: ${BREAKPOINTS.phone}) {
    > span {
      display: none;
    }
  }
`
const textStyle = css`
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 8px;
`

export function BrandDisplay(props) {
  const {
    brandId,
    clearSessionState,
    data: { brands = [], loading, error },
  } = props

  if (loading || error || !brandId) {
    return <EmptyBrandDisplay />
  }

  const selectedBrand = find(brand => brand.id === brandId)(brands)

  if (!selectedBrand) {
    const error = Error(`BrandId ${brandId} not found.`)
    error.name = 'BrandIdError'
    throw error
  }

  return (
    <React.Fragment>
      <div className={toolbarItemContainer}>
        <div className={avatarStyle}>
          <ImageLoader location={selectedBrand.logoLocation} width={200} height={40} />
        </div>
        <span className={textStyle}>{selectedBrand.name}</span>
      </div>
      <div className={toolbarItemContainer}>
        <ChangeBrandLink brands={brands} currentBrandId={selectedBrand.id} clearSessionState={clearSessionState} />
      </div>
    </React.Fragment>
  )
}

const ChangeBrandLink = ({ brands, clearSessionState, currentBrandId }) => {
  if (brands.length === 1) {
    return null
  }

  return (
    <Link path={ROOT_PATH}>
      <Query
        query={AdsQueryWithStatusForCount}
        variables={{ adStatus: ['APPROVED', 'REJECTED'] }}
        pollInterval={parseInt(process.env.REACT_APP_POLL_INTERVAL)}
      >
        {({ data }) => {
          const dealers = defaultTo([], prop('dealers', data))
          const ads = flatten(map(prop('ads'), dealers))
          const allBrandAdCount = length(ads)

          return (
            <Query
              query={AdsQueryWithStatusForCountByBrand}
              variables={{ id: currentBrandId, adStatus: ['APPROVED', 'REJECTED'] }}
            >
              {({ data }) => {
                let currentBrandAdCount = 0

                if (data.brand) {
                  currentBrandAdCount = length(data.brand.ads)
                }

                const count = allBrandAdCount - currentBrandAdCount

                return (
                  <Badge invisible={count === 0} data-testid="badge" badgeContent={count} color="error">
                    <span onClick={clearSessionState} className={`${changeBrandButtonStyle} ${textStyle}`}>
                      Change <span>Brand</span>
                    </span>
                  </Badge>
                )
              }}
            </Query>
          )
        }}
      </Query>
    </Link>
  )
}

const EmptyBrandDisplay = () => (
  <React.Fragment>
    <div className={toolbarItemContainer} />
    <div className={toolbarItemContainer} />
  </React.Fragment>
)

BrandDisplay.propTypes = {
  brandId: PropTypes.string,
  data: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default compose(
  withBrandId,
  connect(
    null,
    mapDispatchToProps
  ),
  graphql(BrandsQueryDocument)
)(BrandDisplay)
