import { AUTH0_PROFILE, AUTH0_LOGOUT } from 'actions/auth0'
import { AUTH0_AUTHENTICATED, AUTH0_AUTHORIZATION_ERROR, AUTH0_CHECK_EXPIRY, AUTH0_LOGIN } from '../actions/auth0'

const auth0LockReduxReducer = (state = null, action) => {
  switch (action.type) {
    case AUTH0_LOGOUT:
      return null
    case AUTH0_LOGIN:
      return {
        isAuthenticated: false,
        isAuthenticating: true,
      }
    case AUTH0_AUTHENTICATED:
      return {
        ...state,
        ...action.payload,
        expiresAt: action.payload.idTokenPayload.exp,
      }
    case AUTH0_PROFILE:
      return {
        ...state,
        isAuthenticated: true,
        isAuthenticating: false,
        profile: action.payload,
      }
    case AUTH0_AUTHORIZATION_ERROR:
      return {
        error: action.error,
        isAuthenticated: false,
        isAuthenticating: false,
      }
    case AUTH0_CHECK_EXPIRY:
      return {
        ...state,
        isAuthenticated: state && new Date().getTime() / 1000 < state.expiresAt,
      }
    default:
      return state
  }
}

export default auth0LockReduxReducer
