import * as React from 'react'
import { Trans } from 'react-i18next'
import PropTypes from 'prop-types'
import { graphql } from 'react-apollo'
import { compose, propEq, path } from 'ramda'
import { Button } from '@material-ui/core'
import { PublishAd, PublishAdWithoutApproval } from 'components/TransitionEntityButton'
import { StripeCardsQueryDocument } from '../graphql'
import { auth } from 'components/CovaultAuth'
import PublishBlocker from 'components/PublishBlocker'

class PublishCampaign extends React.Component {
  state = {
    publishBlockerOpen: false,
  }

  handlePublishBlockerClick = () => this.setState({ publishBlockerOpen: !this.state.publishBlockerOpen })

  render() {
    const {
      props: { campaign, auth, isSingleParty, data, loading, error, disabled },
      state: { publishBlockerOpen },
    } = this

    if (loading || error) {
      return null
    }

    const isDealer = auth.parentType === 'dealer'
    const isDealerViewingApprovedAd = isDealer && propEq('status', 'APPROVED', campaign)
    const isSinglePartyDealerViewingUnsubmittedAd =
      isDealer && isSingleParty && propEq('status', 'UNSUBMITTED', campaign)
    const isDealerWithoutSavedCards =
      isDealer &&
      path(['dealer', 'stripeCustomer', 'stripeCards'], data) &&
      data.dealer.stripeCustomer.stripeCards.length === 0

    if (disabled) {
      return (
        <Button disabled color="primary" variant="contained">
          <Trans>Publish</Trans>
        </Button>
      )
    }

    return (
      <React.Fragment>
        <PublishBlocker isOpen={publishBlockerOpen} handleClose={this.handlePublishBlockerClick} />
        {(isDealerViewingApprovedAd || isSinglePartyDealerViewingUnsubmittedAd) && isDealerWithoutSavedCards ? (
          <Button color="primary" variant="contained" onClick={this.handlePublishBlockerClick}>
            <Trans>Publish</Trans>
          </Button>
        ) : isSingleParty ? (
          <PublishAdWithoutApproval ad={campaign} />
        ) : (
          <PublishAd ad={campaign} />
        )}
      </React.Fragment>
    )
  }
}

PublishCampaign.propTypes = {
  campaign: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  isSingleParty: PropTypes.bool.isRequired,
  data: PropTypes.any.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.any,
}

export default compose(
  auth({
    requiredPermission: 'ad:view',
    requiredParent: 'dealer',
  }),
  graphql(StripeCardsQueryDocument, {
    options: props => ({
      variables: {
        dealerId: props.auth.parentId,
      },
    }),
  })
)(PublishCampaign)
