import * as React from 'react'
import { path } from 'ramda'
import Loading from '../components/Loading'

export const graphQLData = (Component: any) => (props: any) => {
  const {
    data: { loading, error },
    data,
  } = props

  if (loading) {
    return <Loading />
  }

  if (error) {
    const networkErrors = (path(['error', 'networkError', 'result', 'errors'], data) || []) as Array<string>
    if (networkErrors) {
      const reducer = (accumulator: string, current: any) => {
        return accumulator.concat(path(['message'], current) || '')
      }
      console.error(networkErrors.reduce(reducer, ''))
    }

    throw error
  }

  return <Component {...props} />
}

export default graphQLData
