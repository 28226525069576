import * as React from 'react'
import { connect } from 'react-redux'
import { compose } from 'ramda'
import { routerActions } from 'react-router-redux'
import { graphql } from 'react-apollo'
import { translate } from 'react-i18next'
import { Trans } from 'react-i18next'
import PropTypes from 'prop-types'
import SecondaryNavBar from 'components/SecondaryNavBar'
import Title from 'components/Title'
import RunCampaignDialog from 'campaign/RunCampaignDialog'
import ScrollWrapper from '../components/ScrollWrapper'
import BrandDashboardContainer from './BrandDashboardContainer'
import { SalesProgramsQueryDocument } from '../graphql'
import { hasDatePassed } from '../utils/helpers'
import graphQLData from '../hoc/GraphQLData'

class DashboardContainer extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  }

  state = { open: false }

  handleSalesProgramSelectClose = () => {
    this.setState({ open: false })
  }

  handleOpenSalesProgramSelect = () => {
    this.setState({ open: true })
  }

  render() {
    return (
      <React.Fragment>
        <SecondaryNavBar>
          <Title>
            <Trans>Dashboard</Trans>
          </Title>
        </SecondaryNavBar>
        <ScrollWrapper>
          {this.props.data.brand.salesPrograms.filter(salesProgram => !hasDatePassed(Date.parse(salesProgram.endDate)))
            .length ? (
            <RunCampaignDialog isSalesProgramAvailable={true} />
          ) : (
            <RunCampaignDialog isSalesProgramAvailable={false} />
          )}
          <BrandDashboardContainer />
        </ScrollWrapper>
      </React.Fragment>
    )
  }
}

export default compose(
  translate(),
  connect(
    null,
    { replace: routerActions.replace }
  ),
  graphql(SalesProgramsQueryDocument, {
    options: props => ({
      variables: {
        brandId: props.match.params.brandId,
      },
    }),
  }),
  graphQLData
)(DashboardContainer)
