import AWS from 'aws-sdk'

const region = 'us-west-2'

const getAuthenticatedSDK = credentials => {
  const config = new AWS.Config({ region, ...credentials })

  AWS.config.update(config)

  return AWS
}

export function startManagedUpload(file, assumeRole) {
  const { accessKeyId, sessionToken, secretAccessKey, fileKey, bucket } = assumeRole

  const AWS = getAuthenticatedSDK({
    accessKeyId,
    sessionToken,
    secretAccessKey,
  })
  const s3 = new AWS.S3({ signatureVersion: 'v4' })
  const params = {
    Body: file,
    Bucket: bucket.split(':')[bucket.split(':').length - 1],
    Key: fileKey.slice(1),
  }

  return s3.upload(params) // a ManagedUpload instance.
}
