import * as React from 'react'
import { compose } from 'ramda'
import { Mutation, withApollo } from 'react-apollo'
import { Button, TextField, withStyles } from '@material-ui/core'
import {
  AdQueryDocument,
  ApproveAdWithNotesMutationDocument,
  UpdateAdMutationDocument,
  RejectAdMutationDocument,
  SubmitAdMutationDocument,
} from '../../graphql'
import withSnackbar from 'components/WithSnackbar'
import { CovaultAuth } from 'components/CovaultAuth'
import { css } from 'emotion'

const buttonWrapperStyle = css`
  align-self: flex-end;
`
const StyledButton = withStyles({
  root: {
    marginTop: 12,
    marginLeft: 12,
    alignSelf: 'flex-end',
  },
})(({ children, ...props }) => (
  <Button color="primary" variant="contained" {...props}>
    {children}
  </Button>
))
const DecoratedTextField = withStyles({
  root: {
    margin: '12px 0',
  },
})(props => (
  <TextField
    fullWidth={true}
    multiline={true}
    variant="outlined"
    InputProps={{ inputProps: { maxLength: 255 }, readOnly: props.readOnly }}
    {...props}
  />
))

class ChatBox extends React.Component {
  state = {
    value: void 0,
  }

  handleChange = ({ target: { value } }) => this.setState({ value })

  submitAd = () =>
    this.props.client.mutate({
      mutation: SubmitAdMutationDocument,
      variables: { id: this.props.ad.id },
      refetchQueries: [{ query: AdQueryDocument, variables: { id: this.props.ad.id } }],
    })

  render() {
    const {
      // ad,
      ad: { name, status, statusLogs, id },
      snackbarContext,
    } = this.props
    const { value } = this.state
    const unsubmitted = status === 'UNSUBMITTED'
    const approved = status === 'APPROVED'
    const rejected = status === 'REJECTED'

    const rejectionMessage =
      (rejected && statusLogs.find(sl => sl.status === 'REJECTED').notes) || 'No notes were provided.'
    const approvalMessage =
      (approved && statusLogs.find(sl => sl.status === 'APPROVED').notes) || 'No notes were provided.'

    return (
      <React.Fragment>
        <DecoratedTextField
          readOnly={!unsubmitted}
          label="Marketing Activity Requested"
          defaultValue={name}
          value={unsubmitted ? value : name}
          onChange={this.handleChange}
        />
        <CovaultAuth requiredParent="dealer">
          <React.Fragment>
            {!approved && !rejected && (
              <Mutation mutation={UpdateAdMutationDocument} onCompleted={this.submitAd}>
                {mutate => (
                  <StyledButton
                    onClick={() => mutate({ variables: { ad: { name: value }, id } })}
                    disabled={!unsubmitted}
                  >
                    {unsubmitted ? 'Submit' : 'Submitted'}
                  </StyledButton>
                )}
              </Mutation>
            )}
          </React.Fragment>
        </CovaultAuth>
        {approved || rejected ? (
          <DecoratedTextField
            readOnly={true}
            label={approved ? 'Approval Notes' : 'Rejection Notes'}
            value={approved ? approvalMessage : rejectionMessage}
            defaultValue={approved ? approvalMessage : rejectionMessage}
          />
        ) : (
          <CovaultAuth requiredParent="brand">
            <React.Fragment>
              <DecoratedTextField label="Approval or Rejection Notes" value={value} onChange={this.handleChange} />
              <div className={buttonWrapperStyle}>
                <Mutation
                  mutation={RejectAdMutationDocument}
                  refetchQueries={[{ query: AdQueryDocument, variables: { id } }]}
                  onCompleted={() => snackbarContext.successMessage('Co-op Request Rejected.')}
                >
                  {(mutate, { data, loading, error }) => (
                    <StyledButton onClick={() => mutate({ variables: { notes: value, id } })}>Reject</StyledButton>
                  )}
                </Mutation>
                <Mutation
                  mutation={ApproveAdWithNotesMutationDocument}
                  refetchQueries={[{ query: AdQueryDocument, variables: { id } }]}
                  onCompleted={() => snackbarContext.successMessage('Co-op Request Approved.')}
                >
                  {(mutate, { data, loading, error }) => (
                    <StyledButton onClick={() => mutate({ variables: { notes: value || '', id } })}>
                      Approve
                    </StyledButton>
                  )}
                </Mutation>
              </div>
            </React.Fragment>
          </CovaultAuth>
        )}
      </React.Fragment>
    )
  }
}

export default compose(
  withSnackbar,
  withApollo
)(ChatBox)
