import * as React from 'react'
import PropTypes from 'prop-types'
import { CHANNEL_NAMES } from 'constants/channels'
import { STATUS_NAMES } from 'constants/ads'
import { Paper, Typography, withStyles } from '@material-ui/core'
import { FacebookCreativeTemplateList } from 'components/FacebookCreativeList'

const styles = theme => ({
  paperDescription: {
    margin: '24px',
  },
  paperCreatives: {
    marginLeft: '14px',
  },
})

export function CampaignTemplateSection({ campaignTemplate, classes, dealer = {}, salesProgramId }) {
  const { creativeTemplates, channel, description, defaultAudience, status } = campaignTemplate

  return (
    <React.Fragment>
      <Paper elevation={0} className={classes.paperDescription}>
        <Typography component="p">Channel: {CHANNEL_NAMES[channel]}</Typography>
        <Typography component="p">Description: {description}</Typography>
        <Typography component="p">Default Audience: {defaultAudience}</Typography>
        <Typography component="p">
          This Campaign Template is currently&nbsp;
          <strong data-testid="status">{STATUS_NAMES[status]}</strong>
        </Typography>
        <br />

        {creativeTemplates.length === 0 && (
          <React.Fragment>
            <br />
            <Typography component="title">There are no Templates associated with this Template.</Typography>
          </React.Fragment>
        )}
      </Paper>

      <Paper elevation={0} className={classes.paperCreatives}>
        {campaignTemplate.channel === 'FACEBOOK' && (
          <FacebookCreativeTemplateList
            dealer={dealer}
            campaignTemplate={campaignTemplate}
            salesProgramId={salesProgramId}
          />
        )}
      </Paper>
    </React.Fragment>
  )
}

CampaignTemplateSection.propTypes = {
  campaignTemplate: PropTypes.object.isRequired,
  dealer: PropTypes.object,
  salesProgramId: PropTypes.string.isRequired,
}

export default withStyles(styles)(CampaignTemplateSection)
