import * as React from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { Card, CardContent, Typography } from '@material-ui/core'

const styles = {
  card: {
    minWidth: 275,
    maxWidth: 450,
    margin: 'auto',
    marginTop: 50
  },
  cardContent: {
    textAlign: 'center'
  }
}

export function Error({ classes, title, message = '' }) {
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="headline" gutterBottom>
          {title}
        </Typography>
        <br />
        <Typography variant="subheading" gutterBottom color="error">
          {message}
        </Typography>
        <Typography variant="subheading" gutterBottom>
          If the issue persists, please contact Covault support.
        </Typography>
      </CardContent>
    </Card>
  )
}

Error.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default withStyles(styles)(Error)
