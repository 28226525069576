import * as React from 'react'
import PropTypes from 'prop-types'
import { css } from 'emotion'
import { contains } from 'ramda'
import { generatePath } from 'react-router'
import { Link as RouterLink, NavLink as RouterNavLink } from 'react-router-dom'

const linkStyle = css`
  color: black;
`

export function Link({ children, path, search = null, params, to: pathname = null }) {
  if (params && contains(undefined, Object.values(params))) {
    return (
      <RouterLink className={linkStyle} to={'/'} active="false">
        {children}
      </RouterLink>
    )
  }

  const to = {
    pathname: path ? generatePath(path, params) : pathname,
    search,
  }

  return (
    <RouterLink className={linkStyle} to={to}>
      {children}
    </RouterLink>
  )
}

export function NavLink({ children, path, params, ...prop }) {
  if (params && contains(undefined, Object.values(params))) {
    return (
      <RouterNavLink {...prop} to={'/'} active="false">
        {children}
      </RouterNavLink>
    )
  }

  const to = generatePath(path, params)

  return (
    <RouterNavLink to={to} {...prop}>
      {children}
    </RouterNavLink>
  )
}

Link.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.array]),
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  search: PropTypes.string,
  path: PropTypes.string,
  params: PropTypes.object,
}

NavLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.array]),
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  path: PropTypes.string,
  params: PropTypes.object,
}

export default Link
