import * as React from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { translate } from 'react-i18next'
import PropTypes from 'prop-types'
import Link from 'components/Link'
import { BrandsQueryDocument } from '../graphql'
import ScrollWrapper from 'components/ScrollWrapper'
import { compose } from 'ramda'
import { graphql } from 'react-apollo'
import { BRAND_PATH, SALES_PROGRAMS_PATH } from 'constants/paths'
import Loading from 'components/Loading'
import { UnlinkFromBrand } from 'components/TransitionEntityButton'

export function BrandsTable({ brands, dealer, loading, error, showUnlinkButton, t }) {
  if (loading) {
    return <Loading />
  }
  if (error) {
    throw error
  }

  return (
    <ScrollWrapper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('Brand Name')}</TableCell>
            <TableCell numeric>{t('Sales Programs')}</TableCell>
            <TableCell numeric>{t('Product Lines')}</TableCell>
            <TableCell numeric>{t('Brand ID')}</TableCell>
            {showUnlinkButton && <TableCell />}
          </TableRow>
        </TableHead>
        {brands && (
          <TableBody>
            {brands.map(({ name, id, productLines, salesPrograms }, i) => (
              <TableRow key={id}>
                <TableCell>
                  <Link path={BRAND_PATH} params={{ id }}>
                    {name}
                  </Link>
                </TableCell>
                <TableCell numeric>
                  <Link path={SALES_PROGRAMS_PATH} params={{ brandId: id }}>
                    {salesPrograms ? salesPrograms.length : 0} Sales Programs
                  </Link>
                </TableCell>
                <TableCell numeric>{productLines ? productLines.length : 0}</TableCell>
                <TableCell numeric>{id}</TableCell>
                {showUnlinkButton && (
                  <TableCell>
                    <UnlinkFromBrand dealer={{ ...dealer, attachedBrandId: id }} />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </ScrollWrapper>
  )
}

BrandsTable.propTypes = {
  brands: PropTypes.array,
  dealer: PropTypes.object,
  error: PropTypes.object,
  loading: PropTypes.bool,
  showUnlinkButton: PropTypes.bool,
  t: PropTypes.func,
}

export const TranslatedBrandsTable = translate()(BrandsTable)

export default compose(
  graphql(BrandsQueryDocument, {
    props: ({ data: { brands, loading, error } }) => ({
      brands,
      loading,
      error,
    }),
  })
)(TranslatedBrandsTable)
