const HOTJAR_ID = process.env.REACT_APP_HOTJAR_ID
const HOTJAR_VERSION = 6

function init(hjid, hjsv) {
  ;(function(h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function() {
        ;(h.hj.q = h.hj.q || []).push(arguments)
      }
    h._hjSettings = { hjid, hjsv }
    a = o.getElementsByTagName('head')[0]
    r = o.createElement('script')
    r.async = 1
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
    a.appendChild(r)
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
}

export default function registerHotjar() {
  if (HOTJAR_ID) {
    init(HOTJAR_ID, HOTJAR_VERSION)
  }
}
