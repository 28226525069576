import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'ramda'
import { withStyles } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import withSnackbar from 'components/WithSnackbar'

import green from '@material-ui/core/colors/green'
import amber from '@material-ui/core/colors/amber'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import { Trans } from 'react-i18next'
import Icon from 'components/Icon'

const styles = theme => ({
  close: {
    width: theme.spacing.unit * 4,
    height: theme.spacing.unit * 4
  },
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20,
    marginRight: theme.spacing.unit * 2
  },
  message: {
    display: 'block',
    alignItems: 'center'
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center'
  }
})

class ContextSnackbar extends React.Component {
  handleClose = (event, reason) => {
    this.props.snackbarContext.closeSnackbar()
  }

  render() {
    const {
      classes,
      snackbarContext: { title, message, variant, open }
    } = this.props

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={open}
        autoHideDuration={10000}
        onClose={this.handleClose}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
      >
        <SnackbarContent
          className={classes[variant]}
          aria-describedby="client-snackbar"
          message={
            <span id="message-id" className={classes.wrapper}>
              <Icon variant={variant} />
              <span className={classes.message}>
                <h3>
                  <Trans>{title}</Trans>
                </h3>
                <p>
                  <Trans>{message}</Trans>
                </p>
              </span>
            </span>
          }
          action={[
            <IconButton
              data-testid="snackbar-close-button"
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </Snackbar>
    )
  }
}

ContextSnackbar.propTypes = {
  classes: PropTypes.object.isRequired,
  snackbarContext: PropTypes.object.isRequired
}

export default compose(
  withStyles(styles),
  withSnackbar
)(ContextSnackbar)
