import gql from 'graphql-tag'
import { assumeRoleFragment, productLineFragment } from '../graphql'

export const ProductLinesQueryDocument = gql`
  query GetProductLinesOfBrand($id: ID!) {
    brands(id: $id) {
      productLines {
        id
        name
      }
    }
  }
`

export const CreateProductLineMutationDocument = gql`
  mutation CreateProductLine($id: ID!, $productLine: ProductLineCreateParams!) {
    createProductLine(brandId: $id, productLine: $productLine) {
      productLine {
        ...productLine
      }
      assumeRole {
        ...assumeRole
      }
    }
  }

  ${assumeRoleFragment}
  ${productLineFragment}
`

export const DeleteProductLine = gql`
  mutation DeleteProductLine($id: ID!) {
    deleteProductLine(id: $id) {
      id
    }
  }
`

export const UpdateProductLineMutationDocument = gql`
  mutation UpdateProductLine($productLine: ProductLineUpdateParams!, $id: ID!) {
    updateProductLine(productLine: $productLine, productLineId: $id) {
      productLine {
        ...productLine
      }
      assumeRole {
        ...assumeRole
      }
    }
  }

  ${assumeRoleFragment}
  ${productLineFragment}
`
