// this function is built in order to redirect people to their desired URL once they log in
// without this, someone following a link to a Covault page, while logged out, would just land on the dashboard after logging in
// with this function, they land on the right page after logging in.

import { COVAULT_URL_PASSTHRU_KEY } from 'constants/urlPassthru'

export default function(store) {
  return next => action => {
    if (action.type === '@@router/LOCATION_CHANGE') {
      const { router, auth } = store.getState()

      const firstRouteAfterPageload = router.location === null
      const notAuthenticated = !(auth && auth.isAuthenticated)
      const notRedirectingAfterAuthenticating = action.payload.pathname !== '/login/callback'

      if (firstRouteAfterPageload && notAuthenticated && notRedirectingAfterAuthenticating) {
        window.sessionStorage.setItem(COVAULT_URL_PASSTHRU_KEY, `${action.payload.pathname}${action.payload.search}`)
      }
    }

    return next(action)
  }
}
