import Auth0Lock from 'auth0-lock'

import {
  auth0Authenticated,
  auth0AuthorizationError,
  auth0HashParsed,
  auth0Profile,
  auth0ProfileError,
  AUTH0_SHOW_LOGIN_DIALOG,
} from 'actions/auth0'

const auth0Lock = new Auth0Lock(process.env.REACT_APP_AUTH0_CLIENT_ID, process.env.REACT_APP_AUTH0_TENANT_ENDPOINT, {
  configurationBaseUrl: 'https://cdn.auth0.com',
  closable: false,
  allowSignUp: false,
  rememberLastLogin: false,
  auth: {
    audience: process.env.REACT_APP_API_ENDPOINT,
    redirectUrl: process.env.REACT_APP_AUTH0_CALLBACK_URL,
    responseType: 'token id_token',
    params: { scope: 'openid profile' },
  },
  languageDictionary: {
    title: '',
  },
  theme: {
    logo: '/assets/images/covaultWordmark.png',
    primaryColor: '#1f10f2',
  },
})

const middleware = store => {
  const dispatch = store.dispatch

  auth0Lock.on('authenticated', authResult => {
    auth0Lock.hide()

    dispatch(auth0Authenticated(authResult))

    auth0Lock.getUserInfo(authResult.accessToken, (err, profile) => {
      if (err) {
        dispatch(auth0ProfileError(err))
      } else {
        dispatch(auth0Profile(profile))
      }
    })
  })

  auth0Lock.on('authorization_error', err => {
    dispatch(auth0AuthorizationError(err))
  })

  auth0Lock.on('hash_parsed', payload => {
    dispatch(auth0HashParsed(payload))
  })

  return next => action => {
    if (action.type === AUTH0_SHOW_LOGIN_DIALOG) {
      auth0Lock.show()
    } else {
      return next(action)
    }
  }
}

export default middleware
