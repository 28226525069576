import { AssumeRoleMutationDocument } from '../../graphql'
import { startManagedUpload } from 'utils/upload'

async function uploadFile(
  assumeRole,
  keyname,
  { errorMessage = 'Upload failed.', successMessage = 'File was successfully uploaded.' } = {}
) {
  if (!this.state[keyname] || !assumeRole) {
    return this.setState({ loading: false })
  }

  const managedUpload = startManagedUpload(this.state[keyname], assumeRole)
  managedUpload.on('httpUploadProgress', ({ loaded, total }) =>
    this.setState({ progress: total > 0 ? loaded / total : 0 })
  )
  await managedUpload.promise().then(
    () => {
      this.setState({ progress: void 0, loading: void 0 })
      this.props.snackbarContext.successMessage(successMessage, '')
    },
    error => {
      console.error(error)
      this.props.snackbarContext.errorMessage(errorMessage, '')
    }
  )
}

function uploadTemporaryFile(file, fileKeyname, locationKeyname, opts) {
  this.props.client
    .mutate({
      mutation: AssumeRoleMutationDocument,
      variables: { filename: file.name },
    })
    .then(({ data: { assumeRole } }) => {
      uploadFile.bind(this)(assumeRole, fileKeyname, opts)

      const [, domain, path, key] = assumeRole.unmodifiedFileUrl.match(/https?:\/\/([^/]*)(\/.*\/)([^/]+)$/)

      this.setState({ [locationKeyname]: { domain, path, key } })
    })
}

export { uploadFile, uploadTemporaryFile }
