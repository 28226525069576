import * as React from 'react'
import RunCampaignContainer from '../campaign/RunCampaignContainer'
import Raven from 'raven-js'
import TextShrinker from 'components/TextShrinker'
import { CovaultAuth } from 'components/CovaultAuth'
import { CenteredPurpleText, ErrorBar } from 'components/materialUI'
import { Trans } from 'react-i18next'
import { Button, Dialog, DialogActions, Icon, Slide, withStyles } from '@material-ui/core'
import { path, map, join } from 'ramda'
import { TABLET_BREAKPOINT } from '../constants'

function Transition(props) {
  return <Slide direction="up" {...props} />
}

export default class RunCampaignButton extends React.Component {
  handleClose = () => {
    this.setState({ showCloseConfirmation: true })
  }

  handleCloseConfirmed = () => {
    this.setState({ open: false, showCloseConfirmation: false })
  }

  handleCloseCancelled = () => {
    this.setState({ showCloseConfirmation: false })
  }

  handleStart = () => {
    this.setState({ open: true })
  }

  onError = error => {
    let errorMessage

    if (error.graphQLErrors && error.graphQLErrors.length > 0) {
      errorMessage = join(', ', map(gqlError => path(['message'], gqlError), error.graphQLErrors))
    } else {
      errorMessage = 'An unspecified error occurred.'
    }
    Raven.captureException(error)
    this.setState({ errorMessage })
  }

  state = { open: false, errorMessage: null, showCloseConfirmation: false }

  render() {
    const { buttonText = 'Start', shortButtonText, ...otherProps } = this.props
    const { errorMessage, showCloseConfirmation } = this.state

    return (
      <CovaultAuth requiredParent="dealer">
        <React.Fragment>
          <Button color="primary" variant="contained" onClick={this.handleStart}>
            <Trans>
              <TextShrinker fullText={buttonText} shortText={shortButtonText} />
            </Trans>
          </Button>{' '}
          <RunCampaignDialog
            TransitionComponent={Transition}
            maxWidth="lg"
            onClose={this.handleClose}
            open={this.state.open}
            scroll="paper"
          >
            <RunCampaignContainer {...otherProps} onMutationError={this.onError} />
            {errorMessage && <ErrorBar>{errorMessage}</ErrorBar>}
            <StyledIcon onClick={this.handleClose}>close</StyledIcon>
            <CloseConfirmationDialog maxWidth="lg" open={showCloseConfirmation} scroll="paper" disablePortal={true}>
              <CenteredPurpleText>
                This campaign is not saved. <b>Discard this campaign?</b>
              </CenteredPurpleText>
              <DialogActions>
                <Button variant="contained" onClick={this.handleCloseConfirmed}>
                  Discard Campaign
                </Button>
                <Button variant="contained" color="primary" onClick={this.handleCloseCancelled}>
                  Keep Campaign & Continue
                </Button>
              </DialogActions>
            </CloseConfirmationDialog>
          </RunCampaignDialog>
        </React.Fragment>
      </CovaultAuth>
    )
  }
}

const RunCampaignDialog = withStyles({
  container: {
    position: 'relative',
  },
  paperWidthLg: {
    borderRadius: 0,
    margin: '64px 0 0 0',
    maxHeight: '92vh',
    overflow: 'auto',
    width: '100%',
    maxWidth: 'unset',
    transition: ['all'],
    transitionDuration: 1000,
    position: 'absolute',
    top: 0,
    [TABLET_BREAKPOINT]: {
      marginTop: '56px',
      maxHeight: '93vh',
    },
  },
})(Dialog)

export const CloseConfirmationDialog = withStyles({
  root: {
    position: 'absolute',
  },
  container: {
    position: 'relative',
  },
  paperWidthLg: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 0,
    overflow: 'auto',
    width: '100%',
    maxWidth: 'unset',
    transition: ['all'],
    transitionDuration: 1000,
    position: 'absolute',
    top: 0,
    margin: 0,
    height: '100%',
    maxHeight: 'unset',
  },
})(Dialog)

const StyledIcon = withStyles({
  root: {
    position: 'absolute',
    top: 10,
    right: 10,
    padding: 3,
    color: '#9e9e9e',
    borderRadius: '50%',
    border: '2px solid #9e9e9e',
    cursor: 'pointer',
    background: 'white',
  },
})(Icon)
