import * as React from 'react'
import { Trans } from 'react-i18next'
import { Link } from 'components/Link'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'

export default function PublishBlocker({ isOpen, handleClose }) {
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        <Trans>Please enter a credit card and connect to your Facebook.</Trans>
      </DialogTitle>
      <DialogContent>
        <DialogActions>
          <Link to="/userSettings">Visit settings page</Link>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
