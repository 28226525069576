import { ApolloClient } from 'apollo-boost'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { path } from 'ramda'

import introspectionQueryResultData from 'graphql/fragmentTypes.json'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
})

export default function createClient({ store }) {
  const authLink = setContext((_, { headers }) => {
    const state = store.getState()
    const token = path(['auth', 'idToken'], state)

    if (token) {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
        },
      }
    }
  })

  return new ApolloClient({
    link: authLink.concat(createHttpLink({ uri: process.env.REACT_APP_BACKEND_ENDPOINT })),
    cache: new InMemoryCache({ fragmentMatcher }),
  })
}
