import * as React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Step, Stepper, StepLabel } from '@material-ui/core'
import { css } from 'emotion'

const stepStyle = css`
  cursor: pointer;
`

export const styles = theme => ({
  active: {},
  alternativeLabel: {
    '& $active': {
      fontWeight: 600,
    },
  },
})

export function RunCampaignStepper(props) {
  const { enableRevert, revertToStep, activeStep, stepperTitles, classes, lastStepTitle, lastStepGrayed } = props

  let nextStepperTitles = stepperTitles

  if (lastStepTitle && lastStepGrayed) {
    nextStepperTitles = stepperTitles.map((title, i) => (i === stepperTitles.length - 1 ? lastStepTitle : title))
  }

  const activeStep1 = lastStepGrayed && stepperTitles.length - 1 === activeStep ? activeStep - 1 : activeStep

  return (
    <Stepper activeStep={activeStep1} alternativeLabel>
      {nextStepperTitles.map((title, i) => {
        return (
          <Step
            className={enableRevert ? stepStyle : ''}
            onClick={enableRevert && i < activeStep1 ? () => revertToStep(i) : void 0}
            key={title}
          >
            <StepLabel classes={classes} data-testid={`steplabel-${i}-${i === activeStep1 ? 'active' : 'inactive'}`}>
              {title}
            </StepLabel>
          </Step>
        )
      })}
    </Stepper>
  )
}

RunCampaignStepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
  stepperTitles: PropTypes.array.isRequired,
  classes: PropTypes.object,
}

export default withStyles(styles)(RunCampaignStepper)
