import * as React from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { StyledTableCell } from 'components/materialUI'
import { compose } from 'ramda'
import { translate } from 'react-i18next'
import PropTypes from 'prop-types'
import ScrollWrapper from 'components/ScrollWrapper'
import { withStyles } from '@material-ui/core/styles'
import { EditProductLine } from 'components/CreateEntityButton'
import { DeleteProductLine } from 'components/TransitionEntityButton'
import ImageLoader from 'components/ImageLoader'

const styles = {
  root: {
    textAlign: 'right',
  },
}

const containsProductLine = (id, salesPrograms) => {
  if (!salesPrograms) return false
  return salesPrograms.filter(sp => sp.productLine.id === id).length
}

export function ProductLineTable({ brand, classes, productLines, salesPrograms }) {
  return (
    <ScrollWrapper>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>Product Line Name</StyledTableCell>
            <StyledTableCell>Logo</StyledTableCell>
            <TableCell classes={classes} />
          </TableRow>
        </TableHead>
        {productLines && (
          <TableBody>
            {productLines.map((productLine, i) => {
              const { name: productLineName, id: productLineId, logoLocation } = productLine
              return (
                <TableRow key={productLineId}>
                  <StyledTableCell>{productLineName}</StyledTableCell>
                  <StyledTableCell>
                    <ImageLoader width={150} height={90} location={logoLocation} />
                  </StyledTableCell>
                  <TableCell classes={classes}>
                    {!containsProductLine(productLineId, salesPrograms) && (
                      <DeleteProductLine
                        brandId={brand.id}
                        productLine={productLine}
                        buttonProps={{ variant: 'flat' }}
                      />
                    )}
                    <EditProductLine brand={brand} productLine={productLine} buttonProps={{ variant: 'flat' }} />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        )}
      </Table>
    </ScrollWrapper>
  )
}

ProductLineTable.propTypes = {
  brand: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  productLines: PropTypes.array,
}

export default compose(
  translate(),
  withStyles(styles)
)(ProductLineTable)
