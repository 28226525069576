// A facebook ad display component.

import React from 'react'
import PropTypes from 'prop-types'
import ImageLoader from 'components/ImageLoader'
import { Card } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import './styles.css'

const getProfileImage = location => {
  if (location) {
    const { domain, path, key } = location

    return <img alt="" className="header__profileImage" src={`https://${domain}${path}${key}`} />
  }

  return (
    <img
      alt=""
      className="header__profileImage header__placeholderProfileImage"
      src={require('../../assets/ImagePlaceholder.svg')}
    />
  )
}

function FacebookAd({
  classes,
  creative: { description, location, overlayLocation, name, text, clickThroughUrl } = {},
  dealer: { name: dealerName, logoLocation } = {},
  fullscreen,
  isSelected,
}) {
  let height = 246
  let width = 470

  if (fullscreen) {
    height = 7 * Math.floor(window.innerHeight / 8)
    width = 7 * Math.floor(Math.min(520, 1086) / 8)
  }

  let urlStub
  if (clickThroughUrl) {
    const match = clickThroughUrl.match(/http[s]?:\/\/([^/]*)[/.*]?/)
    if (match) {
      urlStub = match[1]
    }
  }

  return (
    <Card className={`MUIcard ${isSelected ? classes.selectedCard : ''}`}>
      <div className={`facebookAdWrapper ${fullscreen ? 'fullscreen' : ''}`}>
        <div className="header">
          <div>
            {getProfileImage(logoLocation)}
            <div className="header__text">
              <h5>{dealerName}</h5>
              <p>
                Sponsored &#183;&nbsp;
                <img alt="" src={require('../../assets/globe.jpeg')} />
              </p>
            </div>
          </div>
          <img alt="" className="likeButtonAndKabob" src={require('../../assets/likeButtonAndKabob.jpeg')} />
        </div>
        <p className="text">{text} &nbsp;</p>
        {location ? (
          <div className="imageWrapper">
            <ImageLoader
              style={{ width: '100%' }}
              location={location}
              height={height}
              width={width}
              transformation="crop"
            />
            {overlayLocation && (
              <ImageLoader
                style={{ position: 'absolute', top: 0, width: '100%' }}
                location={overlayLocation}
                height={height}
                width={width}
              />
            )}
          </div>
        ) : (
          <div className="placeholderImage" style={{ height, width }} />
        )}
        <div className="content">
          <div className="content__text">
            <div className="content__text__domain">{urlStub}</div>
            <div className="content__text__name">{name}</div>
            <div className="content__text__description">{description}</div>
          </div>
          <img alt="" className="learnMoreButton" src={require('../../assets/learnMoreButton.jpeg')} />
        </div>
        <div className="likeCount">
          <img alt="" src={require('../../assets/likeButton.jpeg')} /> 5
        </div>
        <hr className="horizontalRule" />
        <div className="ctaButtons">
          <div>
            <img alt="" src={require('../../assets/likeButtonOutline.jpeg')} /> Like
          </div>
          <div>
            <img alt="" src={require('../../assets/commentOutline.jpeg')} /> Comment
          </div>
          <div>
            <img alt="" src={require('../../assets/shareOutline.jpeg')} /> Share
          </div>
          <div>
            {getProfileImage(logoLocation)}
            <img alt="" src={require('../../assets/downCarat.jpeg')} />
          </div>
        </div>
      </div>
    </Card>
  )
}

FacebookAd.propTypes = {
  creative: PropTypes.object.isRequired,
  dealer: PropTypes.object.isRequired,
  fullscreen: PropTypes.bool,
}

export const styles = theme => ({
  selectedCard: {
    boxShadow: `0px 0px 7px ${theme.palette.primary.main} !important`,
  },
})

export default withStyles(styles)(FacebookAd)
