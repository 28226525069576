/* Title.jsx
 * Wraps title components/strings with MaterialUI Typography component.
 *
 * Created by Louis Ritchie on April 25th, 2018
 * louiscritchie@gmail.com
 */

import * as React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'

const Title = ({ children }) => {
  return <Typography variant="headline">{children}</Typography>
}

Title.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.array]).isRequired
}

export default Title
