/* ScrollWrapper.jsx
 * This component helps our tables scroll nicely while keeping the SecondaryNavBar static.
 * This way, our call-to-action buttons are always in the view of the user.
 *
 * Created by Louis Ritchie on April 27th, 2018
 * louiscritchie@gmail.com
 */

import * as React from 'react'
import PropTypes from 'prop-types'
import { Paper } from '@material-ui/core'
import { css } from 'emotion'

const paperStyle = css`
  overflow: auto;
`

const ScrollWrapper = ({ children }) => (
  <Paper elevation={0} className={paperStyle}>
    {children}
  </Paper>
)

ScrollWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
}

export default ScrollWrapper
