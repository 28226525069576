import * as React from 'react'

export const initialSnackbarContext = {
  snackbar: {
    open: false,
    title: '',
    message: '',
    variant: '',
    setSnackbarContext: () => {},
  },
}

export default React.createContext(initialSnackbarContext)

export function snackbarFactory(setSnackbarContext) {
  const snackbarMessage = (title, message, variant = 'success') => {
    setSnackbarContext({ title, message, open: true, variant })
  }

  const successMessage = (title, message) => {
    snackbarMessage(title, message, 'success')
  }

  const infoMessage = (title, message) => {
    snackbarMessage(title, message, 'info')
  }

  const warnMessage = (title, message) => {
    snackbarMessage(title, message, 'warn')
  }

  const errorMessage = (title, message) => {
    snackbarMessage(title, message, 'error')
  }

  const closeSnackbar = () => {
    setSnackbarContext({ open: false })
  }

  return {
    snackbarMessage: snackbarMessage,
    successMessage: successMessage,
    infoMessage: infoMessage,
    warnMessage: warnMessage,
    errorMessage: errorMessage,
    closeSnackbar: closeSnackbar,
  }
}

export function successMessage(setSnackbarContext, title, message) {
  setSnackbarContext({ title: title, message: message, open: true })
}
