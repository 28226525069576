import * as React from 'react'
import { Trans } from 'react-i18next'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import { getProductLine } from 'campaign/Utils'
import { CHANNEL_NAMES } from '../constants'
import TextShrinker from 'components/TextShrinker'

export function selectCampaignTemplateContent(props, state) {
  const {
    data: {
      brand: { productLines },
    },
  } = props

  const { productLineId, selectCampaignTemplateId } = state
  const productLine = getProductLine(productLines, productLineId)
  return <SelectCampaignTemplate productLine={productLine} selectCampaignTemplateId={selectCampaignTemplateId} />
}

export function selectCampaignTemplateAction(props, state) {
  return ''
}

export function SelectCampaignTemplate(props) {
  const {
    productLine: { campaignTemplates },
    selectCampaignTemplateId,
  } = props

  const now = moment()
  const filteredCampaignTemplates = campaignTemplates.filter(campaignTemplate => {
    return (
      now.isSameOrBefore(campaignTemplate.salesProgram.endDate) &&
      now.isSameOrAfter(campaignTemplate.salesProgram.startDate)
    )
  })

  return (
    <React.Fragment>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="dense">
              <Trans>Name</Trans>
            </TableCell>
            <TableCell padding="dense">
              <Trans>Description</Trans>
            </TableCell>
            <TableCell padding="dense">
              <Trans>Available Creatives</Trans>
            </TableCell>
            <TableCell padding="dense">
              <Trans>Channel</Trans>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredCampaignTemplates.map(({ creativeTemplates, name, id, channel, description, status }, i) => {
            return (
              <TableRow key={id}>
                <TableCell padding="dense">{name}</TableCell>
                <TableCell padding="dense">{description}</TableCell>
                <TableCell padding="dense">{creativeTemplates.length}</TableCell>
                <TableCell padding="dense">{CHANNEL_NAMES[channel]}</TableCell>
                <TableCell>
                  <Button
                    color="primary"
                    onClick={() => selectCampaignTemplateId(id)}
                    data-testid={`SelectCampaignTemplate-select-${id}`}
                  >
                    <Trans>Select</Trans>
                  </Button>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </React.Fragment>
  )
}

SelectCampaignTemplate.propTypes = {
  productLine: PropTypes.object.isRequired,
  selectCampaignTemplateId: PropTypes.func.isRequired,
}

export default SelectCampaignTemplate

export const campaignTemplateStep = {
  title: ({ isSinglePartyDealer }) => (
    <Typography variant="title" gutterBottom data-testid="SelectCampaignTemplate-title">
      <Trans>Select a {isSinglePartyDealer ? 'Digital Template' : 'Pre-Made Campaign'}</Trans>
    </Typography>
  ),
  stepperTitle: ({ isSinglePartyDealer }) => (
    <TextShrinker
      fullText={isSinglePartyDealer ? 'Select a Digital Template' : 'Select a Pre-Made Campaign'}
      shortText="Campaign"
    />
  ),
  content: selectCampaignTemplateContent,
  action: selectCampaignTemplateAction,
  runStepComplete: (props, { campaignTemplateId }) => Boolean(campaignTemplateId),
  campaignStepComplete: ({ salesProgram }) => Boolean(salesProgram),
  undoStep: () => ({ salesProgram: null, campaignTemplateId: null }),
}
